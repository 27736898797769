<section>
  <h3 class="title">{{ "Admin.Home.Limit" | translate }}</h3>

  <div *ngFor="let currency of currencies" class="box mt-5">
    <div class="flex items-center gap-2">
      <app-currency-icon [currency]="currency" class="[&>div]:h-12 [&>div]:w-12" />
      <h4 class="text-base font-medium">{{ getCurrencyName(currency) }}</h4>
    </div>

    <div class="mt-5 space-y-2">
      <p class="flex items-center justify-between font-medium">
        {{ "Admin.Home.Day_limit" | translate }}:
        <span>{{ getLimits(currency)?.maxWithdrawalPerDay | mfeCustom: { currency } }}</span>
      </p>
      <p class="flex items-center justify-between font-medium">
        {{ "Admin.Home.Operation_limit" | translate }}:
        <span>{{ getLimits(currency)?.maxWithdrawalPerTx | mfeCustom: { currency } }}</span>
      </p>
      <p class="flex items-center justify-between font-medium">
        {{ "Admin.Home.With_verification_limit" | translate }}:
        <span>{{ getLimits(currency)?.maxTxAmountWithoutConfirm | mfeCustom: { currency } }}</span>
      </p>
      <p class="flex items-center justify-between font-medium">
        {{ "Admin.Home.Max_buy_limit" | translate }}:
        <span>{{ getLimits(currency)?.maxBuyAmount | mfeCustom: { currency } }}</span>
      </p>
      <p class="flex items-center justify-between font-medium">
        {{ "Admin.Home.Max_sell_limit" | translate }}:
        <span>{{ getLimits(currency)?.maxSellAmount | mfeCustom: { currency } }}</span>
      </p>
      <p class="flex items-center justify-between font-medium">
        {{ "Admin.Home.Min_sell_limit" | translate }}:
        <span>{{ getLimits(currency)?.minSellAmount | mfeCustom: { currency } }}</span>
      </p>
      <p class="flex items-center justify-between font-medium">
        {{ "Admin.Home.Min_buy_limit" | translate }}:
        <span>{{ getLimits(currency)?.minBuyAmount | mfeCustom: { currency } }}</span>
      </p>
      <p class="flex items-center justify-between font-medium">
        {{ "Admin.Home.Without_confirm_limit" | translate }}:
        <span>{{ getLimits(currency)?.maxAcquiringAmountWithoutConfirm | mfeCustom: { currency } }}</span>
      </p>

      <button
        class="ml-auto block w-6"
        (click)="handleOpenModal(currency, editLimitsModal)"
        [disabled]="isUserObserverAdmin"
      >
        <app-svg-icon name="edit" />
      </button>
    </div>
  </div>
</section>

<ng-template let-modal #editLimitsModal>
  <form [formGroup]="limitsForm" (ngSubmit)="handleSaveLimit()" class="flex flex-col gap-y-4 px-15 py-8">
    <h4 class="title-big">
      {{ "Admin.Home.Limit" | translate }} {{ getCurrencyName(limitsForm.value["currency"]) }}
    </h4>
    <app-input
      [title]="'Admin.Home.Day_limit' | translate"
      type="number"
      placeholder="100"
      formControlName="maxWithdrawalPerDay"
      appOnlyNumbers
      [errorMsg]="getFieldError('maxWithdrawalPerDay')"
    />
    <app-input
      [title]="'Admin.Home.Operation_limit' | translate"
      type="number"
      placeholder="100"
      formControlName="maxWithdrawalPerTx"
      appOnlyNumbers
      [errorMsg]="getFieldError('maxWithdrawalPerTx')"
    />
    <app-input
      [title]="'Admin.Home.With_verification_limit' | translate"
      type="number"
      placeholder="100"
      formControlName="maxTxAmountWithoutConfirm"
      appOnlyNumbers
      [errorMsg]="getFieldError('maxTxAmountWithoutConfirm')"
    />
    <app-input
      [title]="'Admin.Home.Max_buy_limit' | translate"
      type="number"
      placeholder="100"
      formControlName="maxBuyAmount"
      appOnlyNumbers
      [errorMsg]="getFieldError('maxBuyAmount')"
    />
    <app-input
      [title]="'Admin.Home.Min_buy_limit' | translate"
      type="number"
      placeholder="100"
      formControlName="minBuyAmount"
      appOnlyNumbers
      [errorMsg]="getFieldError('minBuyAmount')"
    />
    <app-input
      [title]="'Admin.Home.Max_sell_limit' | translate"
      type="number"
      placeholder="100"
      formControlName="maxSellAmount"
      appOnlyNumbers
      [errorMsg]="getFieldError('maxSellAmount')"
    />
    <app-input
      [title]="'Admin.Home.Min_sell_limit' | translate"
      type="number"
      placeholder="100"
      formControlName="minSellAmount"
      appOnlyNumbers
      [errorMsg]="getFieldError('minSellAmount')"
    />
    <app-input
      [title]="'Admin.Home.Without_confirm_limit' | translate"
      type="number"
      placeholder="100"
      formControlName="maxAcquiringAmountWithoutConfirm"
      appOnlyNumbers
      [errorMsg]="getFieldError('maxAcquiringAmountWithoutConfirm')"
    />

    <button type="submit" class="btn-primary btn-medium mt-4" [disabled]="limitsForm.invalid || isPending">
      {{ "Admin.Home.Change" | translate }}
    </button>
  </form>
</ng-template>
