import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectComponent {
  @HostListener('window:click', ['$event'])
  handleClick(event: MouseEvent) {
    this.hideOnClick(event);
  }

  @Input() public items: string[] = [];
  @Input() public selectedItem: string = '';
  @Input() public caption: string = '';
  @Input() public centered: boolean = false;

  @Output() public onSelect = new EventEmitter<string>();

  public isOpen: boolean = false;

  constructor() {}

  public toggleSelect() {
    this.isOpen = !this.isOpen;
  }

  public pickItem(item: string) {
    this.selectedItem = item;
    this.onSelect.emit(this.selectedItem);
    this.isOpen = false;
  }

  public hideOnClick(e: any) {
    if (
      !e.target.classList.contains('select') &&
      !e.target.classList.contains('select__toggle') &&
      !e.target.parentNode?.classList.contains('select__toggle')
    ) {
      this.isOpen = false;
    }
  }

  public displaySelectedItem() {
    if (this.selectedItem) {
      return this.selectedItem;
    } else {
      return this.items[0];
    }
  }
}
