<ng-container *ngIf="!isLoading; else loader">
  <section class="admin-body">
    <app-back-button link="/admin/users"></app-back-button>

    <h3 class="title">{{ "Admin.Users.User" | translate }}</h3>

    <div class="grid grid-cols-2 grid-rows-1 gap-5">
      <ng-container *ngFor="let wallet of userData.wallets">
        <app-balance-card *ngFor="let balance of wallet.balances" [balanceItem]="balance" [rates]="rates" />
      </ng-container>
      <div *ngIf="userData?.wallets?.length" class="box space-y-2">
        <div *ngFor="let wallet of userData.wallets">
          <p class="text-sm font-medium dark:text-slate-200">
            {{ "Admin.Users.Address" | translate }} {{ getNetworkName(wallet.network) }}
          </p>
          <p *ngIf="userData.wallets" class="mt-1 flex items-center text-base">
            <a
              target="_blank"
              [href]="getWalletLink(wallet.network, wallet.address)"
              class="truncate font-bold text-link-color"
            >
              {{ wallet.address }}
            </a>
            <app-copy-btn [value]="wallet.address" />
          </p>
        </div>
      </div>
      <div class="box">
        <p class="text-sm font-medium dark:text-slate-200">{{ "Admin.Users.Phone_number" | translate }}</p>
        <p class="mt-1 flex items-center text-base">{{ userData.phoneNumber | phoneDisplayer }}</p>
      </div>
      <div class="box">
        <p class="text-sm font-medium dark:text-slate-200">{{ "Admin.Users.Full_name" | translate }}</p>
        <p *ngIf="userData.kyc; else userNotVerified" class="mt-1 flex items-center text-base">
          {{ userData.kyc.firstName + " " + userData.kyc.lastName }}
        </p>
      </div>
      <div class="box">
        <p class="text-sm font-medium dark:text-slate-200">{{ "Admin.Users.Bank_cards" | translate }}</p>
        <ng-container *ngIf="userData.cards?.length; else noCardsAdded">
          <div *ngFor="let card of userData.cards">
            <p class="mt-1 flex items-center text-base">{{ card }}</p>
          </div>
        </ng-container>
      </div>
      <div class="box">
        <p class="mb-2 text-sm font-medium dark:text-slate-200">{{ "Admin.Users.Risk_level" | translate }}</p>
        <app-dropdown
          [items]="userRiskLevels"
          (onSelect)="onRiskLevelSelect($event)"
          [disabled]="isUserObserverAdmin"
        >
          <button class="flex items-center gap-2">
            <p>{{ userRiskLevel }}</p>
            <app-svg-icon name="edit" class="w-3" />
          </button>
        </app-dropdown>
      </div>
    </div>

    <div class="flex flex-auto">
      <button
        class="btn-primary w-[12.5rem] px-5 py-2 text-sm"
        [class.btn_danger]="!isUserBlocked"
        (click)="handleBlockUnblock()"
        [disabled]="blockInProgress || isUserObserverAdmin || !isUserActive"
        *ngIf="!isUserBlockedForever"
      >
        {{ isUserBlocked ? ("Admin.Users.Unblock" | translate) : ("Admin.Users.Block" | translate) }}
      </button>
      <button
        class="btn-primary ml-2 w-[12.5rem] px-5 py-2 text-sm"
        [class.btn_danger]="isUserActive"
        (click)="handleDeactivate()"
        [disabled]="!isUserActive || isUserObserverAdmin"
        *ngIf="!isUserBlockedForever"
      >
        {{ "Admin.Users.Deactivate_account" | translate }}
      </button>
      <button
        class="btn-primary ml-2 w-[12.5rem] px-5 py-2 text-sm"
        [class.btn_danger]="true"
        (click)="handleDeleteIdentification()"
        [disabled]="isUserObserverAdmin || !userData.kyc || !isUserActive"
      >
        {{ "Admin.Users.Delete_identification" | translate }}
      </button>
      <button
        class="btn-primary ml-2 w-[12.5rem] px-5 py-2 text-sm"
        (click)="handleDownloadReport()"
        [disabled]="isUserObserverAdmin || !userData.kyc"
      >
        {{ "Admin.Users.Download_user_data" | translate }}
      </button>
    </div>
  </section>

  <section class="mt-10 grid grid-rows-1">
    <div class="flex items-center justify-between">
      <h3 class="title">{{ "Transaction.Transaction_history" | translate }}</h3>
      <a [routerLink]="'transactions'" class="btn-primary btn-medium">
        {{ "Transaction.All_transactions" | translate }}
      </a>
    </div>

    <app-tabs
      [tabs]="tokenSwitchValues"
      (onSelect)="switchTxType($event)"
      class="my-3 block overflow-x-auto overflow-y-hidden"
    />

    <ng-container *ngIf="!isTxsLoading; else txLoader">
      <ng-container *ngIf="hasTxs; else noTrans">
        <ng-container *ngIf="isBuySellTab; else bcTxs">
          <app-crypto-transaction-list-item *ngFor="let item of buySellTxs" [txDto]="item" />
        </ng-container>

        <ng-template #bcTxs>
          <app-transaction-list-item
            *ngFor="let item of txs"
            [txDto]="item"
            class="border-t border-gray-100 first:border-none dark:border-slate-700"
          />
        </ng-template>
      </ng-container>
      <ng-template #noTrans>
        <div class="text-center text-base">{{ "Transaction.No_transactions" | translate }}</div>
      </ng-template>
    </ng-container>

    <ng-template #txLoader>
      <app-transaction-list-item-skeleton></app-transaction-list-item-skeleton>
    </ng-template>
  </section>
</ng-container>

<jw-modal [id]="ModalConstants.DeleteKycConfirmation">
  <h2 class="title-modal">
    {{ "Admin.Users.Delete_confirmation_title" | translate: { user: userFullName } }}
  </h2>

  <div class="mt-5 flex items-center justify-center">
    <button class="btn h-[3rem] w-[12.5rem] px-5 py-2 text-sm" (click)="handleDeleteIdentificationCancel()">
      {{ "Common.No" | translate }}
    </button>
    <button
      class="btn ml-4 h-[3rem] w-[12.5rem] px-5 py-2 text-sm"
      [class.btn_danger]="true"
      (click)="handleDeleteIdentificationConfirm()"
    >
      {{ "Common.Yes" | translate }}
    </button>
  </div>
</jw-modal>

<jw-modal [id]="ModalConstants.DeactivateUserConfirmation">
  <h2 class="title-modal">
    {{ "Admin.Users.Deactivate_account_confirmation_title" | translate }}
  </h2>

  <div class="mt-5 flex items-center justify-center">
    <button class="btn h-[3rem] w-[12.5rem] px-5 py-2 text-sm" (click)="handleDeactivateCancel()">
      {{ "Common.No" | translate }}
    </button>
    <button
      class="btn ml-4 h-[3rem] w-[12.5rem] px-5 py-2 text-sm"
      [class.btn_danger]="true"
      (click)="handleDeactivateConfirm()"
    >
      {{ "Common.Yes" | translate }}
    </button>
  </div>
</jw-modal>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-template #userNotVerified>
  <p class="mt-1 flex items-center text-base">{{ "Admin.Users.User_not_verified" | translate }}</p>
</ng-template>

<ng-template #noCardsAdded>
  <p class="mt-1 flex items-center text-base">{{ "Admin.Users.No_bank_cards" | translate }}</p>
</ng-template>
