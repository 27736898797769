import { Component } from "@angular/core";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  selector: "app-black-list",
  templateUrl: "./black-list.component.html",
  styleUrls: ["./black-list.component.css"],
})
export class BlackListComponent {
  phoneNumber: string | undefined = undefined;

  constructor(private readonly _localStorage: LocalStorageService) {
    _localStorage.getUserData().then(x => {
      if (x != null) {
        this.phoneNumber = `+${x?.phoneNumber}`;
      }
    });
  }
}
