<div class="box">
  <div class="flex items-center">
    <app-skeleton class="w-16 h-16 [&>div]:rounded-full" />
    <app-skeleton class="w-64 h-12 ml-2" />
    <app-skeleton class="w-28 h-10 ml-auto" />
  </div>
  <div class="space-y-1 mt-2">
    <app-skeleton class="w-full h-6 max-md:w-44"></app-skeleton>
    <app-skeleton class="w-full h-6 max-md:w-44"></app-skeleton>
    <app-skeleton class="w-full h-6 max-md:w-44"></app-skeleton>
  </div>
</div>
