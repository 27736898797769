<section>
  <app-back-button></app-back-button>

<!--  <div class="box mt-5 grid grid-rows-1 gap-5" *ngIf="kycDto.name; else loader">-->
<!--    <div class="text-base">-->
<!--      <span class="font-light">{{ "Admin.Kyc.Phone_number" | translate }}</span>-->
<!--      <span class="block mt-1">-</span>-->
<!--    </div>-->
<!--    <div class="text-base">-->
<!--      <span class="font-light">{{ "Admin.Kyc.Name" | translate }}</span>-->
<!--      <span class="block mt-1">{{ kycDto.name }}</span>-->
<!--    </div>-->
<!--    <div class="text-base">-->
<!--      <span class="font-light">{{ "Admin.Kyc.Series" | translate }}</span>-->
<!--      <span class="block mt-1">{{ kycDto.passId }}</span>-->
<!--    </div>-->
<!--    <div class="text-base">-->
<!--      <span class="font-light">{{ "Admin.Kyc.Photo" | translate }}</span>-->
<!--      <div class="flex">-->
<!--        <img class="block mt-1 w-[26.25rem] bg-cover bg-no-repeat" [src]="kycImageSrc" alt="Photo" />-->
<!--        <a [href]="kycImageSrc" target="_blank" rel="noreferrer" class="w-7 ml-1">-->
<!--          <img src="assets/icons/link.svg" alt="Open in new page" />-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="w-[26.25rem] flex flex-col space-y-3">-->
<!--      <button class="btn w-full" (click)="handleApprove()">{{ "Common.Approve" | translate }}</button>-->
<!--      <hr class="divider" />-->
<!--      <button class="btn btn_dangerOutline w-full" (click)="handleBlock()">-->
<!--        {{ "Admin.Kyc.Black_list" | translate }}-->
<!--      </button>-->
<!--      <hr class="divider" />-->
<!--      <p class="text-text-pale text-base">{{ "Admin.Kyc.Repass" | translate }}</p>-->
<!--      <app-input-->
<!--        [title]="'Admin.Kyc.State_reason' | translate"-->
<!--        type="text"-->
<!--        id="reason"-->
<!--        [placeholder]="'Admin.Kyc.State_reason_placeholder' | translate"-->
<!--        [(ngModel)]="rejectionReason"-->
<!--      ></app-input>-->
<!--      <button class="btn btn_outline w-full" (click)="handleReject()">-->
<!--        {{ "Admin.Kyc.Send_to_repass" | translate }}-->
<!--      </button>-->
<!--    </div>-->

<!--    <div *ngIf="isVerdictError" class="text-sm text-invalid-color">-->
<!--      {{ "Admin.Kyc.Verdict_error" | translate }}-->
<!--    </div>-->
<!--  </div>-->

  <ng-template #loader>
    <app-loader></app-loader>
  </ng-template>
</section>
