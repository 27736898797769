<div class="block">
  <div *ngFor="let item of faqList; index as i" class="mb-5 max-md:mb-2">
    <button
      (click)="toggleAccordion(i)"
      class="flex w-full items-start justify-between rounded-lg bg-box-background p-7 text-left text-2xl font-bold shadow-md max-md:px-3 max-md:py-4 max-md:text-xl"
    >
      <p>{{ i + 1 }}. {{ item.question }}</p>
      <img
        src="assets/icons/chevron-down.svg"
        alt="arrow"
        class="h-14 w-14 transition-transform max-md:h-8 max-md:w-8"
        [ngClass]="{ '-rotate-180': item.opened }"
      />
    </button>

    <div [@openClose]="item.opened" class="ml-10 mt-2 overflow-hidden rounded-lg bg-secondary">
      <p class="p-7 text-2xl font-semibold max-md:px-3 max-md:py-4 max-md:text-xl">
        {{ item.answer }}
      </p>
    </div>
  </div>
</div>
