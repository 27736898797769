import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/common/constants/constants";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { Network } from "src/app/common/enums/network.enum";
import { TxPageType } from "src/app/common/enums/tx-page-type.enum";
import { UserRiskLevel } from "src/app/common/enums/user-risk-level.enum";
import { Transaction } from "src/app/common/models/transaction";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { getWalletLink } from "src/app/common/utils/wallet-link-helper.util";
import { ModalService } from "src/app/components/_modal";
import { BlackListService } from "src/app/services/black-list.service";
import { CryptoService } from "src/app/services/crypto.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PolygonService } from "src/app/services/polygon.service";
import { RatesService } from "src/app/services/rates.service";
import { ReportsService } from "src/app/services/reports.service";
import { ToastService } from "src/app/services/toast.service";
import { TronService } from "src/app/services/tron.service";
import { UserService } from "src/app/services/user.service";

interface IUserRiskLevel {
  name: string;
  value: UserRiskLevel;
}
@Component({
  templateUrl: "./user-page.component.html",
  styleUrls: ["./user-page.component.css"],
})
export class UserPageComponent implements OnInit {
  public userRiskLevelsList: IUserRiskLevel[] = [
    {
      name: "Admin.Users.Risk_level_none",
      value: UserRiskLevel.None,
    },
    {
      name: "Admin.Users.Risk_level_low",
      value: UserRiskLevel.Low,
    },
    {
      name: "Admin.Users.Risk_level_medium",
      value: UserRiskLevel.Medium,
    },
    {
      name: "Admin.Users.Risk_level_high",
      value: UserRiskLevel.High,
    },
  ];

  private userId: number = 0;
  private numOfLatestTxs = 3;

  public isLoading: boolean = true;
  public userData: UserDto;
  public trxWallet: string = "";
  public polygonWallet: string = "";

  private trxTxs: Transaction[] = [];
  private trxFingerprint: string | null = null;

  private usdtTxs: Transaction[] = [];
  private usdtFingerprint: string | null = null;

  private maticTxs: Transaction[] = [];

  private polygonUsdtTxs: Transaction[] = [];

  public tab: TxPageType = TxPageType.Trx;

  public isTxsLoading: boolean = true;
  public blockInProgress: boolean = false;
  public buySellTxs: CryptoTxDto[] = [];

  public ModalConstants = ModalConstants;

  public isUserObserverAdmin: boolean = false;

  public rates: RateDto[] = [];

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _blackListService: BlackListService,
    private readonly _router: Router,
    private readonly _userService: UserService,
    private readonly _tronService: TronService,
    private readonly _cryptoService: CryptoService,
    private readonly _translateService: TranslateService,
    private readonly _toastService: ToastService,
    private readonly _polygonService: PolygonService,
    private readonly _reportService: ReportsService,
    private readonly _modalService: ModalService,
    private readonly _ratesService: RatesService
  ) {
    this._activatedRoute.params.subscribe(params => {
      this.userId = params["id"];
    });
    this.userData = new UserDto();
  }

  async ngOnInit(): Promise<void> {
    await this.requestUserData();
    this.trxWallet = this.userData.wallets.find(x => x.network === Network.Tron)?.address ?? "";
    this.polygonWallet = this.userData.wallets.find(x => x.network === Network.Polygon)?.address ?? "";
    this.isLoading = false;
    this.isUserObserverAdmin = await this._localStorage.isUserObserverAdmin();

    await this.loadTxs();
    await this.loadRates();
  }

  public handleDownloadReport() {
    this._reportService.getUserPersonalDataReport(
      this.userId,
      this.userData.kyc?.firstName ?? "",
      this.userData.kyc?.lastName ?? ""
    );
  }

  public get txs() {
    switch (this.tab) {
      default:
      case TxPageType.Usdt:
        return this.usdtTxs;
      case TxPageType.Trx:
        return this.trxTxs;
      case TxPageType.Matic:
        return this.maticTxs;
      case TxPageType.PolygonUsdt:
        return this.polygonUsdtTxs;
    }
  }

  public get isUserBlocked() {
    return this.userData.blackList;
  }

  public get isUserBlockedForever() {
    return this.userData.blackList?.isForever;
  }

  public get isUserActive() {
    return this.userData.isActive;
  }

  public async handleBlockUnblock() {
    this.blockInProgress = true;

    if (this.isUserBlocked) {
      await this.unblockUser();
    } else {
      await this.blockUser();
    }

    this.blockInProgress = false;
  }

  public switchTxType(tabNumber: number) {
    this.tab = Array.from(Constants.TokenSwitchValues.keys())[tabNumber];
    this.loadTxs();
  }

  public get tokenSwitchValues() {
    return Array.from(Constants.TokenSwitchValues.values()).slice(0, -1);
  }

  public get isBuySellTab() {
    return this.tab === TxPageType.BuySell;
  }

  public get hasTxs() {
    return this.isBuySellTab ? this.buySellTxs.length > 0 : this.txs.length > 0;
  }

  public get userRiskLevel() {
    return this._translateService.instant(
      this.userRiskLevelsList.find(x => x.value == this.userData.riskLevel)?.name ??
        this.userRiskLevelsList[0].name
    );
  }

  public get userRiskLevels(): string[] {
    return this.userRiskLevelsList.map(x => this._translateService.instant(x.name));
  }

  public getWalletLink(network: Network, wallet: string) {
    return getWalletLink(network, wallet);
  }

  public getNetworkName(network: Network) {
    return getNetworkName(network);
  }

  public onRiskLevelSelect(selectedItem: string) {
    const selectedRiskLevel = this.userRiskLevelsList.filter(
      x => this._translateService.instant(x.name) == selectedItem
    )[0].value;

    this._userService
      .updateUserRiskLevel({
        userId: this.userData.id,
        userRiskLevel: selectedRiskLevel,
      })
      .then(res => {
        if (res.withError) {
          this._toastService.show(this._translateService.instant("Admin.Users.Risk_level_update_fail"));
        } else {
          this.userData.riskLevel = selectedRiskLevel;
          this._toastService.show(this._translateService.instant("Admin.Users.Risk_level_update_success"));
        }
      });
  }

  public get userFullName() {
    return this.userData.kyc?.firstName + " " + this.userData.kyc?.lastName;
  }

  public handleDeleteIdentification() {
    this._modalService.open(ModalConstants.DeleteKycConfirmation);
  }

  public handleDeleteIdentificationCancel() {
    this._modalService.close(ModalConstants.DeleteKycConfirmation);
  }

  public async handleDeleteIdentificationConfirm() {
    const response = await this._userService.deleteUserIdentification(this.userId);

    if (response.withError) {
      this._toastService.show(this._translateService.instant("Common.Unknown_error"));
    } else {
      this._toastService.show(this._translateService.instant("Admin.Users.Success_delete_identification"));
    }

    await this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.adminUsers}`);
  }

  public handleDeactivate() {
    this._modalService.open(ModalConstants.DeactivateUserConfirmation);
  }

  public handleDeactivateCancel() {
    this._modalService.close(ModalConstants.DeactivateUserConfirmation);
  }

  public async handleDeactivateConfirm() {
    const response = await this._userService.deactivateUser(this.userData.id);

    if (response.withError) {
      this._toastService.show(this._translateService.instant("Common.Unknown_error"));
    } else {
      this._toastService.show(this._translateService.instant("Admin.Users.Deactivate_account_success"));
    }

    await this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.adminUsers}`);
  }

  private async requestUserData() {
    const res = await this._userService.getUserById(this.userId);
    this.userData = res.params!;
  }

  private async loadTrxTxs() {
    const trxTxsRes = await this._tronService.getTrxTransactions(this.trxWallet, true, this.trxFingerprint);
    this.trxFingerprint = trxTxsRes.fingerPrint;
    this.trxTxs = trxTxsRes.items.slice(0, this.numOfLatestTxs);
  }

  private async loadUsdtTxs() {
    const usdtTxsRes = await this._tronService.getUsdtTransactions(
      this.trxWallet,
      true,
      this.usdtFingerprint
    );
    this.usdtFingerprint = usdtTxsRes.fingerPrint;
    this.usdtTxs = usdtTxsRes.items.slice(0, this.numOfLatestTxs);
  }

  private async loadBuySellTxs() {
    const buySellTxsRes = await this._cryptoService.getTransactions(1, this.numOfLatestTxs, this.userId);
    this.buySellTxs = buySellTxsRes.params?.items ?? [];
  }

  private async loadMaticTxs() {
    const maticTxsRes = await this._polygonService.getMaticTransactions(this.polygonWallet, 1);
    this.maticTxs = maticTxsRes.slice(0, this.numOfLatestTxs);
  }

  private async loadPolygonUsdtTxs() {
    const usdtTxsRes = await this._polygonService.getUsdtTransactions(this.polygonWallet, 1);
    this.polygonUsdtTxs = usdtTxsRes.slice(0, this.numOfLatestTxs);
  }

  private async blockUser() {
    await this._blackListService.blockUser(this.userId, false);
    this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.adminUsers}`);
  }

  private async unblockUser() {
    await this._blackListService.unblockUser(this.userId);
    this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.adminUsers}`);
  }

  private async loadTxs() {
    this.isTxsLoading = true;
    switch (this.tab) {
      case TxPageType.Matic:
        await this.loadMaticTxs();
        break;
      case TxPageType.PolygonUsdt:
        await this.loadPolygonUsdtTxs();
        break;
      case TxPageType.Usdt:
        await this.loadUsdtTxs();
        break;
      case TxPageType.Trx:
        await this.loadTrxTxs();
        break;
      case TxPageType.BuySell:
        await this.loadBuySellTxs();
        break;
      case TxPageType.WaitingConfirm:
        break;
      default:
        break;
    }
    this.isTxsLoading = false;
  }

  private async loadRates() {
    const rates = await this._ratesService.getRates();
    this.rates = rates.params ?? [];
  }
}
