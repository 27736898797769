import { Component } from "@angular/core";
import { NavigationEnd, ResolveEnd, ResolveStart, Router, RouterEvent } from "@angular/router";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { EventData } from "src/app/common/models/event-data";
import { EventBusService } from "src/app/services/event-bus.service";

@Component({
  selector: "app-client-layout",
  templateUrl: "./client-layout.component.html",
  styleUrls: ["./client-layout.component.css"],
})
export class ClientLayoutComponent {
  public resolverLoading = false;

  constructor(
    private router: Router,
    private _eventBusService: EventBusService
  ) {
    this.router.events.subscribe((routerEvent: any) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  private checkRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationEnd) {
      this._eventBusService.dispatch(new EventData(EventTypeConstants.ToggleFullscreenLoader, false));
    }

    if (routerEvent instanceof ResolveStart) {
      this.resolverLoading = true;
    }

    if (routerEvent instanceof ResolveEnd) {
      this.resolverLoading = false;
    }
  }
}
