<div class="grid grid-cols-2 items-stretch justify-center gap-2 max-md:grid-cols-1">
  <div
    class="flex gap-10 rounded-tw bg-box-background p-8 shadow-lg max-md:gap-5 max-md:px-3"
    *ngFor="let item of groupedCommissions"
  >
    <app-currency-icon [currency]="item.currency" />
    <div class="space-y-5">
      <p class="text-xl uppercase">{{ item.name }}</p>
      <p class="text-2xl font-bold max-md:text-xl" *ngFor="let comm of item.commissions">
        {{ comm | translate }}
      </p>
    </div>
  </div>
</div>
