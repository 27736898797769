<div>
  <p class="text-base mb-1">{{ caption }}</p>
  <div class="flex items-center justify-between">
    <div>
      <p class="text-[1.75rem]">{{ strings[0] }}</p>
      <p class="text-sm text-text-pale text-right -mt-1">= {{ strings[1] }}</p>
    </div>
    <button type="button" class="w-6" (click)="swap()">
      <img src="assets/icons/swap.svg" alt="swap" />
    </button>
  </div>
</div>
