<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)"
>
  <div
    class="flex w-[25rem] items-center justify-between gap-4 rounded-main border-min border-stroke-color bg-select-bg px-6 py-4 text-base text-text-secondary shadow-lg dark:border-slate-700 dark:bg-slate-800 max-md:w-full max-md:p-5"
  >
    {{ toast.body }}
    <button (click)="toastService.remove(toast)" class="w-6">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.6 20L4 18.4L10.4 12L4 5.6L5.6 4L12 10.4L18.4 4L20 5.6L13.6 12L20 18.4L18.4 20L12 13.6L5.6 20Z"
          class="fill-text-secondary"
        />
      </svg>
    </button>
  </div>
</ngb-toast>
