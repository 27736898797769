import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageConstants } from "src/app/common/constants/language.constants";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent {
  constructor(private readonly _translateService: TranslateService) {}

  public get language(): LanguageConstants {
    return this._translateService.currentLang as LanguageConstants;
  }

  public get currentYear(): number {
    return new Date().getFullYear();
  }
}
