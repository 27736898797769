import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EventListenerPageComponent } from "./event-listener-page/event-listener-page.component";
import { EventListenerRoutingModule } from "./event-listener-routing.module";

@NgModule({
  declarations: [EventListenerPageComponent],
  imports: [CommonModule, EventListenerRoutingModule],
  exports: [],
})
export class EventListenerModule {}
