<div class="flex items-start justify-between max-md:block">
  <h3 class="title">{{ "Transaction.Transaction_history" | translate }}:</h3>
  <app-tabs
    [tabs]="tokenSwitchValues"
    (onSelect)="switchTxType($event)"
    class="max-md: block max-w-[calc(100vw-2rem)] max-md:mt-5 max-md:overflow-x-auto"
  />
</div>

<section class="mt-5 space-y-5">
  <ng-container *ngIf="!isLoading; else txSkeleton">
    <ng-container *ngIf="hasTxs; else noTrans">
      <div *ngFor="let date of datesArray">
        <p class="text-xl text-[#1F0F57]">{{ date | customDate }}</p>
        <div class="mt-5 grid grid-rows-1 gap-5 max-md:gap-3">
          <app-transaction-list-item *ngFor="let item of txs[date]" [txDto]="item" [fullSize]="true" />
        </div>
      </div>
      <div class="flex items-center justify-end gap-x-5 max-md:justify-center">
        <button
          class="btn btn_pale py-3 max-md:flex-grow"
          (click)="onPrevPage()"
          [disabled]="!hasPrevPage || isLoading"
        >
          <app-svg-icon name="arrow-up" class="block aspect-square w-16 -rotate-[135deg] max-md:w-10" />
        </button>
        <button
          class="btn btn_pale py-3 max-md:flex-grow"
          (click)="onNextPage()"
          [disabled]="!hasNextPage || isLoading"
        >
          <app-svg-icon name="arrow-up" class="block aspect-square w-16 rotate-45 max-md:w-10" />
        </button>
      </div>
    </ng-container>

    <ng-template #noTrans>
      <div class="text-2xl font-bold text-select-bg">
        {{ "Transaction.No_transactions" | translate }}
      </div>
    </ng-template>
  </ng-container>

  <ng-template #txSkeleton>
    <div class="grid grid-rows-1 gap-5 max-md:gap-3">
      <app-transaction-list-item-skeleton />
      <app-transaction-list-item-skeleton />
    </div>
  </ng-template>
</section>
