import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appOnlyNumbers]",
})
export class OnlyNumbersDirective {
  private specialKeys: Array<string> = [
    "Backspace",
    "Tab",
    "End",
    "Home",
    "ArrowLeft",
    "ArrowRight",
    "Del",
    "Delete",
  ];

  constructor(private el: ElementRef) {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    // Prevent arrow keys
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }

    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Allow numpad numbers
    if (keyCode >= 96 && keyCode <= 105) {
      return;
    }

    // Allow only numbers
    if (/[^0-9]/.test(keyValue)) {
      event.preventDefault();
    }
  }
}
