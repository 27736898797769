export enum CryptoToken {
  Trx = 1,
  Usdt = 2,
  Matic = 3,
  PolygonUsdt = 4,
  Ton = 5,
  Not = 6,
  TonUsdt = 7,
  Bitcoin = 8,
}
