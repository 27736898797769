import { Injectable, NgZone } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
import { Constants } from "src/app/common/constants/constants";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { EventBusService } from "./event-bus.service";
import { EventTypeConstants } from "../common/constants/event-type.constants";
import { EventData } from "../common/models/event-data";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  public isSessionExpired: boolean = false;
  public remainingTime: number = 0;
  public $timeCounter: Subject<number> = new Subject<number>();

  private interval: any;
  private timeout: any;
  private readonly _sessionTimeout = 1000 * Constants.SessionTimeInSec; // 15 minutes

  constructor(
    private _localStorage: LocalStorageService,
    private _router: Router,
    private _zone: NgZone,
    private _eventBusService: EventBusService
  ) {}

  public startTimer() {
    clearInterval(this.interval);
    this.remainingTime = Constants.SessionTimeInSec;
    this.$timeCounter.next(this.remainingTime);

    this._zone.runOutsideAngular(() => {
      this.interval = setInterval(async () => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
          this.$timeCounter.next(this.remainingTime);
        } else {
          await this.onSessionExpired();
        }
      }, Constants.MsInSec);
    });
  }

  public resetTimeout() {
    clearTimeout(this.timeout);
    this.isSessionExpired = false;
    this.startTimer();
    this.timeout = setTimeout(() => {
      this.isSessionExpired = true;
    }, this._sessionTimeout);
  }

  public async getRemainingTime(): Promise<number> {
    const accessLifetime = await this._localStorage.accessTokenLifetime();

    if (accessLifetime != null) {
      return new Date(accessLifetime).getTime() / 1000 - new Date().getTime() / 1000;
    }

    return 0;
  }

  public async onSessionExpired() {
    await this._localStorage.clearTokens();
    await this._localStorage.removeUserData();
    this._eventBusService.dispatch(new EventData(EventTypeConstants.Logout));
    this._zone.run(() => {
      this._router.navigateByUrl("/");
    });
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    clearTimeout(this.timeout);
  }
}
