import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-string-swapper',
  templateUrl: './string-swapper.component.html',
  styleUrls: ['./string-swapper.component.css'],
})
export class StringSwapperComponent {
  @Input() public caption: string = '';
  @Input() public strings: string[] = [];

  constructor() {}

  swap() {
    [this.strings[0], this.strings[1]] = [this.strings[1], this.strings[0]];
  }
}
