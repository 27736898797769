import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { WalletBalanceDto } from "src/app/common/DTO/wallets/wallet-balance.dto";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { AccountRole } from "src/app/common/enums/account-role.enum";
import { UserResolver } from "src/app/common/resolvers/user.resolver";
import { BuySellModalComponent } from "src/app/components/buy-sell-modal/buy-sell-modal.component";
import { RatesService } from "src/app/services/rates.service";
import { WalletService } from "src/app/services/wallet.service";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: "./buy-sell.component.html",
  styleUrls: ["./buy-sell.component.css"],
})
export class BuySellComponent implements OnInit {
  wallets: WalletDto[] = [];
  rates: RateDto[] = [];
  selectedBalance: WalletBalanceDto | null = null;

  constructor(
    private readonly _ratesService: RatesService,
    private readonly _modalService: NgbModal,
    private readonly _walletService: WalletService,
    private readonly _userResolver: UserResolver
  ) {}

  ngOnInit() {
    this.init();
  }

  openBuyModal(selectedBalance: WalletBalanceDto) {
    const modalRef = this._modalService.open(BuySellModalComponent);
    modalRef.componentInstance.variant = "buy";
    modalRef.componentInstance.selectedBalance = selectedBalance;
  }

  openSellModal(selectedBalance: WalletBalanceDto) {
    const modalRef = this._modalService.open(BuySellModalComponent);
    modalRef.componentInstance.variant = "sell";
    modalRef.componentInstance.selectedBalance = selectedBalance;
  }

  private async getUserData() {
    const user = this._userResolver.user;
    if (user === null) {
      window.location.replace(environment.domain);
      return;
    }
    if (
      user.role == AccountRole.Admin ||
      user.role == AccountRole.SuperAdmin ||
      user.role == AccountRole.ObserverAmin
    ) {
      window.location.replace(`${environment.domain}${RouteConstants.admin}/${RouteConstants.adminHome}`);
      return;
    }
    if (user.blackList) {
      window.location.replace(`${environment.domain}${RouteConstants.blackList}`);
      return;
    }
  }

  private async getWallets() {
    const wallets = await this._walletService.getMy();
    this.wallets = wallets?.params || [];
  }

  private async getRates() {
    const rates = await this._ratesService.getRates();
    this.rates = rates?.params || [];
  }

  private async init() {
    await this.getUserData();
    await this.getWallets();
    await this.getRates();
  }
}
