<app-header></app-header>

<section class="main-container mx-auto max-w-[33.25rem] space-y-4 py-[3.75rem]">
  <h3 class="text-center text-base uppercase">{{ "Kyc.Verification" | translate }}</h3>

  <div class="box flex flex-col space-y-4 px-[1.875rem] py-[2.125rem] max-sm:text-center">
    <p class="text-center text-sm font-light">{{ "Login.Myid_do" | translate }}</p>
    <a *ngIf="!isMiniApp" [href]="myIdLink" target="_blank" class="btn mt-2">{{
      "Kyc.Do_verification" | translate
    }}</a>
    <button *ngIf="isMiniApp" class="btn mt-2" (click)="handleRedirect()">
      {{ "Kyc.Do_verification" | translate }}
    </button>
  </div>
</section>
