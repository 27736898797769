export class ValidateConstants {
  public static readonly PhoneNumberLength = 9;
  public static readonly CodeMinOrEqualVal = 100000;
  public static readonly CodeMaxVal = 999999;

  public static readonly MinPhoneLength = 11;
  public static readonly MaxPhoneLength = 13;
  public static readonly PhoneLengthWithoutCode = 9;

  public static readonly MinPswLength = 8;
  public static readonly MaxPswLength = 16;

  public static readonly PswPattern = "^(?=.*[a-zа-я])(?=.*[A-ZА-Я])(?=.*\\d)(?=.*\\W).{8,16}$";

  public static readonly KycNamePattern = "[a-zA-Zа-яА-Я]";
  public static readonly MaxKycNameLength = 50;
  public static readonly MinKycNameLength = 5;

  public static readonly MaxPassIdLength = 15;

  public static readonly ValidFileExtensions = ["image/png", "image/jpeg"];
  public static readonly MaxFileSize = 10_485760;
  public static readonly MaxFileNameLength = 200;

  public static readonly TrxAddressLength = 34;
  public static readonly TrxAddressPattern = "^[a-zA-Z0-9]+$";

  public static readonly PolygonAddressLength = 42;
  public static readonly PolygonAddressPattern = "^(0x)?[0-9a-fA-F]{40}$";

  public static readonly TonAddressLength = 48;
  public static readonly TonAddressPattern = "^[0-9a-zA-Z-_]{48}$";

  public static readonly BitcoinAddressLength = 42;
  public static readonly BitcoinAddressPattern = "^[a-zA-HJ-NP-Z0-9]{25,42}$";

  public static readonly MinCardNumberLength = 13;
  public static readonly MaxCardNumberLength = 19;
  public static readonly CardNumberLength = 16;

  public static readonly MinCardNameLength = 5;
  public static readonly MaxCardNameLength = 50;

  public static readonly PinflLength = 14;

  public static readonly IsNumericNotStartingWithZeroPattern = /^[1-9]\d*$/;
  public static readonly SearchNotNumberCharPattern = /\D/g;

  public static readonly MinCvvLength = 3;
  public static readonly MaxCvvLength = 4;

  public static readonly MaxOtpTries = 3;
}
