import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {ValidateConstants} from "src/app/common/constants/validate.constants";

export function validCodeRangeValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const value = control.value;

    if (!value || !/^\d+$/.test(value)) {
      return null;
    }

   const isValid = value >= ValidateConstants.CodeMinOrEqualVal && value < ValidateConstants.CodeMaxVal;

    return !isValid ? { codeOutOfRange: true } : null;
  }
}
