import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "./env.service";
import { ethers } from "ethers";
import { firstValueFrom } from "rxjs";
import { Transaction } from "src/app/common/models/transaction";
import { CryptoToken } from "src/app/common/enums/crypto-token.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { Network } from "src/app/common/enums/network.enum";
import { TonUsdtMasterAddress } from "../common/constants/ton-usdt.constant";
import { NotMasterAddress } from "../common/constants/not.constant";
import { ToncenterRawTxListDto } from "../common/DTO/toncenter/toncenter-raw-tx-list.dto";
import { ToncenterRawTxDto } from "../common/DTO/toncenter/toncenter-raw-tx.dto";
import { ToncenterJettonTxListDto } from "../common/DTO/toncenter/toncenter-jetton-tx-list.dto";
import { ToncenterJettonTxDto } from "../common/DTO/toncenter/toncenter-jetton-tx.dto";
import { Address, fromNano } from "@ton/ton";

@Injectable({
  providedIn: "root",
})
export class TonService {
  private readonly txPerPage = 10;
  private readonly notContractAddress = NotMasterAddress;
  private readonly usdtContractAddress = TonUsdtMasterAddress;
  private readonly bitcoinContractAddress = "";

  constructor(
    private readonly _http: HttpClient,
    private readonly _env: EnvService
  ) {}

  public async getTonTransactions(wallet: string, page: number): Promise<Transaction[]> {
    const txs: Transaction[] = [];

    let response = await this.getTonTxs(wallet, page, this.txPerPage, false);
    if (!response) return [];

    // If the rate limit reached, try again with the API key
    // if (
    //   typeof response.result === "string" &&
    //   response.result.includes("Max rate limit reached, please use API Key for higher rate limit")
    // ) {
    //   response = await this.getTonTxs(wallet, page, this.txPerPage, true);
    // }
    if (!response) return [];

    for (const tx of response.transactions) {
      const dto = new ToncenterRawTxDto();
      Object.assign(dto, tx);
      const parsed = this.parseRawTx(dto, wallet);
      txs.push(parsed);
    }

    return txs;
  }

  public async getNotTransactions(wallet: string, page: number): Promise<Transaction[]> {
    const txs: Transaction[] = [];

    let response = await this.getNotTxs(wallet, page, this.txPerPage, false);
    if (!response) return [];

    // if (
    //   typeof response.result === "string" &&
    //   response.result.includes("Max rate limit reached, please use API Key for higher rate limit")
    // ) {
    //   response = await this.getUsdtTxs(wallet, page, this.txPerPage, true);
    // }
    if (!response) return [];

    for (const tx of response.jetton_transfers) {
      const dto = new ToncenterJettonTxDto();
      Object.assign(dto, tx);
      const parsed = this.parseJettonTx(dto, wallet, CryptoToken.Not);
      txs.push(parsed);
    }

    return txs;
  }

  public async getUsdtTransactions(wallet: string, page: number): Promise<Transaction[]> {
    const txs: Transaction[] = [];

    let response = await this.getUsdtTxs(wallet, page, this.txPerPage, false);
    if (!response) return [];

    // if (
    //   typeof response.result === "string" &&
    //   response.result.includes("Max rate limit reached, please use API Key for higher rate limit")
    // ) {
    //   response = await this.getUsdtTxs(wallet, page, this.txPerPage, true);
    // }
    if (!response) return [];

    for (const tx of response.jetton_transfers) {
      const dto = new ToncenterJettonTxDto();
      Object.assign(dto, tx);
      const parsed = this.parseJettonTx(dto, wallet, CryptoToken.Not);
      txs.push(parsed);
    }

    return txs;
  }

  public async getBitcoinTransactions(wallet: string, page: number): Promise<Transaction[]> {
    const txs: Transaction[] = [];

    let response = await this.getBitcoinTxs(wallet, page, this.txPerPage, false);
    if (!response) return [];

    // if (
    //   typeof response.result === "string" &&
    //   response.result.includes("Max rate limit reached, please use API Key for higher rate limit")
    // ) {
    //   response = await this.getUsdtTxs(wallet, page, this.txPerPage, true);
    // }
    if (!response) return [];

    for (const tx of response.jetton_transfers) {
      const dto = new ToncenterJettonTxDto();
      Object.assign(dto, tx);
      const parsed = this.parseJettonTx(dto, wallet, CryptoToken.Not);
      txs.push(parsed);
    }

    return txs;
  }

  public async estimateTonTransferCost(): Promise<number> {
    return 0.0055;
  }

  private parseRawTx(tx: ToncenterRawTxDto, userWallet: string): Transaction {
    const txDto = new Transaction();

    const fromAddress = Address.parseRaw(tx.in_msg.source).toString();
    const toAddress = Address.parseRaw(tx.in_msg.destination).toString();

    txDto.from = fromAddress;
    txDto.to = toAddress;
    txDto.hash = tx.hash;
    txDto.createdAt = new Date(Number(tx.in_msg.created_at) * 1000);
    txDto.timestamp = Number(tx.in_msg.created_at) * 1000;
    txDto.fee = Number(tx.total_fees);
    txDto.id = tx.trace_id;
    txDto.type = fromAddress.toLowerCase() === userWallet.toLowerCase() ? TxType.Out : TxType.In;
    txDto.network = Network.Ton;
    txDto.amount = fromNano(tx.in_msg.value);
    txDto.status = tx.end_status !== "0" ? TxStatus.Approved : TxStatus.Canceled;
    txDto.token = CryptoToken.Ton;
    // txDto.isCommission = tx.methodId === "0xa9059cbb";
    txDto.isCommission = false;

    return txDto;
  }

  private parseJettonTx(tx: ToncenterJettonTxDto, userWallet: string, token: CryptoToken): Transaction {
    const txDto = new Transaction();

    txDto.from = tx.source_wallet;
    txDto.to = tx.response_destination;
    txDto.hash = tx.transaction_hash;
    txDto.createdAt = new Date(0 * 1000);
    txDto.timestamp = 0 * 1000;
    txDto.fee = 0 * 0;
    txDto.id = tx.trace_id;
    txDto.type = tx.source_wallet.toLowerCase() === userWallet.toLowerCase() ? TxType.Out : TxType.In;
    txDto.network = Network.Ton;
    txDto.amount = ethers.utils.formatUnits(tx.amount || 0, 0);
    txDto.status = TxStatus.Approved;
    txDto.token = token;

    return txDto;
  }

  private async getTonTxs(wallet: string, page: number = 1, offset: number = 10, withApiKey?: boolean) {
    const urlSearchParams = new URLSearchParams({
      account: wallet,
      limit: String(offset),
      offset: String((page - 1) * offset),
      sort: "desc",
    });

    const uri = `${this._env.tonCenterApiUrl}/transactions?${urlSearchParams.toString()}`;

    const headers: { [key: string]: string | string[] } = {};

    if (withApiKey) {
      headers["Authorization"] = this._env.tonCenterApiKey;
    }

    try {
      return (await firstValueFrom(this._http.get(uri, { headers }))) as ToncenterRawTxListDto;
    } catch (e) {
      return null;
    }
  }

  private async getNotTxs(wallet: string, page: number = 1, offset: number = 10, withApiKey?: boolean) {
    const urlSearchParams = new URLSearchParams({
      owner_address: wallet,
      jetton_wallet: this.notContractAddress,
      limit: String(offset),
      offset: String((page - 1) * offset),
      sort: "desc",
    });

    const uri = `${this._env.tonCenterApiUrl}/jetton/transfers?${urlSearchParams.toString()}`;

    const headers: { [key: string]: string | string[] } = {};

    if (withApiKey) {
      headers["Authorization"] = this._env.tonCenterApiKey;
    }

    try {
      return (await firstValueFrom(this._http.get(uri))) as ToncenterJettonTxListDto;
    } catch (e) {
      return null;
    }
  }

  private async getUsdtTxs(wallet: string, page: number = 1, offset: number = 10, withApiKey?: boolean) {
    const urlSearchParams = new URLSearchParams({
      owner_address: wallet,
      jetton_wallet: this.usdtContractAddress,
      limit: String(offset),
      offset: String((page - 1) * offset),
      sort: "desc",
    });

    const uri = `${this._env.tonCenterApiUrl}/jetton/transfers?${urlSearchParams.toString()}`;

    const headers: { [key: string]: string | string[] } = {};

    if (withApiKey) {
      headers["Authorization"] = this._env.tonCenterApiKey;
    }

    try {
      return (await firstValueFrom(this._http.get(uri))) as ToncenterJettonTxListDto;
    } catch (e) {
      return null;
    }
  }

  private async getBitcoinTxs(wallet: string, page: number = 1, offset: number = 10, withApiKey?: boolean) {
    const urlSearchParams = new URLSearchParams({
      owner_address: wallet,
      jetton_wallet: this.bitcoinContractAddress,
      limit: String(offset),
      offset: String((page - 1) * offset),
      sort: "desc",
    });

    const uri = `${this._env.tonCenterApiUrl}/jetton/transfers?${urlSearchParams.toString()}`;

    const headers: { [key: string]: string | string[] } = {};

    if (withApiKey) {
      headers["Authorization"] = this._env.tonCenterApiKey;
    }

    try {
      return (await firstValueFrom(this._http.get(uri))) as ToncenterJettonTxListDto;
    } catch (e) {
      return null;
    }
  }
}
