import { Pipe, PipeTransform } from "@angular/core";
import { Constants } from "../constants/constants";

@Pipe({
  name: "phoneDisplayer",
})
export class PhoneDisplayerPipe implements PipeTransform {
  transform(value: string): string {
    let phone = value.trim();

    if (phone.startsWith("7") && phone.length === 11) {
      return phone.replace(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/, "+$1 $2 $3 $4 $5");
    }

    if (phone.startsWith(Constants.UzsPhoneCode) && phone.length === 12) {
      return phone.replace(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/, "+$1 $2 $3 $4 $5");
    }

    return `+${phone}`;
  }
}
