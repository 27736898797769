<ng-container [ngSwitch]="step">
  <ng-template ngSwitchCase="input">
    <form class="flex flex-col space-y-6" [formGroup]="inputForm" (ngSubmit)="submitCredentials()">
      <app-input
        type="phone"
        [title]="'Login.Phone_number' | translate"
        placeholder="11 222 33 44"
        formControlName="phoneNumber"
        id="phoneNumber"
        [errorMsg]="phoneNumberError"
        mask="00 000 00 00"
        [autofocus]="true"
      />
      <app-input
        type="password"
        [title]="'Login.Password' | translate"
        placeholder="********"
        formControlName="psw"
        id="psw"
        [errorMsg]="pswError"
        [withPasswordToggle]="true"
      />
      <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>
      <button class="btn w-full" type="submit" [disabled]="inputForm.invalid || isPending">
        {{ "Login.Enter" | translate }}
      </button>
    </form>
  </ng-template>

  <ng-template ngSwitchCase="otp">
    <form [formGroup]="otpForm" (ngSubmit)="submitOtp()">
      <app-input
        type="number"
        [title]="'Login.Sms_code' | translate"
        placeholder="------"
        formControlName="code"
        id="otp"
        [errorMsg]="codeError"
        [timer]="otpTimer"
        (onRefresh)="sendConfirmCode()"
        [autocomplete]="'off'"
        [autofocus]="true"
      />

      <re-captcha
        *ngIf="otpInputCount >= validateConstants.MaxOtpTries"
        (resolved)="captchaResolved($event)"
      ></re-captcha>

      <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>

      <div class="mt-6 flex items-stretch gap-6">
        <button
          type="button"
          class="flex w-16 items-center justify-center rounded-xl bg-select-bg px-5"
          (click)="onBack()"
        >
          <img src="assets/icons/arrow-back-outline.svg" alt="back" />
        </button>
        <button type="submit" class="btn w-full" [disabled]="otpForm.invalid || isPending">
          {{ "Login.Enter" | translate }}
        </button>
      </div>
    </form>
  </ng-template>
</ng-container>
