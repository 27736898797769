import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css'],
})
export class BackButtonComponent {
  @Input() link: string = '';

  constructor(private _location: Location, private _router: Router) {}

  goBack() {
    if (this.link) {
      this._router.navigate([this.link]);
    } else {
      this._location.back();
    }
  }
}
