import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function validOtpValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const value = control.value;

    if (!value || !/^\d{6}$/.test(value)) {
      return { otpIsIncorrect: true };
    }
    return null;
  }
}