export class PolygonRawTx {
  public blockNumber = "";
  public timeStamp = "";
  public hash = "";
  public nonce = "";
  public blockHash = "";
  public transactionIndex = "";
  public from = "";
  public to = "";
  public value = "";
  public gas = "";
  public gasPrice = "";
  public isError = "";
  public txreceipt_status = "";
  public input = "";
  public contractAddress = "";
  public cumulativeGasUsed = "";
  public gasUsed = "";
  public confirmations = "";
  public methodId = "";
  public functionName = "";
}
