import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Constants } from "src/app/common/constants/constants";
import { EnvService } from "src/app/services/env.service";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { firstValueFrom } from "rxjs";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { BlackListErrorCode } from "src/app/common/enums/black-list-error-code.enum";

@Injectable({
  providedIn: "root",
})
export class BlackListService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(private readonly _httpClient: HttpClient, private _envService: EnvService) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  public async blockUser(
    userId: number,
    isForever: boolean
  ): Promise<HttpResultDto<BlackListErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.BlackLists}/${userId}/${isForever}`;
    const result = new HttpResultDto<BlackListErrorCode, null>(false);

    try {
      (await firstValueFrom(this._httpClient.post(uri, null, this._baseReqOpts))) as ApiResponseDto<null>;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async unblockUser(userId: number): Promise<HttpResultDto<BlackListErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.BlackLists}/${userId}/${UrnConstants.BlackListsUnblock}`;
    const result = new HttpResultDto<BlackListErrorCode, null>(false);

    try {
      (await firstValueFrom(this._httpClient.post(uri, null, this._baseReqOpts))) as ApiResponseDto<null>;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): BlackListErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return BlackListErrorCode.InternalError;
      case Constants.Unauthorized:
        return BlackListErrorCode.Unauthorized;
      default:
        return BlackListErrorCode.InternalError;
    }
  }
}
