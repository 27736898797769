import { Component, OnInit } from "@angular/core";
import { Transaction } from "src/app/common/models/transaction";
import { TronService } from "src/app/services/tron.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { Constants } from "src/app/common/constants/constants";
import { TxPageType } from "src/app/common/enums/tx-page-type.enum";
import { PlatformType } from "src/app/common/enums/platform-type.enum";
import { PolygonService } from "src/app/services/polygon.service";
import { Network } from "src/app/common/enums/network.enum";
import { TonService } from "src/app/services/ton.service";

@Component({
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.css"],
})
export class TransactionsComponent implements OnInit {
  public internalError = false;
  public platformType = PlatformType;

  private txPageType = TxPageType.Trx;

  private basePgSize = 10;
  public isLoading: boolean = true;

  private trxWallet = "";
  private polygonWallet = "";
  private tonWallet = "";
  private bitcoinWallet = "";

  private trxTxs: Transaction[] = [];
  private trxFingerprintHistory: any[] = [null];

  private usdtTxs: Transaction[] = [];
  private usdtFingerprintHistory: any[] = [null];

  public maticTxs: Transaction[] = [];
  public maticTxsPage = 1;

  public polygonUsdtTxs: Transaction[] = [];
  public polygonUsdtTxsPage = 1;

  public tonTxs: Transaction[] = [];
  public tonTxsPage = 1;

  public notTxs: Transaction[] = [];
  public notTxsPage = 1;

  public tonUsdtTxs: Transaction[] = [];
  public tonUsdtTxsPage = 1;

  public bitcoinTxs: Transaction[] = [];
  public bitcoinTxsPage = 1;

  constructor(
    private _tronService: TronService,
    public _localStorage: LocalStorageService,
    private _polygonService: PolygonService,
    private _tonService: TonService
  ) {}

  public async ngOnInit(): Promise<void> {
    const data = await this._localStorage.getUserData();
    if (data) {
      this.trxWallet = data.wallets.find(x => x.network === Network.Tron)?.address ?? "";
      this.polygonWallet = data.wallets.find(x => x.network === Network.Polygon)?.address ?? "";
      this.tonWallet = data.wallets.find(x => x.network === Network.Ton)?.address ?? "";
      this.bitcoinWallet = data.wallets.find(x => x.network === Network.Bitcoin)?.address ?? "";
    }
    await this.loadTxs();
  }

  public get txs(): { [key: string]: Transaction[] } {
    let groupedTxs = {};

    switch (this.txPageType) {
      case TxPageType.Usdt:
        groupedTxs = this.groupTxsByDate(this.usdtTxs);
        break;
      case TxPageType.Trx:
        groupedTxs = this.groupTxsByDate(this.trxTxs);
        break;
      case TxPageType.Matic:
        groupedTxs = this.groupTxsByDate(this.maticTxs);
        break;
      case TxPageType.PolygonUsdt:
        groupedTxs = this.groupTxsByDate(this.polygonUsdtTxs);
        break;
      case TxPageType.Ton:
        groupedTxs = this.groupTxsByDate(this.tonTxs);
        break;
      case TxPageType.Not:
        groupedTxs = this.groupTxsByDate(this.notTxs);
        break;
      case TxPageType.TonUsdt:
        groupedTxs = this.groupTxsByDate(this.tonUsdtTxs);
        break;
      case TxPageType.Bitcoin:
        groupedTxs = this.groupTxsByDate(this.bitcoinTxs);
        break;
      default:
        throw new Error(`txPageType value ${this.txPageType} out of range`);
    }
    return groupedTxs;
  }

  public get datesArray(): string[] {
    return Object.keys(this.txs);
  }

  public get hasPrevPage(): boolean {
    switch (this.txPageType) {
      case TxPageType.Trx:
        return this.trxFingerprintHistory.length > 2;
      case TxPageType.Usdt:
        return this.usdtFingerprintHistory.length > 2;
      case TxPageType.Matic:
        return this.maticTxsPage > 1;
      case TxPageType.PolygonUsdt:
        return this.polygonUsdtTxsPage > 1;
      default:
        return false;
    }
  }

  public get hasNextPage(): boolean {
    switch (this.txPageType) {
      case TxPageType.Trx:
        return this.trxFingerprintHistory[this.trxFingerprintHistory.length - 1] != null;
      case TxPageType.Usdt:
        return this.usdtFingerprintHistory[this.usdtFingerprintHistory.length - 1] != null;
      case TxPageType.Matic:
        return this.maticTxs.length === this.basePgSize;
      case TxPageType.PolygonUsdt:
        return this.polygonUsdtTxs.length === this.basePgSize;
      default:
        return false;
    }
  }

  public onPrevPage() {
    switch (this.txPageType) {
      case TxPageType.Trx:
        this.trxFingerprintHistory.splice(-2);
        this.loadTrxTxs();
        break;
      case TxPageType.Usdt:
        this.usdtFingerprintHistory.splice(-2);
        this.loadUsdtTxs();
        break;
      case TxPageType.Matic:
        this.maticTxsPage--;
        this.loadMaticTxs();
        break;
      case TxPageType.PolygonUsdt:
        this.polygonUsdtTxsPage--;
        this.loadPolygonUsdtTxs();
        break;
    }
  }

  public onNextPage() {
    switch (this.txPageType) {
      case TxPageType.Trx:
        this.loadTrxTxs();
        break;
      case TxPageType.Usdt:
        this.loadUsdtTxs();
        break;
      case TxPageType.Matic:
        this.maticTxsPage++;
        this.loadMaticTxs();
        break;
      case TxPageType.PolygonUsdt:
        this.polygonUsdtTxsPage++;
        this.loadPolygonUsdtTxs();
        break;
    }
  }

  public switchTxType(tabNumber: number) {
    this.txPageType = Array.from(Constants.TokenSwitchValues.keys())[tabNumber];
    this.trxFingerprintHistory = [null];
    this.usdtFingerprintHistory = [null];
    this.maticTxsPage = 1;
    this.polygonUsdtTxsPage = 1;
    this.tonTxsPage = 1;
    this.notTxsPage = 1;
    this.tonUsdtTxsPage = 1;
    this.bitcoinTxsPage = 1;
    this.loadTxs();
  }

  public get tokenSwitchValues(): string[] {
    return Array.from(Constants.TokenSwitchValues.values()).slice(0, -2);
  }

  public get hasTxs() {
    return Object.keys(this.txs).length > 0;
  }

  private async loadTxs() {
    this.isLoading = true;
    switch (this.txPageType) {
      case TxPageType.Matic:
        await this.loadMaticTxs();
        break;
      case TxPageType.PolygonUsdt:
        await this.loadPolygonUsdtTxs();
        break;
      case TxPageType.Usdt:
        await this.loadUsdtTxs();
        break;
      case TxPageType.Trx:
        await this.loadTrxTxs();
        break;
      case TxPageType.Ton:
        await this.loadTonTxs();
        break;
      case TxPageType.TonUsdt:
        await this.loadTonUsdtTxs();
        break;
      case TxPageType.Not:
        await this.loadNotTxs();
        break;
      case TxPageType.Bitcoin:
        await this.loadBitcoinTxs();
        break;
      default:
        break;
    }
    this.isLoading = false;
  }

  private async loadTrxTxs() {
    let nextPageFingerprint;
    if (this.trxFingerprintHistory.length <= 1) {
      nextPageFingerprint = null;
    } else {
      nextPageFingerprint = this.trxFingerprintHistory[this.trxFingerprintHistory.length - 1];
    }

    const trxTxsRes = await this._tronService.getTrxTransactions(this.trxWallet, true, nextPageFingerprint);
    this.trxFingerprintHistory.push(trxTxsRes.fingerPrint);
    this.trxTxs = trxTxsRes.items;
  }

  private async loadUsdtTxs() {
    let nextPageFingerprint;
    if (this.usdtFingerprintHistory.length <= 1) {
      nextPageFingerprint = null;
    } else {
      nextPageFingerprint = this.usdtFingerprintHistory[this.usdtFingerprintHistory.length - 1];
    }
    const usdtTxsRes = await this._tronService.getUsdtTransactions(this.trxWallet, true, nextPageFingerprint);
    this.usdtFingerprintHistory.push(usdtTxsRes.fingerPrint);
    this.usdtTxs = usdtTxsRes.items;
  }

  private async loadMaticTxs() {
    const maticTxsRes = await this._polygonService.getMaticTransactions(
      this.polygonWallet,
      this.maticTxsPage
    );
    this.maticTxs = maticTxsRes;
  }

  private async loadPolygonUsdtTxs() {
    const usdtTxsRes = await this._polygonService.getUsdtTransactions(
      this.polygonWallet,
      this.polygonUsdtTxsPage
    );
    this.polygonUsdtTxs = usdtTxsRes;
  }

  private async loadTonTxs() {
    const usdtTxsRes = await this._tonService.getTonTransactions(this.tonWallet, this.tonTxsPage);
    this.tonTxs = usdtTxsRes;
  }

  private async loadTonUsdtTxs() {
    const usdtTxsRes = await this._tonService.getUsdtTransactions(this.tonWallet, this.tonUsdtTxsPage);
    this.tonUsdtTxs = usdtTxsRes;
  }

  private async loadNotTxs() {
    const usdtTxsRes = await this._tonService.getNotTransactions(this.tonWallet, this.notTxsPage);
    this.notTxs = usdtTxsRes;
  }

  private async loadBitcoinTxs() {
    const usdtTxsRes = await this._tonService.getBitcoinTransactions(this.tonWallet, this.notTxsPage);
    this.notTxs = usdtTxsRes;
  }

  private groupTxsByDate(txs: Transaction[]): { [key: string]: Transaction[] } {
    return txs.reduce(
      (acc, tx) => {
        const date = new Date(tx.createdAt).toDateString();
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(tx);
        return acc;
      },
      {} as { [key: string]: Transaction[] }
    );
  }
}
