import { Component, Input } from "@angular/core";

@Component({
  selector: "app-password-checker",
  templateUrl: "./password-checker.component.html",
  styleUrls: ["./password-checker.component.css"],
})
export class PasswordCheckerComponent {
  @Input() password: string | null = null;
  @Input() confirmPassword: string | null = null;

  constructor() {}

  public get passwordRequirements() {
    return [
      { text: "Password_change.Min_length", pattern: ".{6,}" },
      { text: "Password_change.Max_length", pattern: "^.{1,16}$" },
      { text: "Password_change.Numbers", pattern: "\\d+" },
      { text: "Password_change.Special", pattern: "\\W" },
      { text: "Password_change.Uppercase", pattern: "[A-Z]" },
      { text: "Password_change.Password_match", pattern: "passwordMatch" },
    ];
  }

  public getIsPasswordReqValid(pattern: string): boolean {
    if (this.password == null) {
      return false;
    }

    if (pattern === "passwordMatch") {
      return this.password === this.confirmPassword;
    } else {
      return new RegExp(pattern).test(this.password);
    }
  }
}
