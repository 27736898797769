import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
})
export class DropdownComponent {
  @HostListener('window:click', ['$event'])
  handleClick(event: KeyboardEvent) {
    this.hideOnClick(event);
  }

  @Input() items: string[] = [];
  @Input() disabled: boolean = false;

  @Output() onSelect = new EventEmitter<string>();

  isOpen = false;

  constructor() {}

  toggleSelect() {
    this.isOpen = !this.isOpen;
  }

  pickItem(item: string) {
    if(!this.disabled) {
      this.onSelect.emit(item);
    }
  }

  hideOnClick(e: any) {
    e.stopPropagation();
    if (
      !e.target.classList.contains('dropdown__list') &&
      e.target.parentNode?.nodeName !== 'BUTTON'
    ) {
      this.isOpen = false;
    }
  }
}
