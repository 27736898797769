<label class="customInput">
  <p>{{ title }}</p>

  <div class="customInput__inputWrapper" [ngClass]="wrapperClasses">
    <!-- Phone code -->
    <span
      *ngIf="type === 'phone'"
      class="absolute left-3 top-1/2 -translate-y-1/2 text-base font-medium leading-none"
    >
      +{{ phoneCode }}
    </span>

    <input
      [type]="type"
      [name]="name"
      [id]="id"
      [placeholder]="placeholder"
      [ngClass]="{
        'is-valid': errorMsg == null && control.value != null,
        'is-invalid': errorMsg != null,
        '!pl-14': type === 'phone',
        '!pr-14': withPasswordToggle || timer
      }"
      [formControl]="control"
      [mask]="mask"
      [dropSpecialCharacters]="dropSpecialCharacters"
      onmousewheel="return false;"
      [autocomplete]="autocomplete"
      [max]="max"
      [min]="min"
      [appAutofocus]="autofocus"
      (input)="handleMaxLength($event)"
    />
    <!-- Show/hide password -->
    <button
      *ngIf="withPasswordToggle"
      type="button"
      class="absolute right-4 top-1/2 flex h-7 w-7 -translate-y-1/2 items-center justify-center opacity-70"
      (click)="togglePassword()"
    >
      <app-svg-icon [name]="isPasswordShown ? 'eye-on' : 'eye-off'" class="block h-6 w-6" />
    </button>
  </div>

  <!-- Time counter -->
  <button
    *ngIf="withTimer"
    [disabled]="timeCounter > 0"
    class="mx-auto mt-4 block text-center text-sm text-black hover:text-green-400 disabled:text-black/40 dark:text-slate-400"
    (click)="onRefreshClick()"
  >
    <span class="border-b border-dashed">
      Отправить новый код <span *ngIf="timeCounter > 0">({{ timeCounter }})</span>
    </span>
  </button>

  <ng-container *ngIf="comment.length">
    <span class="block text-sm text-text-pale" *ngFor="let c of comment">
      {{ c }}
    </span>
  </ng-container>
  <span *ngIf="errorMsg != null" class="customInput__error">
    {{ errorMsg }}
  </span>
</label>
