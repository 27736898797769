import { Component, EventEmitter, Input, Output } from "@angular/core";

type StatusVariant = "pending" | "error" | "success" | "awaiting";

@Component({
  selector: "app-status-window",
  templateUrl: "./status-window.component.html",
  styleUrls: ["./status-window.component.css"],
})
export class StatusWindowComponent {
  @Input() public variant: StatusVariant = "pending";
  @Input() public successText: string = "";

  @Output() public onRetry = new EventEmitter();

  constructor() {}

  public onRetryClick(): void {
    this.onRetry.emit();
  }
}
