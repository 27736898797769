import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { kycLengthValidator } from "src/app/common/validators/kyc-length.validator";
import { photoFileExtensionValidator } from "src/app/common/validators/photo-file-extension.validator";
import { kycNameValidator } from "src/app/common/validators/kyc-name.validator";

export const kycFormGroupValidator = new FormGroup({
  passId: new FormControl(null, [
    Validators.required,
    Validators.maxLength(ValidateConstants.MaxPassIdLength),
  ]),

  pinfl: new FormControl(null, [
    Validators.required,
    Validators.pattern(`^[0-9]{${ValidateConstants.PinflLength}}$`),
  ]),

  name: new FormControl(null, [
    Validators.required,
    Validators.minLength(ValidateConstants.MinKycNameLength),
    kycLengthValidator(),
    kycNameValidator(),
  ]),

  file: new FormControl(null, [Validators.required, photoFileExtensionValidator()]),

  passFile: new FormControl(null, [Validators.required, photoFileExtensionValidator()]),
});
