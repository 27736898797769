<div *ngFor="let currency of currencies" class="mb-5">
  <h3 class="title">{{ "Admin.Home.Commission" | translate }} {{ getCurrencyName(currency) }}</h3>
  <div class="box mt-5 grid grid-rows-1 gap-3">
    <p class="text-sm font-medium">{{ "Admin.Home.Buy_sell" | translate }}</p>
    <ng-container *ngFor="let item of getCommissions(currency)">
      <div class="flex items-center gap-2">
        <span class="text-sm font-bold">
          {{
            "Admin.Home.Up_to"
              | translate: { value: item.maxAmount | mfeCustom, ticker: getCurrencyName(currency) }
          }}
        </span>
        <span>{{ item.percents }}%</span>
        <button class="ml-auto w-6" (click)="deleteCommission(item.id)" [disabled]="isUserObserverAdmin">
          <img src="assets/icons/delete.svg" alt="Delete" />
        </button>
      </div>
      <hr class="divider" />
    </ng-container>

    <div class="flex items-center gap-2">
      <span></span>
      <button
        class="ml-auto w-6"
        [disabled]="isUserObserverAdmin"
        (click)="handleOpenModal(currency, createCommissionModal)"
      >
        <img src="assets/icons/plus.svg" alt="Add" />
      </button>
    </div>
  </div>
</div>

<ng-template let-modal #createCommissionModal>
  <form class="flex flex-col space-y-4" [formGroup]="form" (ngSubmit)="onModalSubmit()">
    <h4 class="title-big">{{ "Admin.Home.Commission" | translate }}</h4>
    <app-input
      [title]="('Admin.Home.Check_sum' | translate) + ' ' + getCurrencyName(selectedCurrency)"
      type="number"
      placeholder="100"
      formControlName="maxAmount"
      [errorMsg]="amountError"
    />
    <app-input
      [title]="('Admin.Home.Commission' | translate) + ' %'"
      type="number"
      placeholder="2.5"
      formControlName="percent"
      [errorMsg]="percentError"
    />
    <button type="submit" class="btn" [disabled]="form.invalid || isFormPending || !!amountError">
      {{ "Admin.Home.Add" | translate }}
    </button>
  </form>
</ng-template>
