<div class="relative mx-auto px-6 py-7 max-md:p-0 md:w-[31rem]">
  <ng-container [ngSwitch]="step">
    <ng-template [ngSwitchCase]="Step.Input">
      <div class="max-md:flex max-md:items-center max-md:justify-between">
        <button
          (click)="onClose()"
          class="relative h-8 w-8 max-md:rounded-lg max-md:bg-red-50 dark:max-md:bg-slate-700 md:absolute md:-right-5 md:top-2"
        >
          <app-svg-icon name="close" class="max-md:hidden" />
          <app-svg-icon name="actions/back" class="w-4 text-slate-400 dark:text-white md:hidden" />
        </button>
        <div class="space-y-2 text-center">
          <h1 class="text-xl font-semibold">
            <span class="max-md:hidden">Отправить криптовалюту</span>
            <span class="hidden max-md:block">Отправить</span>
          </h1>
          <p class="font-medium text-slate-400 dark:text-slate-200 max-md:hidden">
            Для отправки валюты ...пополнить
          </p>
        </div>
        <div class="block h-6 w-6 md:hidden">
          <!-- <app-svg-icon name="info" class="h-8 w-8" /> -->
        </div>
      </div>

      <form
        class="mt-6 space-y-4 rounded-2xl bg-gray-25 p-4 dark:bg-slate-800 max-md:mt-3"
        [formGroup]="sendForm"
        (ngSubmit)="submitInput()"
      >
        <ng-container *ngIf="walletBalance !== null">
          <div class="flex items-start gap-x-2">
            <app-currency-icon [currency]="walletBalance.currency" />
            <div class="flex flex-col gap-y-2">
              <span class="font-medium">{{ getCurrencyName(walletBalance.currency) }}</span>
              <span class="font-medium text-slate-400">{{ getNetworkName(wallet.network) }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="walletBalance === null && selectedWallet.address.length > 0">
          <div class="space-y-4">
            <div>
              <span class="mb-1 block font-medium">Криптовалюта</span>
              <app-currency-dropdown
                [items]="currencies"
                [isFullWidthList]="true"
                (onSelect)="selectWalletBalanceByCurrency($event)"
              >
                <div
                  class="flex items-center gap-x-2 rounded-2xl bg-white px-4 py-2.5 font-medium text-slate-400 dark:border dark:border-slate-400 dark:bg-slate-800 dark:text-white"
                >
                  <app-currency-icon
                    *ngIf="selectedWalletBalance !== null"
                    [currency]="selectedWalletBalance.currency"
                    size="xs"
                  />
                  <span class="flex-1 py-2">{{ selectedCurrencyName }}</span>
                  <img src="assets/icons/dropdown-arrow.svg" alt="" class="h-2.5 w-3" />
                </div>
              </app-currency-dropdown>
            </div>

            <div>
              <span class="mb-1 block font-medium">Сеть</span>
              <app-network-dropdown
                [items]="networks"
                [isFullWidthList]="true"
                (onSelect)="selectNetwork($event)"
              >
                <div
                  class="flex items-center rounded-2xl bg-white px-4 py-2.5 font-medium text-slate-400 dark:border dark:border-slate-400 dark:bg-slate-800 dark:text-white"
                >
                  <span class="flex-1 py-2">{{ networkName }}</span>
                  <img src="assets/icons/dropdown-arrow.svg" alt="" class="h-2.5 w-3" />
                </div>
              </app-network-dropdown>
            </div>
          </div>
        </ng-container>

        <app-skeleton *ngIf="!selectedWallet.address" class="h-12" />

        <fieldset [disabled]="wallets.length === 0" class="flex flex-col gap-y-4">
          <app-input
            id="token"
            type="text"
            name="token"
            formControlName="to"
            title="Адрес"
            placeholder="Введите"
            [errorMsg]="toError"
          />
          <app-input
            id="amount"
            type="number"
            name="amount"
            formControlName="amount"
            title="Сумма"
            placeholder="100"
            appPositiveDecimals
            [maxLength]="selectedWallet.network === Network.Bitcoin ? 6 : 3"
            [errorMsg]="amountError"
          />
        </fieldset>

        <div class="flex justify-between text-sm font-medium">
          <div class="flex flex-col gap-y-1">
            <span class="text-slate-400">Доступный баланс</span>
            <span>
              {{ selectedWalletBalance?.amount | mfeCustom: { currency: selectedWalletBalance?.currency! } }}
              {{ selectedCurrencyName }}
            </span>
          </div>
          <div class="flex flex-col items-end gap-y-1">
            <span class="text-slate-400">Комиссия за вывод</span>
            <span>
              {{ networkFee | mfeCustom: { currency: selectedWalletBalance?.currency! } }}
              {{ nativeCurrencyName }}
            </span>
          </div>
        </div>

        <div class="flex items-start gap-x-2 rounded-[0.625rem] bg-gold-200 p-2 dark:bg-gold-500">
          <img src="assets/icons/warning.svg" alt="" class="h-8 w-8" />
          <span class="text-sm text-slate-400 dark:text-black">
            Будьте внимательны при заполнении полей, транзакцию отменить не получится.
          </span>
        </div>

        <div class="mt-6">
          <button type="submit" class="btn-primary btn-medium w-full">Отправить</button>
        </div>
      </form>
    </ng-template>

    <ng-template [ngSwitchCase]="Step.Confirm">
      <h2 class="text-center text-2xl font-semibold">
        Вы уверены что хотите перевести
        {{ sendForm.controls["amount"].value | mfeCustom: { currency: selectedWalletBalance?.currency } }}
        {{ selectedCurrencyName }}?
      </h2>
      <p class="my-6 text-center font-medium text-slate-400">{{ sendForm.controls["to"].value }}</p>
      <p *ngIf="businessError" class="mb-2 text-center text-sm text-red-400">{{ businessError }}</p>
      <div class="grid grid-cols-2 gap-x-4">
        <button class="btn-primary-dark btn-medium" (click)="step = Step.Input">Нет</button>
        <button class="btn-primary btn-medium" (click)="onConfirm()" [disabled]="isPending">Перевести</button>
      </div>
    </ng-template>

    <ng-template [ngSwitchCase]="Step.Success">
      <h2 class="text-center text-2xl font-semibold">Успешно</h2>
      <img src="assets/images/success-check-mark.png" alt="success" class="mx-auto mt-6 w-[7.5rem]" />
      <button class="btn-primary-dark btn-medium mt-6 w-full" (click)="onClose()">Закрыть</button>
    </ng-template>

    <ng-template [ngSwitchCase]="Step.NoCrypto">
      <h2 class="text-center text-2xl font-semibold">
        На данный момент у вас нет крипто-активов.<br />
        Хотите приобрести?
      </h2>
      <div class="mt-6 grid grid-cols-2 gap-x-4">
        <button class="btn-primary-dark btn-medium" (click)="step = Step.Input">Нет</button>
        <button class="btn-primary btn-medium" (click)="onOpenSwapModal()">Да</button>
      </div>
    </ng-template>
  </ng-container>
</div>

<!-- <ng-container [ngSwitch]="step">
  <h2 class="title-modal">{{ "Send.Title" | translate }}</h2>
  <app-stepper [totalSteps]="3" [currentStep]="step" class="mb-5 mt-2 block" />

  <ng-template ngSwitchCase="1">
    <div class="space-y-5">
      <div *ngFor="let wallet of wallets" class="grid grid-cols-1 gap-y-5 max-md:gap-y-3">
        <app-balance-card
          *ngFor="let balance of wallet.balances"
          [isShort]="true"
          [withSelect]="true"
          [balanceItem]="balance"
          (onSelect)="onSelectBalance($event, wallet.address)"
          [selected]="selectedWallet.address === wallet.address && selectedWallet.balances[0] === balance"
        />
      </div>
      <app-balance-card *ngIf="wallets.length === 0" [isShort]="true" [isLoading]="true" />
      <hr class="border-border-input-color" />
      <form class="flex flex-col gap-y-5" [formGroup]="sendForm" (ngSubmit)="submitInput()">
        <fieldset [disabled]="wallets.length === 0" class="flex flex-col space-y-5">
          <app-input
            [title]="'Send.Amount' | translate"
            type="number"
            name="amount"
            id="amount"
            placeholder="100"
            formControlName="amount"
            appPositiveDecimals
            [maxLength]="selectedWallet.network === Network.Bitcoin ? 6 : 3"
            [errorMsg]="amountError"
          />
          <app-input
            [title]="'Send.Receiver' | translate"
            type="text"
            name="token"
            id="token"
            [placeholder]="walletPlaceholder"
            formControlName="to"
            [errorMsg]="toError"
          />
        </fieldset>

        <ng-container *ngIf="isTelegramMiniApp && selectedWallet.network === Network.Ton">
          <button
            type="button"
            [disabled]="!tonWalletAddress"
            class="flex items-center gap-x-4 rounded-2xl bg-input-bg p-4 disabled:cursor-not-allowed disabled:opacity-50"
            (click)="useTonWalletAddressAsReceiver()"
          >
            <img src="assets/images/ton-wallet-medium.png" alt="" class="w-9" />
            <div>
              <h4 class="!m-0 !mb-1 font-semibold text-ton">Отправить в "Кошелек"</h4>
              <p class="!m-0 text-sm opacity-35">
                В качестве получателя будет использован адрес подключенного кошелька
              </p>
            </div>
          </button>
          <p *ngIf="!tonWalletAddress" class="flex items-center justify-between text-text-pale">
            Telegram кошелек не подключен
          </p>
        </ng-container>

        <p class="flex items-center justify-between text-text-pale">
          {{ "Send.Network_commission" | translate }}:
          <span
            >{{ networkFee | mfeCustom: true : selectedWallet.network === Network.Bitcoin }}
            {{ nativeCurrencyName }}</span
          >
        </p>
        <p class="flex items-center justify-between text-text-pale">
          {{ "Send.Receive" | translate }}:
          <span class="text-2xl font-bold">
            {{ receiveAmount | mfeCustom: true : selectedWallet.network === Network.Bitcoin }}
            {{ selectedCurrencyName }}
          </span>
        </p>
        <p class="flex items-center justify-between text-text-pale">
          {{ "Send.Total" | translate }}:
          <span class="text-2xl font-bold">
            {{ sendAmount | mfeCustom: true : selectedWallet.network === Network.Bitcoin }}
            {{ selectedCurrencyName }}
          </span>
        </p>

        <button type="submit" class="btn" [disabled]="sendForm.invalid || !!amountError || isCalculating">
          {{ "Common.Next" | translate }}
        </button>
      </form>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="2">
    <div class="space-y-5">
      <app-balance-card [isShort]="true" [balanceItem]="selectedWallet.balances[0]" />
      <p class="flex flex-wrap items-center justify-between text-text-pale max-md:max-w-[calc(100vw-6rem)]">
        {{ "Transaction.From" | translate }}:
        <span class="truncate font-bold text-link-color">{{ selectedWallet.address }}</span>
      </p>
      <p class="flex flex-wrap items-center justify-between text-text-pale max-md:max-w-[calc(100vw-6rem)]">
        {{ "Transaction.To" | translate }}:
        <span class="truncate font-bold text-link-color">{{ sendForm.get("to")?.value }}</span>
      </p>
      <p class="flex items-center justify-between text-text-pale">
        {{ "Send.Network_commission" | translate }}:
        <span
          >{{ networkFee | mfeCustom: true : selectedWallet.network === Network.Bitcoin }}
          {{ nativeCurrencyName }}</span
        >
      </p>
      <p class="flex items-center justify-between text-text-pale">
        {{ "Send.Receive" | translate }}:
        <span class="text-2xl font-bold">
          {{ receiveAmount | mfeCustom: true : selectedWallet.network === Network.Bitcoin }}
          {{ selectedCurrencyName }}
        </span>
      </p>
      <p class="flex items-center justify-between text-text-pale">
        {{ "Send.Total" | translate }}:
        <span class="text-2xl font-bold"
          >{{ sendAmount | mfeCustom: true : selectedWallet.network === Network.Bitcoin }}
          {{ selectedCurrencyName }}</span
        >
      </p>
      <hr class="border-border-input-color" />
      <p class="text-center">{{ "Send.Check_address" | translate }}</p>
      <ng-container *ngIf="confirmationTime > 0">
        <p class="text-center">{{ "Send.Confirmation_available_after" | translate }}</p>
        <div
          class="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-[#1F0F57] text-2xl font-bold text-text-secondary"
        >
          {{ confirmationTime }}
        </div>
      </ng-container>
      <div class="flex items-stretch gap-5">
        <button
          type="button"
          class="flex w-16 items-center justify-center rounded-xl bg-select-bg px-5"
          (click)="onBack()"
        >
          <img src="assets/icons/arrow-back-outline.svg" alt="back" />
        </button>
        <button class="btn flex-grow" [disabled]="confirmationTime > 0 || isPending" (click)="onConfirm()">
          {{ "Common.Confirm" | translate }}
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="3">
    <div class="space-y-5">
      <p class="text-xl">{{ "Send.In_progress" | translate }}</p>
      <div class="space-y-2 rounded-main border border-link-color px-5 py-2">
        <app-balance-card
          [isShort]="true"
          [balanceItem]="selectedWallet.balances[0]"
          class="[&>div]:border-none [&>div]:p-0"
        />
        <p class="flex items-center justify-between text-text-pale max-md:max-w-[calc(100vw-6rem)]">
          {{ "Transaction.From" | translate }}:
          <span class="truncate font-bold text-link-color">{{ selectedWallet.address }}</span>
        </p>
        <p class="flex items-center justify-between text-text-pale max-md:max-w-[calc(100vw-6rem)]">
          {{ "Transaction.To" | translate }}:
          <span class="truncate font-bold text-link-color">{{ sendForm.get("to")?.value }}</span>
        </p>
        <p class="flex items-center justify-between text-text-pale">
          {{ "Send.Network_commission" | translate }}:
          <span
            >{{ networkFee | mfeCustom: true : selectedWallet.network === Network.Bitcoin }}
            {{ nativeCurrencyName }}</span
          >
        </p>
        <p class="flex items-center justify-between text-text-pale">
          {{ "Send.Receive" | translate }}:
          <span class="text-2xl font-bold">
            {{ receiveAmount | mfeCustom: true : selectedWallet.network === Network.Bitcoin }}
            {{ selectedCurrencyName }}
          </span>
        </p>
        <p class="flex items-center justify-between text-text-pale">
          {{ "Send.Total" | translate }}:
          <span class="text-2xl font-bold">
            {{ sendAmount | mfeCustom: true : selectedWallet.network === Network.Bitcoin }}
            {{ selectedCurrencyName }}
          </span>
        </p>
      </div>
      <hr class="border-border-input-color" />
      <button class="btn w-full" (click)="closeModal()">{{ "Send.Go_to_wallet" | translate }}</button>
    </div>
  </ng-template>

  <p *ngIf="businessError" class="mt-5 text-danger">{{ businessError }}</p>

  <ng-container *ngIf="step !== 3">
    <hr class="mt-5 border-border-input-color" />
    <div class="disclaimer mt-5 text-sm font-normal">
      {{ "Send.Disclaimer_1" | translate: { network: networkName } }}
      <br />
      {{ "Send.Disclaimer_2" | translate: { amount: minimumAmount, currency: nativeCurrencyName } }}
      <br />
      {{ "Send.Disclaimer_3" | translate: { currency: nativeCurrencyName } }}
    </div>
  </ng-container>
</ng-container> -->
