import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { WalletComponent } from "src/app/pages/wallet/wallet.component";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { KycGuard } from "src/app/common/guards/kyc.guard";
import { AdminKycComponent } from "src/app/pages/admin/admin-kyc/admin-kyc.component";
import { AdminComponent } from "src/app/pages/admin/admin.component";
import { AdminLoginComponent } from "src/app/pages/admin/admin-login/admin-login.component";
import { KycRecordComponent } from "src/app/pages/admin/admin-kyc/kyc-record/kyc-record.component";
import { TransactionsComponent } from "src/app/pages/wallet/transactions/transactions.component";
import { AdminHomeComponent } from "src/app/pages/admin/admin-home/admin-home.component";
import { UserResolver } from "src/app/common/resolvers/user.resolver";
import { AdminTransactionsComponent } from "src/app/pages/admin/admin-transactions/admin-transactions.component";
import { AdminUsersComponent } from "src/app/pages/admin/admin-users/admin-users.component";
import { UserPageComponent } from "src/app/pages/admin/admin-users/user-page/user-page.component";
import { AdminAdminsComponent } from "src/app/pages/admin/admin-admins/admin-admins.component";
import { AdminGuard } from "src/app/common/guards/admin.guard";
import { UnauthorizedGuard } from "src/app/common/guards/unauthorized.guard";
import { AuthGuard } from "src/app/common/guards/auth.guard";
import { UserGuard } from "src/app/common/guards/user.guard";
import { UserTransactionsComponent } from "src/app/pages/admin/admin-users/user-transactions/user-transactions.component";
import { BlackListGuard } from "src/app/common/guards/black-list.guard";
import { LandingComponent } from "src/app/pages/landing/landing.component";
import { VerificationComponent } from "src/app/pages/verification/verification.component";
import { MyidComponent } from "src/app/pages/auth/myid/myid.component";
import { KycVerificationComponent } from "./pages/kyc-verification/kyc-verification.component";
import { MyidRedirectComponent } from "src/app/pages/myid-redirect/myid-redirect.component";
import { CardsComponent } from "./pages/cards/cards.component";
import { ReportsComponent } from "./pages/admin/admin-reports/reports.component";
import { BlackListComponent } from "./pages/black-list/black-list.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { CommissionsComponent } from "./pages/commissions/commissions.component";
import { CalculatorComponent } from "./pages/calculator/calculator.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { BuySellComponent } from "./pages/buy-sell/buy-sell.component";
import { DepositaryComponent } from "./pages/depositary/depositary.component";
import { SecurityComponent } from "./pages/admin/admin-security/security.component";
import { LogoutComponent } from "./pages/auth/logout/logout.component";
import { LogoutGuard } from "./common/guards/logout.guard";
import { TmaToMyIdRedirectComponent } from "./pages/tma-to-my-id-redirect/tma-to-my-id-redirect.component";
import { NewMyIdVerificationComponent } from "./pages/new-my-id-verification/new-my-id-verification.component";

const depositaryChildren: Routes = [
  { path: "", component: WalletComponent },
  {
    path: RouteConstants.cards,
    component: CardsComponent,
    runGuardsAndResolvers: "always",
    resolve: { user: UserResolver },
  },
  {
    path: RouteConstants.buySell,
    component: BuySellComponent,
    runGuardsAndResolvers: "always",
    resolve: { user: UserResolver },
  },
  {
    path: RouteConstants.transactions,
    component: TransactionsComponent,
  },
  {
    path: RouteConstants.commissions,
    component: CommissionsComponent,
  },
  {
    path: RouteConstants.calculator,
    component: CalculatorComponent,
  },
  {
    path: RouteConstants.faq,
    component: FaqComponent,
  },
];

const adminChildren: Routes = [
  { path: "", redirectTo: RouteConstants.adminHome, pathMatch: "full" },
  {
    path: RouteConstants.adminHome,
    component: AdminHomeComponent,
  },
  {
    path: RouteConstants.adminTransactions,
    component: AdminTransactionsComponent,
  },
  { path: RouteConstants.adminUsers, component: AdminUsersComponent },
  { path: `${RouteConstants.adminUsers}/:id`, component: UserPageComponent },
  { path: `${RouteConstants.adminUsers}/:id/transactions`, component: UserTransactionsComponent },
  { path: RouteConstants.adminAdmins, component: AdminAdminsComponent },
  { path: RouteConstants.adminKyc, component: AdminKycComponent },
  { path: `${RouteConstants.adminKyc}/:id`, component: KycRecordComponent },
  { path: RouteConstants.reports, component: ReportsComponent },
  { path: RouteConstants.adminSecurity, component: SecurityComponent },
];

const routes: Routes = [
  {
    path: RouteConstants.logout,
    component: LogoutComponent,
    canActivate: [LogoutGuard],
  },
  {
    path: RouteConstants.myid,
    component: MyidComponent,
    canActivate: [KycGuard, UserGuard],
  },
  // {
  //   path: RouteConstants.kyc,
  //   component: KycComponent,
  //   canActivate: [KycGuard, UserGuard],
  // },
  {
    path: "depositary",
    component: DepositaryComponent,
    children: depositaryChildren,
    canActivate: [AuthGuard, UserGuard, BlackListGuard],
  },
  {
    path: RouteConstants.admin,
    component: AdminComponent,
    children: adminChildren,
    canActivate: [AdminGuard],
  },
  {
    path: `${RouteConstants.admin}/${RouteConstants.login}`,
    component: AdminLoginComponent,
    pathMatch: "full",
    canActivate: [UnauthorizedGuard],
  },
  {
    path: `${RouteConstants.verification}`,
    component: VerificationComponent,
  },
  {
    path: `${RouteConstants.kycVerification}`,
    component: KycVerificationComponent,
  },
  {
    path: `${RouteConstants.blackList}`,
    component: BlackListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${RouteConstants.myIdRedirect}`,
    component: MyidRedirectComponent,
  },
  {
    path: `${RouteConstants.tmaToMyIdRedirect}`,
    component: TmaToMyIdRedirectComponent,
  },
  {
    path: RouteConstants.eventsListener,
    loadChildren: () =>
      import("./event-listener-module/event-listener.module").then(m => m.EventListenerModule),
  },
  {
    path: RouteConstants.newMyIdVerification,
    component: NewMyIdVerificationComponent,
  },
  {
    path: "",
    component: LandingComponent,
  },
  { path: "**", pathMatch: "full", component: PageNotFoundComponent },
];

// const subdomainRoutes: Routes = [
//   {
//     path: "",
//     component: BuySellComponent,
//     runGuardsAndResolvers: "always",
//     resolve: { user: UserResolver },
//     canActivate: [SubdomainGuard, AuthGuard, UserGuard, BlackListGuard],
//   },
//   {
//     path: RouteConstants.eventsListener,
//     loadChildren: () =>
//       import("./event-listener-module/event-listener.module").then(m => m.EventListenerModule),
//   },
//   { path: "**", pathMatch: "full", component: PageNotFoundComponent },
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
