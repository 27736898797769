<form
  [formGroup]="calculatorForm"
  class="flex flex-col gap-y-2 md:flex-row md:items-center md:gap-x-4 md:gap-y-0"
>
  <div class="space-y-2">
    <span class="font-medium">Что меняем</span>
    <div class="flex items-center rounded-2xl bg-white">
      <input
        type="number"
        formControlName="fromAmount"
        placeholder="0"
        appPositiveNumbers
        [maxLength]="fromCurrency === CryptoSymbol.Bitcoin ? 6 : 3"
        class="w-[70%] flex-1 rounded-l-2xl px-6 py-4 font-extrabold text-black focus-visible:outline-none md:w-auto md:max-w-none"
        (input)="handleOutputAmountChange($event)"
      />
      <app-currency-dropdown [items]="currencies" (onSelect)="onSelectInputCurrency($event)">
        <div class="flex items-center justify-end gap-x-2 p-4 font-bold text-slate-400 md:w-24">
          <span>{{ getCurrencyName(fromCurrency) }}</span>
          <img src="assets/icons/dropdown-arrow.svg" alt="" class="h-2.5 w-3" />
        </div>
      </app-currency-dropdown>
    </div>
  </div>

  <img src="assets/icons/transfer.svg" alt="swap" class="mt-8 hidden h-6 w-6 md:block" />

  <div class="space-y-2">
    <span class="font-medium">Что получаем</span>
    <div class="flex items-center overflow-hidden rounded-2xl bg-white">
      <input
        type="number"
        formControlName="toAmount"
        placeholder="0"
        appOnlyNumbers
        class="w-[70%] flex-1 rounded-l-2xl px-6 py-4 font-extrabold text-black focus-visible:outline-none disabled:bg-white md:w-auto md:max-w-none"
      />
      <span class="p-4 font-bold text-slate-400">UZS</span>
    </div>
  </div>
</form>

<div class="mt-2 flex items-center gap-x-4 text-sm text-white/60 max-md:flex-col max-md:items-start">
  <p>
    Результат:
    <span>{{ pureUzsToAmount | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS</span>
  </p>
  <p>
    Размер комиссии:
    <span>{{ uzsServiceFee | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS</span>
  </p>
</div>
