import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { ModalService } from "src/app/components/_modal";
import { ToastService } from "src/app/services/toast.service";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { VerificationStatus } from "src/app/common/enums/verification-status.enum";
import { CardDto } from "src/app/common/DTO/cards/card.dto";
import { CardService } from "src/app/services/card.service";
import { Constants } from "src/app/common/constants/constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { UserService } from "src/app/services/user.service";
import { EnvService } from "src/app/services/env.service";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { AuthService } from "src/app/services/auth.service";
import { FormGroup } from "@angular/forms";
import { ChangePasswordDto } from "src/app/common/DTO/auth/change-password.dto";
import { changePasswordValidator } from "./validators/change-password-form-group.validator";
import { TranslateService } from "@ngx-translate/core";
import { AuthErrorCode } from "src/app/common/enums/auth-error-code.enum";
import { EventBusService } from "src/app/services/event-bus.service";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { EventData } from "src/app/common/models/event-data";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  @ViewChild("photoInput") photoInput!: ElementRef;

  public profilePhotoSrc = Constants.ProfilePhotoBase;
  public verificationStatus: VerificationStatus = 0;
  public ModalConstants = ModalConstants;
  public cardList: CardDto[] = [];
  public internalError = false;

  public user: UserDto = new UserDto();
  public currentCard = 0;

  public changePasswordValidator: FormGroup;
  private _changePasswordDto: ChangePasswordDto;

  constructor(
    private readonly _modalService: ModalService,
    private readonly _toastService: ToastService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _cardService: CardService,
    private readonly _userService: UserService,
    private readonly _envService: EnvService,
    private readonly _authService: AuthService,
    private readonly _translateService: TranslateService,
    private readonly _eventBusService: EventBusService,
    private readonly _localStorageService: LocalStorageService
  ) {
    this.changePasswordValidator = changePasswordValidator;
    this._changePasswordDto = new ChangePasswordDto();
  }

  public async ngOnInit(): Promise<void> {
    const data = (await firstValueFrom(this._activatedRoute.data)) as { user: UserDto };
    if (data == null || data.user == null) {
      this._router.navigate([RouteConstants.login]);
      return;
    }
    this.user = data.user;
    this.updateVerifyStatus();
    await this.setCards();
    this.setProfilePhoto();
  }

  public openUploadPhotoDialog(): void {
    this.photoInput.nativeElement.click();
  }

  public async onPhotoInputChange(event: any): Promise<void> {
    const file = event.target.files[0] as File;
    if (ValidateConstants.ValidFileExtensions.indexOf(file.type) === -1) {
      const fileTypeMessage = this._translateService.instant("Profile.File_type_message");
      this._toastService.show(fileTypeMessage);
      return;
    }
    if (file.size > ValidateConstants.MaxFileSize) {
      const fileSizeMessage = this._translateService.instant("Profile.File_size_message");
      this._toastService.show(fileSizeMessage);
      return;
    }
    if (file.name.length > ValidateConstants.MaxFileNameLength) {
      const fileNameMessage = this._translateService.instant("Profile.File_name_message");
      this._toastService.show(fileNameMessage);
      return;
    }
    const res = await this._userService.uploadAvatar(file);
    if (res.withError) {
      this.internalError = true;
      return;
    }

    await this.updateUserDto();
    this.setProfilePhoto();
    this._eventBusService.dispatch(new EventData(EventTypeConstants.ChangeAvatar, this.user.avatar));
  }

  public setProfilePhoto(): void {
    if (this.user.avatar == null) {
      this.profilePhotoSrc = Constants.ProfilePhotoBase;
    } else {
      this.profilePhotoSrc = `${this._envService.serverUrl}${UrnConstants.StaticFolder}${this.user.avatar}`;
    }
  }

  public async updateUserDto(): Promise<void> {
    const res = await this._userService.getMe();
    if (res.withError) {
      this.internalError = true;
      return;
    }

    this.user = res.params!;
    this._localStorageService.saveUserData(this.user);
  }

  public async onChangeAvatar(avatarId: string) {
    await this._userService.updateAvatar(avatarId);
    this.closeChangeAvatarModal();

    await this.updateUserDto();
    this.setProfilePhoto();
    this._eventBusService.dispatch(new EventData(EventTypeConstants.ChangeAvatar, this.user.avatar));
  }

  public get isUserBlocked(): boolean {
    return this.user.blackList != null;
  }

  public canAddCard(): boolean {
    return this.cardList.length < Constants.MaxCardCount;
  }

  public async deleteCard() {
    const res = await this._cardService.deleteCard(this.currentCard);
    this.closeDeleteCardModal();

    if (res.withError) {
      this.internalError = true;
      return;
    }

    this.cardList = this.cardList.filter(x => x.id != this.currentCard);
    this.currentCard = 0;

    const successMessage = this._translateService.instant("Profile.Bank_card_deleted");
    this._toastService.show(successMessage);
  }

  public async addCard(cardId: number) {
    await this.setCards();
    this.closeBankCardModal();
    this.openCardConfirmationModal(cardId);
  }

  public async onVerifyCard() {
    await this.setCards();
    this.closeCardConfirmationModal();
    const successMessage = this._translateService.instant("Profile.Bank_card_added");
    this._toastService.show(successMessage);
  }

  public openBankCardModal() {
    this._modalService.open(ModalConstants.BankCardAdd);
  }

  public closeBankCardModal() {
    this._modalService.close(ModalConstants.BankCardAdd);
  }

  public openDeleteCardModal(id: number) {
    this.currentCard = id;
    this._modalService.open(ModalConstants.BankCardDelete);
  }

  public closeDeleteCardModal() {
    this._modalService.close(ModalConstants.BankCardDelete);
  }

  public openChangePasswordModal() {
    this._modalService.open(ModalConstants.ChangePassword);
  }

  public closeChangePasswordModal() {
    this._modalService.close(ModalConstants.ChangePassword);
  }

  public openCardConfirmationModal(id: number) {
    this.currentCard = id;
    this._modalService.open(ModalConstants.BankCardConfirmation);
  }

  public closeCardConfirmationModal() {
    this._modalService.close(ModalConstants.BankCardConfirmation);
  }

  public openChangeAvatarModal() {
    this._modalService.open(ModalConstants.ChangeAvatar);
  }

  public closeChangeAvatarModal() {
    this._modalService.close(ModalConstants.ChangeAvatar);
  }

  public get _oldPswError() {
    return this.getChangeErrorMessage("oldPsw", "incorrectPsw");
  }

  public get _newPswError() {
    return this.getChangeErrorMessage("newPsw", "pattern");
  }

  public get _pswRepeatError() {
    return this.getChangeErrorMessage("pswRepeat", "notMatch");
  }

  public async handleChangePassword() {
    this._changePasswordDto.oldPsw = this.changePasswordValidator.value.oldPsw;
    this._changePasswordDto.newPsw = this.changePasswordValidator.value.newPsw;
    this._changePasswordDto.userId = this.user.id;

    const res = await this._authService.changePassword(this._changePasswordDto);

    if (res && res.withError) {
      if (res.errorCode === AuthErrorCode.IncorrectCode) {
        this.changePasswordValidator.get("oldPsw")?.setErrors({ incorrectPsw: true });
        return;
      }
      this.internalError = true;
      return;
    }

    this.closeChangePasswordModal();
    const successMessage = this._translateService.instant("Profile.Password_changed");
    this._toastService.show(successMessage);
    this.changePasswordValidator.reset();
  }

  private async setCards(): Promise<void> {
    const res = await this._cardService.getCards();
    if (res.withError) {
      // this.internalError = true;
      return;
    }

    this.cardList = res.params!;
  }

  private updateVerifyStatus(): void {
    if (this.user.kyc == null) {
      this.verificationStatus = VerificationStatus.Required;
      return;
    }

    this.verificationStatus = VerificationStatus.Verified;
  }

  private getChangeErrorMessage(controlName: string, errorKey: string): string | null {
    const control = this.changePasswordValidator.get(controlName);

    if (!control?.touched) {
      return null;
    }

    if (control.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (control.hasError("minlength") || control.hasError("maxlength")) {
      return this._translateService.instant("Login.Password_length_error");
    }

    if (control.hasError(errorKey)) {
      switch (errorKey) {
        case "incorrectPsw":
          return this._translateService.instant("Login.Wrong_password");
        case "pattern":
          return this._translateService.instant("Login.Password_validation_error");
        case "notMatch":
          return this._translateService.instant("Login.Password_not_match");
      }
    }

    return null;
  }
}
