<div class="md:box py-12 px-8" [ngSwitch]="variant">
  <div class="max-w-[21.25rem] space-y-4 text-center mx-auto">
    <!-- Pending -->
    <ng-template ngSwitchCase="pending">
      <img class="w-[3.75rem] mx-auto" src="assets/icons/pending.svg" alt="Pending" />
      <p class="text-sm">{{ "Operation.Pending" | translate }}</p>
    </ng-template>
    <!-- Error -->
    <ng-template ngSwitchCase="error">
      <img class="w-[3.75rem] mx-auto" src="assets/icons/chat-error.svg" alt="Error" />
      <p class="text-sm">{{ "Operation.Error" | translate }}</p>
      <button class="btn w-full" (click)="onRetryClick()">
        {{ "Operation.Try_again" | translate }}
      </button>
    </ng-template>
    <!-- Success -->
    <ng-template ngSwitchCase="success">
      <img class="w-[3.75rem] mx-auto" src="assets/icons/success.svg" alt="Success" />
      <p class="text-sm">{{ successText }}</p>
    </ng-template>
    <!-- Awaiting -->
    <ng-template ngSwitchCase="awaiting">
      <img class="w-[3.75rem] mx-auto" src="assets/icons/pending.svg" alt="Awaiting" />
      <p class="text-sm">{{ "Operation.Awaiting_1" | translate }}</p>
      <p class="text-sm">{{ "Operation.Awaiting_2" | translate }}</p>
      <p class="text-sm">{{ "Operation.Awaiting_3" | translate }}</p>
    </ng-template>

    <a routerLink="/wallet" class="btn w-full block" [class.btn_outline]="variant === 'error'">
      {{ "Operation.Back_to_home" | translate }}
    </a>
  </div>
</div>
