import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ValidateConstants } from "src/app/common/constants/validate.constants";

export const passwordMatchingValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get("newPsw");
  const confirmPassword = control.get("pswRepeat");

  if (!password || !confirmPassword) {
    return null;
  }

  if (password.errors && !confirmPassword.errors?.["notMatch"]) {
    return null;
  }

  if (password.value !== confirmPassword.value) {
    confirmPassword.setErrors({ notMatch: true });
  } else {
    confirmPassword.setErrors(null);
  }

  return null;
};

export const changePasswordValidator = new FormGroup(
  {
    oldPsw: new FormControl(null, [
      Validators.required,
      Validators.minLength(ValidateConstants.MinPswLength),
      Validators.maxLength(ValidateConstants.MaxPswLength),
    ]),
    newPsw: new FormControl(null, [
      Validators.required,
      Validators.minLength(ValidateConstants.MinPswLength),
      Validators.maxLength(ValidateConstants.MaxPswLength),
      Validators.pattern(ValidateConstants.PswPattern),
    ]),
    pswRepeat: new FormControl(null, [Validators.required]),
  },
  { validators: passwordMatchingValidator }
);
