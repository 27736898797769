import { Network } from "src/app/common/enums/network.enum";

export function getWalletLink(network: Network, address: string) {
  switch (network) {
    case Network.Tron:
      return `https://tronscan.org/#/address/${address}`;
    case Network.Polygon:
      return `https://polygonscan.com/address/${address}`;
    case Network.Ton:
      return `https://tonscan.org/address/${address}`;
    case Network.Bitcoin:
      return `https://btcscan.org/address/${address}`;
    default:
      return "";
  }
}
