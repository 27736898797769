import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "customDate",
})
export class CustomDatePipe implements PipeTransform {
  transform(value: string | Date, variant: "short" | "full" = "short"): string {
    const dateObj = new Date(value);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = dateObj.getFullYear();
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    switch (variant) {
      case "full":
        return `${day}.${month}.${year} ${hours}:${minutes}`;
      case "short":
        return `${day}.${month}.${year}`;
    }
  }
}
