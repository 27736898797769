import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "./env.service";
import { UrnConstants } from "../common/constants/urn.constants";
import { AddTerrorDto } from "../common/DTO/terrorists/add-terror.dto";
import { HttpResultDto } from "../common/DTO/http-result.dto";
import { TerroristErrorCode } from "../common/enums/terrorist-error-code.enum";
import { Constants } from "../common/constants/constants";
import { firstValueFrom } from "rxjs";
import { ErrorParserUtil } from "../common/utils/error-parser.util";
import { ScannedTerrorDto } from "../common/DTO/terrorists/scanned-terror.dto";
import { PagedListDto } from "../common/DTO/paged-list.dto";
import { ApiResponseDto } from "../common/DTO/api-response.dto";

@Injectable({
  providedIn: "root"
})
export class TerroristService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _envService: EnvService
  ) {}

  public async addTerrorist(dto: AddTerrorDto): Promise<HttpResultDto<TerroristErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.Terrorist}`;
    const result = new HttpResultDto<TerroristErrorCode, null>(false);

    try{
      await firstValueFrom(this._http.post(uri, dto));
    } catch(e){
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async uploadCsvFile(formData: FormData): Promise<HttpResultDto<TerroristErrorCode, number>>{
    const uri = `${this._envService.serverUrl}${UrnConstants.UploadTerroristsCsv}`;
    const result = new HttpResultDto<TerroristErrorCode, number>(false);
    
    try {
      const response = await firstValueFrom(this._http.post(uri, formData)) as ApiResponseDto<number>
      result.params = response.data;
    } catch(e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async scanTerrorsWithKyc(
    size: number,
    page: number
  ): Promise<HttpResultDto<TerroristErrorCode, PagedListDto<ScannedTerrorDto> | null>> {
    const params = `?size=${size}&index=${page}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.ScanTerrorists}${params}`;
    const result = new HttpResultDto<TerroristErrorCode, PagedListDto<ScannedTerrorDto> | null>(false);
    
    try {
      const response = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<PagedListDto<ScannedTerrorDto> | null>;
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): TerroristErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return TerroristErrorCode.InternalError;
      case Constants.Unauthorized:
        return TerroristErrorCode.Unauthorized;
      case 'Terrorist already exist':
        return TerroristErrorCode.AlreadyExist;
      default:
        return TerroristErrorCode.InternalError;
    }
  }
}