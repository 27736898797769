<div
  class="box flex min-h-[11.125rem] flex-col border-gray-100 bg-blue-50 p-6 shadow-lg dark:border-slate-400 dark:bg-slate-700"
  [ngClass]="{
    'gradient-bg-1': index === 0,
    'gradient-bg-2': index === 1,
    'gradient-bg-3': index === 2,
    'gradient-bg-4': index === 3
  }"
>
  <div *ngIf="showDetails; else basicView">
    <p class="text-base font-medium">{{ card.maskedPan }}</p>
  </div>

  <ng-template #basicView>
    <div class="flex items-center justify-between">
      <div>
        <p class="text-base font-medium">
          {{ card.balance | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS
        </p>
        <p class="mt-1 flex items-center gap-x-2 text-base text-slate-400 dark:text-slate-200">
          <span>{{ card.maskedPan.slice(-5) }}</span>
          <span class="h-1 w-1 shrink-0 rounded-full bg-gray-100"></span>
          <span>{{ getBankType() }}</span>
        </p>
      </div>
      <div class="flex w-16 items-center justify-center rounded-lg bg-red-50 p-3">
        <img [src]="'assets/icons/banks/' + getBankType() + '.png'" alt="" class="h-5 w-5" />
      </div>
    </div>
  </ng-template>

  <div *ngIf="showActions" class="mt-auto flex items-center justify-between">
    <button class="btn-flat" (click)="onClickDelete()">
      <app-svg-icon name="trash-bin" class="mr-1 h-6 w-6" />
      Удалить
    </button>
    <button *ngIf="!showDetails" class="btn-flat" (click)="toggleDetails()">
      <app-svg-icon name="card" class="mr-1 h-6 w-6" />
      Реквизиты
    </button>
    <button *ngIf="showDetails" class="btn-flat" (click)="toggleDetails()">
      <app-svg-icon name="close" class="mr-1 h-6 w-6" />
      Скрыть
    </button>
  </div>
</div>
