import { Component, inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { reportDatesValidator } from "src/app/common/validators/report-dates.validator";
import { ModalService } from "src/app/components/_modal";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ReportsService } from "src/app/services/reports.service";

@Component({
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.css"],
})
export class ReportsComponent implements OnInit {
  isUserObserverAdmin: boolean = false;

  ModalConstants = ModalConstants;

  maxStartDate = new Date().toISOString().split("T")[0];
  maxEndDate = new Date().toISOString().split("T")[0];
  minEndDate!: string;

  public reportForm = new FormGroup(
    {
      startDate: new FormControl("", [Validators.required]),
      endDate: new FormControl("", [Validators.required]),
    },
    {
      validators: reportDatesValidator("startDate", "endDate"),
    }
  );

  constructor(
    private readonly _modalService: ModalService,
    private readonly _reportsService: ReportsService,
    public readonly _translateService: TranslateService,
    private readonly _localStorage: LocalStorageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isUserObserverAdmin = await this._localStorage.isUserObserverAdmin();
  }

  public handleOpenModal(modalName: string) {
    this._modalService.open(modalName);
  }

  public handleSubmit(modalName: string) {
    if (this.reportForm.valid) {
      switch (modalName) {
        case ModalConstants.AdminGetReportForPeriod:
          this._reportsService.getAccountingReport(
            this.reportForm.value.startDate as string,
            this.reportForm.value.endDate as string
          );
          break;
        case ModalConstants.AdminGetRegisteredUsersReportForPeriod:
          this._reportsService.getRegisteredUsersReportForPeriodReport(
            this.reportForm.value.startDate as string,
            this.reportForm.value.endDate as string
          );
          break;
        default:
          break;
      }
      this._modalService.close(modalName);
    }
  }

  public handleClose() {
    this.reportForm.reset();
    this.minEndDate = "";
    this.maxStartDate = new Date().toISOString().split("T")[0];
  }

  public onStartDateChanged() {
    this.minEndDate = this.reportForm.value.startDate as string;
  }

  public onEndDateChenged() {
    this.maxStartDate = this.reportForm.value.endDate as string;
  }

  public handleDownloadButtonClick() {
    this._reportsService.getUsersPersonalDataReport();
  }
}
