<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)"
>
  <div
    class="bg-select-bg text-base rounded-main border-min border-stroke-color py-[2.125rem] px-[1.875rem] text-text-secondary flex justify-between items-center w-[25rem] gap-4 max-md:p-5 max-md:w-full"
  >
    {{ toast.body }}
    <button (click)="toastService.remove(toast)" class="w-6">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.6 20L4 18.4L10.4 12L4 5.6L5.6 4L12 10.4L18.4 4L20 5.6L13.6 12L20 18.4L18.4 20L12 13.6L5.6 20Z"
          class="fill-text-secondary"
        />
      </svg>
    </button>
  </div>
</ngb-toast>
