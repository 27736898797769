import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { Network } from "src/app/common/enums/network.enum";
import { EventData } from "src/app/common/models/event-data";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { getWalletLink } from "src/app/common/utils/wallet-link-helper.util";
import { EventBusService } from "src/app/services/event-bus.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { UserService } from "src/app/services/user.service";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  selector: "app-kyc-verification",
  templateUrl: "./kyc-verification.component.html",
  styleUrls: ["./kyc-verification.component.css"],
})
export class KycVerificationComponent implements OnInit {
  public isFail = true;
  public wallets: WalletDto[] = [];
  public walletRoute = RouteConstants.wallet;

  constructor(
    private route: ActivatedRoute,
    private readonly _userService: UserService,
    private readonly _localStorage: LocalStorageService,
    private readonly _eventBusService: EventBusService,
    private readonly _walletService: WalletService
  ) {}

  public async ngOnInit(): Promise<void> {
    const isVerificationFailed = this.route.snapshot.queryParams["isFail"];

    if (isVerificationFailed === "false") {
      this.isFail = false;
      const wallets = await this._walletService.getMy();
      this.wallets = wallets?.params || [];
      await this.getUserData();
    }
  }

  public getWalletLink(network: Network, wallet: string) {
    return getWalletLink(network, wallet);
  }

  public getNetworkName(network: Network) {
    return getNetworkName(network);
  }

  private async getUserData() {
    const response = await this._userService.getMe();

    if (response.withError) {
      return;
    }

    await this._localStorage.saveUserData(response.params!);

    this._eventBusService.dispatch(
      new EventData(EventTypeConstants.UpdateVerificationStatus, response.params?.kyc)
    );
  }
}
