import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CardDto } from "src/app/common/DTO/cards/card.dto";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { Constants } from "src/app/common/constants/constants";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { VerificationStatus } from "src/app/common/enums/verification-status.enum";
import { UserResolver } from "src/app/common/resolvers/user.resolver";
import { AddBankCardModalComponent } from "src/app/components/add-bank-card-modal/add-bank-card-modal.component";
import { CardService } from "src/app/services/card.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  templateUrl: "./cards.component.html",
  styleUrls: ["./cards.component.css"],
})
export class CardsComponent implements OnInit {
  public ModalConstants = ModalConstants;
  public cardList: CardDto[] = [];
  public user: UserDto = new UserDto();
  public verificationStatus = VerificationStatus.Required;
  public VerificationStatus = VerificationStatus;
  public showConfirmationDeleteForId: number | null = null;

  constructor(
    private readonly _cardService: CardService,
    private readonly _modalService: NgbModal,
    private readonly _toastService: ToastService,
    private readonly _translateService: TranslateService,
    private readonly _userResolver: UserResolver
  ) {}

  async ngOnInit() {
    const user = this._userResolver.user;
    if (user) {
      this.user = user;
      if (this.user.kyc == null) {
        this.verificationStatus = VerificationStatus.Required;
      } else {
        this.verificationStatus = VerificationStatus.Verified;
      }
    }

    await this.setCards();
  }

  public get isUserBlocked(): boolean {
    return this.user.blackList != null;
  }

  public canAddCard(): boolean {
    return this.cardList.length < Constants.MaxCardCount;
  }

  public handleFirstDeleteClick(id: number) {
    this.showConfirmationDeleteForId = id;
  }

  public async deleteCard(cardId: number) {
    const res = await this._cardService.deleteCard(cardId);

    if (res.withError) {
      this._toastService.show(this._translateService.instant("Common.Unknown_error"));
      return;
    }

    this.showConfirmationDeleteForId = null;
    this.cardList = this.cardList.filter(x => x.id != cardId);
    const successMessage = this._translateService.instant("Profile.Bank_card_deleted");
    this._toastService.show(successMessage);
  }

  public openAddBankCardModal() {
    const modalRef = this._modalService.open(AddBankCardModalComponent);
    modalRef.componentInstance.onAddCard.subscribe(() => {
      this.setCards();
    });
    modalRef.componentInstance.onCardAdded.subscribe(() => {
      this.setCards();
      modalRef.close();
    });
  }

  private async setCards(): Promise<void> {
    const res = await this._cardService.getCards();
    if (res.withError) {
      return;
    }
    this.cardList = res.params!;
  }
}
