<section class="admin-body">
  <app-back-button link="/admin/home"></app-back-button>

  <h3 class="title">{{ "Admin.Trans.Active" | translate }}</h3>

  <div class="flex flex-col space-y-2">
    <h4 class="text-base font-medium dark:text-slate-200">{{ "Admin.Trans.Manual" | translate }}</h4>
    <table class="adminTable">
      <thead>
        <tr>
          <th scope="col">{{ "Admin.Trans.Sum" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Date" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Sender_address" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Receiver_number" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Receiver_address" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Options" | translate }}</th>
        </tr>
      </thead>
      <tbody *ngIf="sendTxs.length > 0; else noSendTxs">
        <tr *ngFor="let item of sendTxs">
          <td>{{ item.amount | mfeCustom }} USDT</td>
          <td>{{ item.createdAt | customDate: "full" }}</td>
          <td>
            <div>
              <span>{{ item.from }}</span>
              <app-copy-btn [value]="item.from"></app-copy-btn>
            </div>
          </td>
          <!-- TODO Add phone number -->
          <td>-</td>
          <td>
            <div>
              <span>{{ item.to }}</span>
              <app-copy-btn [value]="item.to"></app-copy-btn>
            </div>
          </td>
          <td>
            <div>
              <button
                class="h-6 w-6 disabled:opacity-50"
                [title]="'Common.Approve' | translate"
                (click)="handleVerdictSendTx(item.id, true)"
                [disabled]="isVerdictPending"
              >
                <img src="assets/icons/accept.svg" alt="Accept" />
              </button>
              <button
                class="h-6 w-6 disabled:opacity-50"
                [title]="'Common.Cancel' | translate"
                (click)="handleVerdictSendTx(item.id, false)"
                [disabled]="isVerdictPending"
              >
                <img src="assets/icons/decline.svg" alt="Decline" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
      <ng-template #noSendTxs>
        <tr>
          <td colspan="6" class="text-center">{{ "Transaction.No_transactions" | translate }}</td>
        </tr>
      </ng-template>
    </table>

    <ngb-pagination
      [collectionSize]="sendTotalCount"
      [(page)]="sendPage"
      [pageSize]="pageSize"
      [maxSize]="3"
      [boundaryLinks]="true"
      (pageChange)="requestSendTxs()"
    >
    </ngb-pagination>
  </div>

  <div class="flex flex-col space-y-2">
    <h4 class="text-base font-medium dark:text-slate-200">{{ "Admin.Trans.Buy_sell" | translate }}</h4>
    <table class="adminTable">
      <thead>
        <tr>
          <th scope="col">{{ "Admin.Trans.Sum" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Date" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Type" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Wallet" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Phone_number" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Status" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Card_number" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Options" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Revert" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Compliance" | translate }}</th>
        </tr>
      </thead>
      <tbody *ngIf="cryptoTxs.length > 0; else noCryptoTxs">
        <tr *ngFor="let item of cryptoTxs">
          <td>
            {{
              getCryptoCurrencyValue(item.toAmount, item.toCurrency)
                | mfeCustom: { currency: item.toCurrency }
            }}
            {{ getCryptoCurrencyName(item.toCurrency) }}
          </td>
          <td>{{ item.createdAt | customDate: "full" }}</td>
          <td>
            <span>{{ getCryptoTxType(item.type) }}</span>
          </td>
          <td>
            <a [href]="getAddressOnExplorer(item)" target="_blank" class="link"
              >{{ item.walletPublicKey.slice(0, 5) }}...</a
            >
          </td>
          <td>
            <a [routerLink]="'/admin/users/' + item.userId" class="link" target="_blank">{{
              item.userPhoneNumber | phoneDisplayer
            }}</a>
          </td>
          <td *ngIf="item.cryptoTxHash">
            <a [href]="getTransactionOnExplorer(item)" target="_blank" class="link">{{
              getCryptoTxStatus(item.internalStatus)
            }}</a>
          </td>
          <td *ngIf="!item.cryptoTxHash">{{ getCryptoTxStatus(item.internalStatus) }}</td>
          <td>
            <span>{{ item.maskedCardNumber }}</span>
          </td>
          <td>
            <div *ngIf="isCryptoVerdictEnabled(item.internalStatus)">
              <button
                class="h-6 w-6"
                [title]="'Common.Approve' | translate"
                (click)="handleVerdictCryptoTx(item.id, true)"
                [disabled]="isCryptoVerdictPending || isUserObserverAdmin"
              >
                <img src="assets/icons/accept.svg" alt="Accept" />
              </button>
              <button
                class="h-6 w-6"
                [title]="'Common.Cancel' | translate"
                (click)="handleVerdictCryptoTx(item.id, false)"
                [disabled]="isCryptoVerdictPending || isUserObserverAdmin"
              >
                <img src="assets/icons/decline.svg" alt="Decline" />
              </button>
            </div>
          </td>
          <td>
            <button
              *ngIf="item.canRevertWithdraw"
              class="h-6 w-6"
              [title]="'Admin.Trans.Revert' | translate"
              (click)="handleReverWithdrawTx(item.id)"
              [disabled]="isUserObserverAdmin"
            >
              <img src="assets/icons/revert_deposit.svg" alt="Revert" />
            </button>
          </td>
          <td>
            <a
              *ngIf="item.complianceRiskScore && item.complianceReportLink"
              [href]="item.complianceReportLink"
              target="_blank"
              class="link"
            >
              {{ item.complianceRiskScore * 100 }}%
            </a>
            <span *ngIf="!item.complianceRiskScore">0%</span>
          </td>
        </tr>
      </tbody>
      <ng-template #noCryptoTxs>
        <tr>
          <td colspan="10" class="text-center">{{ "Transaction.No_transactions" | translate }}</td>
        </tr>
      </ng-template>
    </table>

    <ngb-pagination
      [collectionSize]="cryptoTotalCount"
      [(page)]="cryptoPage"
      [pageSize]="pageSize"
      [maxSize]="3"
      [boundaryLinks]="true"
      (pageChange)="requestCryptoTxs()"
    >
    </ngb-pagination>
  </div>
</section>
