import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";

import { ModalService } from "./modal.service";

@Component({
  selector: "jw-modal",
  templateUrl: "modal.component.html",
  styleUrls: ["modal.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {
  @HostListener("document:keydown.escape", ["$event"])
  onEsc() {
    this.close();
  }

  @Input() id: string = "";
  @Input() fullScreenOnMobile: boolean = false;

  /**
   * Set width of modal body. If not provided, value 'w-[32.75rem]' is used.
   */
  @Input() bodyWidth: string = "w-[32.75rem]";

  private element: any;

  @Output() onClose = new EventEmitter();

  constructor(
    private modalService: ModalService,
    private el: ElementRef
  ) {
    this.element = this.el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.id) {
      console.error("modal must have an id");
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("mousedown", (el: any) => {
      if (el.target.className === "jw-modal") {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = "block";
    document.body.classList.add("jw-modal-open");
  }

  // close modal
  close(): void {
    this.element.style.display = "none";
    document.body.classList.remove("jw-modal-open");
    this.onClose.emit();
  }
}
