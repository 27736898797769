<aside class="pb-6 pr-8 pt-8 max-md:hidden">
  <div class="grid grid-cols-1 gap-y-5">
    <ng-container *ngFor="let item of menuItems">
      <app-menu-link-button [item]="item" [isFullMenu]="isFullMenu" />
    </ng-container>

    <button
      class="flex items-center rounded-tw border border-border-input-color stroke-text-primary px-7 py-5 hover:border-danger-hover hover:bg-danger-hover hover:stroke-text-secondary hover:text-text-secondary"
      (click)="onLogout()"
    >
      <app-svg-icon name="menu/exit-outline" class="h-8 w-8 shrink-0" />
      <span
        [@buttonExpand]="isFullMenu"
        class="ml-5 overflow-hidden whitespace-nowrap text-sm font-semibold uppercase"
      >
        {{ "Header.Logout" | translate }}
      </span>
    </button>

    <button
      class="flex w-[5.625rem] items-center rounded-tw border border-border-input-color px-7 py-5"
      (click)="toggleMenu()"
    >
      <app-svg-icon
        name="menu/chevron-back-outline"
        class="h-8 w-8 transition-transform"
        [ngClass]="{ '-rotate-180': !isFullMenu }"
      />
    </button>
  </div>
</aside>
