import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";

@Component({
  selector: "app-select-avatar-modal",
  templateUrl: "./select-avatar-modal.component.html",
  styleUrls: ["./select-avatar-modal.component.css"],
})
export class SelectAvatarModalComponent implements OnInit {
  public ModalConstants = ModalConstants;
  public avatars: string[] = [
    "/assets/images/avatars/1.jpg",
    "/assets/images/avatars/2.jpg",
    "/assets/images/avatars/3.jpg",
    "/assets/images/avatars/4.jpg",
    "/assets/images/avatars/5.jpg",
    "/assets/images/avatars/6.jpg",
  ];
  public selectedAvatar: string = "";

  @Input() public currentAvatar: string = "";
  @Output() public onChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  public isCurrentAvatar(avatarUrl: string): boolean {
    return this.getAvatarIdFromUrl(this.currentAvatar) === this.getAvatarIdFromUrl(avatarUrl);
  }

  public onSelect(avatar: string): void {
    this.selectedAvatar = avatar;
  }

  public onConfirm() {
    const avatarId = this.getAvatarIdFromUrl(this.selectedAvatar);
    this.onChange.emit(avatarId);
    this.selectedAvatar = "";
  }

  private getAvatarIdFromUrl(avatarUrl: string): string {
    return avatarUrl.split("/").pop()?.split(".")[0] ?? "";
  }
}
