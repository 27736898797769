import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AssetsSDK, createApi } from "@ton-community/assets-sdk";
import { Address, toNano } from "@ton/ton";
import { CHAIN } from "@tonconnect/ui";
import { generate } from "lean-qr";
import { toSvgDataURL } from "lean-qr/extras/svg";
import { NotMasterAddress } from "src/app/common/constants/not.constant";
import { TonUsdtMasterAddress } from "src/app/common/constants/ton-usdt.constant";
import { WalletBalanceDto } from "src/app/common/DTO/wallets/wallet-balance.dto";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { Network } from "src/app/common/enums/network.enum";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { TelegramMiniAppHelper } from "src/app/common/utils/telegram-mini-app-helper.util";
import { getWalletLink } from "src/app/common/utils/wallet-link-helper.util";
import { EnvService } from "src/app/services/env.service";
import { TonConnectSender } from "src/app/services/ton-connect-sender.service";
import { TonConnectService } from "src/app/services/ton-connect.service";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  selector: "app-receive-modal",
  templateUrl: "./receive-modal.component.html",
  styleUrls: ["./receive-modal.component.css"],
})
export class ReceiveModalComponent implements OnInit, OnDestroy {
  @Input() wallet: WalletDto = new WalletDto();
  @Input() walletBalance: WalletBalanceDto | null = null;

  internalError = false;
  wallets: WalletDto[] = [];
  qrBase64 = "";
  selectedWallet = this.wallet;
  selectedWalletBalance = this.walletBalance;
  Network = Network;
  isTelegramMiniApp = TelegramMiniAppHelper.isMiniApp();
  isTonWalletConnected = false;
  networkFee = 0;

  receiveForm: FormGroup;

  private cleanUpFunctionsList: (VoidFunction | undefined)[] = [];

  constructor(
    private readonly _activeModal: NgbActiveModal,
    private readonly _walletService: WalletService,
    private readonly _tonConnectService: TonConnectService,
    private readonly _translateService: TranslateService,
    private readonly _envService: EnvService
  ) {
    this.receiveForm = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(Number.MIN_VALUE)]),
    });
  }

  async ngOnInit() {
    if (this.wallet && this.walletBalance) {
      this.selectedWallet = this.wallet;
      this.selectedWalletBalance = this.walletBalance;
    }

    const wallets = await this._walletService.getMy();
    this.wallets = wallets?.params || [];
    if (!this.selectedWallet.address || this.selectedWalletBalance === null) {
      this.selectedWallet = this.wallets[0];
      this.selectedWalletBalance = this.selectedWallet.balances[0];
    }
    this.generateQr(this.selectedWallet.address);

    this.isTonWalletConnected = this._tonConnectService?.tonConnectUi?.connected;
    const tonStatusUnsubscribe = this._tonConnectService?.tonConnectUi?.onStatusChange?.(wallet => {
      if (wallet?.account?.address) {
        this.isTonWalletConnected = true;
      }
    });
    this.cleanUpFunctionsList.push(tonStatusUnsubscribe);
  }

  ngOnDestroy() {
    this.cleanUpFunctionsList.map(cleanUp => cleanUp?.());
  }

  closeModal(): void {
    this._activeModal.close();
  }

  get walletLink() {
    return getWalletLink(this.selectedWallet.network, this.selectedWallet.address);
  }

  get networkName(): string {
    return getNetworkName(this.selectedWallet.network);
  }

  get isTonNetwork() {
    return this.selectedWallet.network === Network.Ton;
  }

  get currencyName(): string {
    return getCurrencyName(this.selectedWallet.balances[0]?.currency);
  }

  get networks() {
    return this.wallets.map(w => w.network);
  }

  get currencies() {
    return this.selectedWallet.balances.map(b => b.currency);
  }

  get selectedCurrencyName() {
    if (this.selectedWalletBalance === null) {
      return "Unknown";
    }
    return this.getCurrencyName(this.selectedWalletBalance.currency);
  }

  get amountError(): string | null {
    const amount = this.receiveForm.controls["amount"];
    const balanceCurrency = this.selectedWalletBalance?.currency;

    if (amount.value === null) {
      return null;
    }

    if (amount?.hasError("required")) {
      return this._translateService.instant("Send.Amount_required_error");
    }

    if (amount?.hasError("min")) {
      return this._translateService.instant("Send.Amount_min_error");
    }

    if (amount?.hasError("max")) {
      return this._translateService.instant("Send.Amount_insuf_error");
    }

    return null;
  }

  getNetworkName = getNetworkName;
  getCurrencyName = getCurrencyName;

  selectNetwork(network: Network): void {
    this.selectedWallet = this.wallets.find(x => x.network === network) || new WalletDto();
    const balance = this.selectedWallet.balances[0];
    this.selectedWalletBalance = balance;
    this.generateQr(this.selectedWallet.address);
  }

  private generateQr(address: string) {
    const code = generate(address);
    this.qrBase64 = toSvgDataURL(code);
  }

  selectWalletBalance(walletBalance: WalletBalanceDto) {
    this.selectedWalletBalance = walletBalance;
  }

  selectWalletBalanceByCurrency(currency: CryptoSymbol) {
    const selectedWalletBalance = this.selectedWallet.balances.find(b => b.currency === currency);
    this.selectedWalletBalance = selectedWalletBalance ?? null;
  }

  copyWalletAddress(value: string) {
    const tempEl = document.createElement("textarea");
    document.body.appendChild(tempEl);
    tempEl.value = value;
    tempEl.select();
    document.execCommand("copy", false);
    tempEl.remove();
  }

  async receiveFromTonWallet() {
    if (!this.receiveForm.valid) {
      console.log("amount is invalid");
      return;
    }
    try {
      if (!this._tonConnectService?.tonConnectUi?.connected) {
        console.log("wallet is not connected");
        await this._tonConnectService.openTelegramWallet();
        return;
      }
      const amount = this.receiveForm.controls["amount"].value;
      switch (this.selectedWalletBalance?.currency) {
        case CryptoSymbol.Ton: {
          await this.receiveTonCoins(amount);
          break;
        }
        case CryptoSymbol.TonUsdt: {
          await this.receiveTonJettons(amount, "usdt");
          break;
        }
        case CryptoSymbol.Not: {
          await this.receiveTonJettons(amount, "not");
          break;
        }
        default: {
          throw new Error("Cannot receive unsupported currency");
        }
      }
    } catch (error) {
      console.error("ton transaction error:", error);
    }
  }

  private async receiveTonCoins(amount: string | number) {
    await this._tonConnectService?.tonConnectUi?.sendTransaction?.({
      network: this._envService.isProduction ? CHAIN.MAINNET : CHAIN.TESTNET,
      validUntil: Math.floor(Date.now() / 1000) + 180, // 180 sec
      messages: [
        {
          address: this.selectedWallet.address,
          amount: toNano(amount).toString(),
        },
      ],
    });
  }

  private async receiveTonJettons(amount: string | number, jettonType: "usdt" | "not") {
    const tonAssetsSdkApi = await createApi(this._envService.isProduction ? "mainnet" : "testnet");
    const tonAssetsSender = new TonConnectSender(this._tonConnectService.tonConnectUi);
    const tonAssetsSdk = AssetsSDK.create({ api: tonAssetsSdkApi, sender: tonAssetsSender });

    const jettonAddress = jettonType === "usdt" ? TonUsdtMasterAddress : NotMasterAddress;

    const jettonWallet = tonAssetsSdk.openJettonWallet(Address.parse(jettonAddress));
    const receiver = Address.parse(this.selectedWallet.address);

    await jettonWallet.send(tonAssetsSender, receiver, toNano(amount));
  }
}
