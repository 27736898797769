export class VerdictKycDto {
  public kycId: number;
  public isApprove: boolean;
  public cancelReason: string | null;

  constructor(kycId: number, isApprove: boolean, cancelReason: string | null) {
    this.kycId = kycId;
    this.isApprove = isApprove;
    this.cancelReason = cancelReason;
  }
}
