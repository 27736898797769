<div class="switch">
  <label class="switch__box">
    <input
      type="checkbox"
      [ngModel]="checked"
      (ngModelChange)="onModelChange($event)"
      [disabled]="disabled"
    />
    <span class="toggler"></span>
  </label>

  <span *ngIf="caption" class="switch__caption" [innerHTML]="caption"></span>
</div>
