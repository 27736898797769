<section class="flex flex-col space-y-4" *ngIf="!isLoading; else loader">
  <app-back-button link="/admin/home"></app-back-button>

  <div class="flex flex-col space-y-3 py-5">
    <h3 class="title">{{ "Admin.Security.Terrorists.Title" | translate }}</h3>
    <div class="box grid grid-rows-1 gap-3">
      <ng-container>
        <div class="flex items-center gap-2">
          <span class="text-sm font-bold">{{ "Admin.Security.Terrorists.Add" | translate }}</span>
          <button
            class="ml-auto w-6"
            (click)="handleOpenModal()"
            [disabled]="isUserObserverAdmin"
          >
            <img src="assets/icons/plus.svg" alt="add" />
          </button>
        </div>
      </ng-container>
    </div>

    <div class="box grid grid-rows-1 gap-3">
      <ng-container>
        <div class="flex items-center gap-2">
          <span class="text-sm font-bold">{{ "Admin.Security.Terrorists.Terrorist_list" | translate }}</span>
          <button
            class="ml-auto w-6"
            (click)="handleDownloadTerroristReportButtonClick()"
            [disabled]="isUserObserverAdmin"
          >
            <img src="assets/icons/import-icon.svg" alt="Export" />
          </button>
        </div>
      </ng-container>
    </div>

    <div class="box grid grid-rows-1 gap-3">
      <ng-container>
        <div class="flex items-center gap-2">
          <span class="text-sm font-bold">{{ "Admin.Security.Terrorists.Import" | translate }}</span>
          <input type="file" #fileInput style="display: none" (change)="onFileSelected($event)"/>
          <button
            class="ml-auto w-6"
            (click)="fileInput.click()"
            [disabled]="isUserObserverAdmin"
          >
            <img src="assets/icons/export-icon.svg" alt="Import" />
          </button>
        </div>
      </ng-container>
    </div>

    <div class="box grid grid-rows-1 gap-3">
      <ng-container>
        <div class="flex items-center gap-2">
          <span class="text-sm font-bold">{{ "Admin.Security.Terrorists.Scan" | translate }}</span>
          <button
            class="ml-auto w-6"
            (click)="requestScannedTerrors()"
            [disabled]="isUserObserverAdmin"
          >
            <img src="assets/icons/scan-icon.svg" alt="Scan" />
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</section>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<jw-modal [id]="ModalConstants.AddTerrorist">
  <form class="flex flex-col space-y-4" [formGroup]="addTerroristform" (ngSubmit)="onModalSubmit()">
    <h4 class="title-big">{{ "Admin.Security.Terrorists.Add_terrorist" | translate }}</h4>
    <app-input
      [title]="'Admin.Security.Terrorists.Name' | translate"
      type="text"
      formControlName="firstName"
      id="terroristName"
      [errorMsg]="addTerrorNameErrorMessage"
    ></app-input>
    <app-input
      [title]="'Admin.Security.Terrorists.Last_name' | translate"
      type="text"
      formControlName="lastName"
      id="terroristLastName"
      [errorMsg]="addTerrorLastNameErrorMessage"
    ></app-input>
    <app-input
      [title]="'Admin.Security.Terrorists.Middle_name' | translate"
      type="text"
      formControlName="middleName"
      id="terroristMiddleName"
    ></app-input>
    <app-input
      [title]="'Admin.Security.Terrorists.PinFL' | translate"
      type="text"
      formControlName="pinFL"
      id="terroristPinFL"
      [errorMsg]="addTerrorPinFLErrorMessage"
    ></app-input>
    <button type="submit" class="btn" [disabled]="addTerroristform.invalid">
      {{ "Common.Add" | translate }}
    </button>
  </form>
</jw-modal>

<jw-modal [id]="ModalConstants.AdminGetScannedTerrors" [bodyWidth]="'w-[44rem]'">
  <span class="text-xl">{{ "Admin.Security.Terrorists.Scanned_terrors_title" | translate }}</span>
  <table class="adminTable mb-3">
    <thead>
      <tr>
        <th scope="col">{{ "Admin.Security.Terrorists.User_fullname" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.Terror_fullname" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.User_pinFL" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.Terror_pinFL" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.Block" | translate }}</th>
        <th scope="col">{{ "Admin.Users.Profile" | translate }}</th>
      </tr>
    </thead>
    <tbody *ngIf="scannedTerrorItems?.length; else noTerrors">
      <tr *ngFor="let scannedTerror of scannedTerrorItems">
        <td> {{scannedTerror.kycFullName}}</td>
        <td>{{scannedTerror.terroristFullName}}</td>
        <td>{{scannedTerror.kycPinFL}}</td>
        <td>{{scannedTerror.terroristPinFL}}</td>
        <td>
          <app-switch [(ngModel)]="switchValues[scannedTerror.userId]" (onSwitch)="onSwitchChange(scannedTerror.userId)"></app-switch>
        </td>
        <td>
          <a
            class="w-6 h-6"
            [routerLink]="'/admin/users/' + scannedTerror.userId"
            [title]="'Admin.Users.Open_user_page' | translate"
            target="_blank"
          >
            <img src="assets/icons/link.svg" alt="Accept" />
          </a>
      </tr>
    </tbody>
    <ng-template #noTerrors>
      <tr>
        <td colspan="7" class="text-center">{{ "Admin.Security.Terrorists.Terror_not_found" | translate }}</td>
      </tr>
    </ng-template>
  </table>

  <ngb-pagination
    [collectionSize]="totalCount"
    [(page)]="page"
    [pageSize]="pageSize"
    [maxSize]="3"
    [boundaryLinks]="true"
    (pageChange)="requestScannedTerrors()"
  >
  </ngb-pagination>
</jw-modal>