<label class="customInput">
  <p>{{ title }}</p>

  <div class="customInput__inputWrapper">
    <!-- Phone code -->
    <span
      *ngIf="type === 'phone'"
      class="absolute left-4 top-1/2 -translate-y-1/2 text-sm font-medium leading-none"
    >
      +{{ phoneCode }}
    </span>

    <input
      [type]="type"
      [name]="name"
      [id]="id"
      [placeholder]="placeholder"
      [ngClass]="{
        'is-valid': errorMsg == null && control.value != null,
        'is-invalid': errorMsg != null,
        '!pl-14': type === 'phone',
        '!pr-14': withPasswordToggle || timer
      }"
      [formControl]="control"
      [mask]="mask"
      [dropSpecialCharacters]="dropSpecialCharacters"
      onmousewheel="return false;"
      [autocomplete]="autocomplete"
      [max]="max"
      [min]="min"
      [appAutofocus]="autofocus"
    />
    <!-- Show/hide password -->
    <button
      *ngIf="withPasswordToggle"
      type="button"
      class="absolute right-4 top-1/2 flex h-7 w-7 -translate-y-1/2 items-center justify-center opacity-70"
      (click)="togglePassword()"
    >
      <img [src]="isPasswordShown ? 'assets/icons/eye-on.svg' : 'assets/icons/eye-off.svg'" alt="toggle" />
    </button>
    <!-- Time counter -->
    <div *ngIf="timer" class="absolute right-4 top-1/2 -translate-y-1/2">
      <span *ngIf="timeCounter > 0; else refresh" class="text-sm font-medium text-[#666]">
        {{ timeCounter }}
      </span>
      <ng-template #refresh>
        <button
          type="button"
          (click)="onRefreshClick()"
          class="flex h-7 w-7 items-center justify-center opacity-70"
        >
          <img src="assets/icons/refresh.svg" alt="refresh" />
        </button>
      </ng-template>
    </div>
  </div>

  <ng-container *ngIf="comment.length">
    <span class="block text-sm text-text-pale" *ngFor="let c of comment">
      {{ c }}
    </span>
  </ng-container>
  <span *ngIf="errorMsg != null" class="customInput__error">
    {{ errorMsg }}
  </span>
</label>
