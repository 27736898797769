<app-base-auth-form [linkType]="3">
  <h3 class="uppercase text-base text-center">{{ "Login.Change_password" | translate }}</h3>

  <ng-container [ngSwitch]="step">
    <ng-template ngSwitchCase="sms">
      <form class="customForm" [formGroup]="smsForm" (ngSubmit)="onPhoneSubmit()">
        <app-input
          [title]="'Login.Phone_number' | translate"
          type="phone"
          placeholder="11 222 33 44"
          id="phoneNumber"
          formControlName="phoneNumber"
          [errorMsg]="phoneNumberError"
          mask="00 000 00 00"
          [autocomplete]="'off'"
        />
        <button type="submit" class="btn" [disabled]="smsForm.invalid || isPending">
          {{ "Login.Continue" | translate }}
        </button>
        <p *ngIf="businessError" class="text-danger text-sm">{{ businessError }}</p>
      </form>
    </ng-template>

    <ng-template ngSwitchCase="password">
      <form class="customForm" [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-input
          [title]="'Profile.New_password' | translate"
          type="password"
          placeholder="******"
          formControlName="psw"
          [errorMsg]="pswError"
          id="psw"
        />
        <app-input
          [title]="'Profile.Repeat_new_password' | translate"
          type="password"
          placeholder="******"
          formControlName="pswRepeat"
          [errorMsg]="pswRepeatError"
          id="pswRepeat"
        />
        <app-input
          [title]="'Login.Sms_code' | translate"
          type="number"
          placeholder="123456"
          formControlName="code"
          id="code"
          [errorMsg]="codeError"
        />
        <re-captcha *ngIf="otpInputCount >= validateConstants.MaxOtpTries" (resolved)="resolved($event)"></re-captcha>

        <button
          type="submit"
          class="btn"
          [disabled]="(form.invalid || isPending) || (otpInputCount >= validateConstants.MaxOtpTries)">
          {{ "Login.Change_password" | translate }}
        </button>
        <p *ngIf="businessError" class="text-danger text-sm">{{ businessError }}</p>
      </form>
    </ng-template>
  </ng-container>
</app-base-auth-form>
