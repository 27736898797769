import {Injectable} from "@angular/core";
import {Subject, Subscription} from "rxjs";
import {EventData} from "src/app/common/models/event-data";
import { filter, map } from 'rxjs/operators';
import {EventHandlerDelegate} from "src/app/common/delegats/event-handler.delegate";

@Injectable({
  providedIn: "root"
})
export class EventBusService {
  private readonly subject = new Subject<EventData>();

  public dispatch(e: EventData): void {
    this.subject.next(e);
  }

  public handle(eventName: string, action: EventHandlerDelegate): Subscription {
    return this.subject
      .pipe(
        filter((e: EventData) => e.name === eventName),
        map((e: EventData) => e.value))
      .subscribe(action);
  }
}
