import { Component, Input, OnChanges } from "@angular/core";

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.css"],
})
export class StepperComponent implements OnChanges {
  @Input() totalSteps: number | undefined;
  @Input() currentStep = 1;
  public stepsArray: number[] = [];

  ngOnChanges() {
    if (this.totalSteps) {
      this.stepsArray = Array.from({ length: this.totalSteps }, (_, i) => i + 1);
    } else {
      this.stepsArray = [1];
    }
  }
}
