import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/common/constants/constants";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { CardDto } from "src/app/common/DTO/cards/card.dto";
import { BuyOrderDto } from "src/app/common/DTO/crypto/buy-order.dto";
import { SellOrderDto } from "src/app/common/DTO/crypto/sell-order.dto";
import { WalletBalanceDto } from "src/app/common/DTO/wallets/wallet-balance.dto";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { CryptoErrorCode } from "src/app/common/enums/crypto-error-code.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { CalculatorService } from "src/app/services/calculator.service";
import { CardService } from "src/app/services/card.service";
import { CryptoService } from "src/app/services/crypto.service";
import { ToastService } from "src/app/services/toast.service";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  selector: "app-swap-modal",
  templateUrl: "./swap-modal.component.html",
  styleUrls: ["./swap-modal.component.css"],
})
export class SwapModalComponent implements OnInit {
  @Input() walletBalance: WalletBalanceDto | null = null;

  step: "input" | "confirm" | "success" | "error" = "input";
  wallets: WalletDto[] = [];
  fromWalletBalance = this.walletBalance;
  bankCards: CardDto[] = [];
  selectedBankCard: CardDto | null = null;
  isPending = false;
  CryptoSymbol = CryptoSymbol;
  confirmationTimeCounter: number = 0;

  private MAX_INPUT_VALUE = 100_000_000_000;
  private _timer: any;

  readonly currencies = [
    CryptoSymbol.Uzs,
    CryptoSymbol.Trx,
    CryptoSymbol.Usdt,
    CryptoSymbol.Matic,
    CryptoSymbol.PolygonUsdt,
    CryptoSymbol.Ton,
    CryptoSymbol.TonUsdt,
    CryptoSymbol.Not,
    CryptoSymbol.Bitcoin,
  ];

  constructor(
    private readonly _cardService: CardService,
    private readonly _translateService: TranslateService,
    private readonly _walletService: WalletService,
    private readonly _router: Router,
    private readonly _activeModal: NgbActiveModal,
    private readonly _cryptoService: CryptoService,
    private readonly _toastService: ToastService,
    private readonly _calculatorService: CalculatorService
  ) {}

  async ngOnInit() {
    if (this.walletBalance) {
      this.fromWalletBalance = this.walletBalance;
      this._calculatorService.setFromCurrency(this.walletBalance.currency);
    } else {
      this._calculatorService.setFromCurrency(CryptoSymbol.Trx);
    }
    this._calculatorService.setToCurrency(CryptoSymbol.Uzs);
    this._calculatorService.form.get("toAmount")?.disable?.();
    this.isPending = true;
    await Promise.all([
      this._calculatorService.init(),
      this.getWallets(),
      this.loadBankCards(),
    ]);
    this.isPending = false;
  }

  public get calculatorForm() {
    return this._calculatorService.form;
  }

  public get fromCurrency() {
    return this._calculatorService.fromCurrency;
  }

  public get toCurrency() {
    return this._calculatorService.toCurrency;
  }

  public get fromAmount() {
    return this._calculatorService.fromAmount;
  }

  public get toAmount() {
    return this._calculatorService.toAmount;
  }

  public get pureUzsToAmount() {
    if (!this.fromAmount) {
      return 0;
    }
    return this._calculatorService.convertAmount(this.fromAmount, this.fromCurrency, CryptoSymbol.Uzs);
  }

  public get serviceFee() {
    if (!this.fromAmount) {
      return 0;
    }
    const convertedFromAmount = this._calculatorService.convertAmount(
      this.fromAmount,
      this.fromCurrency,
      this.toCurrency
    );
    return convertedFromAmount * this._calculatorService.serviceFeeFactor;
  }

  public get uzsServiceFee() {
    if (!this.fromAmount) {
      return 0;
    }
    return this.pureUzsToAmount * this._calculatorService.serviceFeeFactor;
  }

  public get totalAmount(): number {
    if (!this.pureUzsToAmount) {
      return 0;
    }
    if (this.fromCurrency === CryptoSymbol.Uzs) {
      return this.pureUzsToAmount + this.uzsServiceFee;
    } else if (this.toCurrency === CryptoSymbol.Uzs) {
      return this.pureUzsToAmount - this.uzsServiceFee;
    } else {
      return 0;
    }
  }

  public get oneUzsCurrencyAmount(): number {
    let selectedCurrency;
    if (this.toCurrency === CryptoSymbol.Uzs) {
      selectedCurrency = this.fromCurrency;
    } else if (this.fromCurrency === CryptoSymbol.Uzs) {
      selectedCurrency = this.toCurrency;
    } else {
      return 0;
    }
    return this._calculatorService.convertAmount(1, selectedCurrency, CryptoSymbol.Uzs);
  }

  public get currencySymbol() {
    return getCurrencyName(this.fromCurrency);
  }

  public get currencyNetwork() {
    switch (this.fromCurrency) {
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "Polygon";
      case CryptoSymbol.Trx:
      case CryptoSymbol.Usdt:
        return "TRC20";
      case CryptoSymbol.Ton:
      case CryptoSymbol.Not:
      case CryptoSymbol.TonUsdt:
        return "Ton";
      case CryptoSymbol.Bitcoin:
        return "Bitcoin";
      default:
        return "";
    }
  }

  public get nativeCurrencyName() {
    switch (this.fromCurrency) {
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "MATIC";
      case CryptoSymbol.Trx:
      case CryptoSymbol.Usdt:
        return "TRX";
      case CryptoSymbol.Ton:
      case CryptoSymbol.Not:
      case CryptoSymbol.TonUsdt:
        return "TON";
      case CryptoSymbol.Bitcoin:
        return "BTC";
      default:
        return "";
    }
  }

  public get fromCurrencyName() {
    return getCurrencyName(this.fromCurrency);
  }

  public get toCurrencyName() {
    return getCurrencyName(this.toCurrency);
  }

  public get fromAmountError() {
    const amount = this.calculatorForm.get("fromAmount")?.value ?? 0;
    if (!amount) {
      return null;
    }
    if (+amount === 0) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (this.totalAmount <= 0) {
      return this._translateService.instant("Sell.Min_value_error");
    }
    return null;
  }

  public get toAmountError() {
    const amount = this.calculatorForm.get("toAmount")?.value ?? 0;
    if (!amount) {
      return null;
    }
    if (+amount === 0) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    return null;
  }

  onClose() {
    this._activeModal.close();
  }

  goToBankCards() {
    const route = `${RouteConstants.depositary}/${RouteConstants.cards}`;
    this._router.navigateByUrl(route);
    this.onClose();
  }

  goToWallet() {
    this._router.navigateByUrl(RouteConstants.wallet);
    this.onClose();
  }

  public selectFromCurrency(currency: CryptoSymbol) {
    if (currency === this.fromCurrency) {
      return;
    }
    this._calculatorService.setFromCurrency(currency);
    if (currency === CryptoSymbol.Uzs) {
      this._calculatorService.setToCurrency(CryptoSymbol.Trx);
    } else {
      this.wallets.forEach(wallet => {
        wallet.balances.forEach(balance => {
          if (balance.currency === currency) {
            this.fromWalletBalance = balance;
          }
        });
      });
    }
    if (currency !== CryptoSymbol.Uzs && this.toCurrency !== CryptoSymbol.Uzs) {
      this._calculatorService.setToCurrency(CryptoSymbol.Uzs);
    }
    this._calculatorService.triggerCalculations(this.fromAmount);
  }

  public selectToCurrency(currency: CryptoSymbol) {
    if (currency === this.toCurrency) {
      return;
    }
    this._calculatorService.setToCurrency(currency);
    if (currency === CryptoSymbol.Uzs) {
      this._calculatorService.setFromCurrency(CryptoSymbol.Trx);
    } else if (this.fromCurrency !== CryptoSymbol.Uzs) {
      this._calculatorService.setFromCurrency(CryptoSymbol.Uzs);
    }
    this._calculatorService.triggerCalculations(this.fromAmount);
  }

  handleToAmountChange(event: any) {
    let { value } = event.target;

    if (Number(value) > this.MAX_INPUT_VALUE) {
      value = value.toString().slice(0, this.MAX_INPUT_VALUE.toString().length);
      if (value !== event.target.value) {
        event.target.value = value;
        event.target.dispatchEvent(new Event("input"));
      }
    }

    this._calculatorService.triggerCalculations(this.fromAmount);
  }

  onInputSubmit() {
    if (
      this.calculatorForm.invalid ||
      !this.toAmount ||
      +this.toAmount === 0 ||
      !this.fromAmount ||
      +this.fromAmount === 0
    ) {
      return;
    }

    this.step = "confirm";

    this.confirmationTimeCounter = 30;
    this._timer = setInterval(() => {
      if (this.confirmationTimeCounter > 0) {
        this.confirmationTimeCounter--;
      } else {
        this.backToInput();
        return;
      }
    }, Constants.MsInSec);
  }

  backToInput() {
    this.step = "input";
    clearInterval(this._timer);
  }

  onCheckout() {
    if (!this.selectedBankCard) {
      return;
    }
    if (this.fromCurrency === CryptoSymbol.Uzs) {
      this.doBuy();
    }
    if (this.toCurrency === CryptoSymbol.Uzs) {
      this.doSell();
    }
    clearInterval(this._timer);
  }

  private async doSell() {
    this.isPending = true;

    const order: SellOrderDto = {
      sellAmount: Number(this.toAmount),
      sellCurrency: this.toCurrency,
      cardId: this.selectedBankCard!.id,
    };

    const res = await this._cryptoService.sellCrypto(order);
    if (res.withError) {
      if (res.errorCode === CryptoErrorCode.Disabled) {
        this._toastService.show(this._translateService.instant("Sell.Sell_disabled"));
      } else {
        this._toastService.show(this._translateService.instant("Common.Unknown_error"));
      }
      this.step = "error";
    } else {
      this.step = "success";
    }
    this.isPending = false;
  }

  private async doBuy() {
    this.isPending = true;

    const order: BuyOrderDto = {
      buyAmount: Number(this.fromAmount),
      buyCurrency: this.fromCurrency,
      cardId: this.selectedBankCard!.id,
    };

    const res = await this._cryptoService.buyCrypto(order);
    if (res.withError) {
      if (res.errorCode === CryptoErrorCode.Disabled) {
        this._toastService.show(this._translateService.instant("Buy.Buy_disabled"));
      } else {
        this._toastService.show(this._translateService.instant("Common.Unknown_error"));
      }
      this.step = "error";
    } else {
      this.step = "success";
    }
    this.isPending = false;
  }

  private async loadBankCards() {
    const res = await this._cardService.getCards();
    if (res.withError || !res.params) {
      return;
    }
    this.bankCards = res.params;
    this.selectedBankCard = this.bankCards[0];
  }

  private async getWallets() {
    const wallets = await this._walletService.getMy();
    this.wallets = wallets?.params || [];
  }

  ngOnDestroy() {
    clearInterval(this._timer);
  }
}
