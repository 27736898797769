import { Component, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { kycFormGroupValidator } from "src/app/pages/auth/kyc/form-group-validators/kyc-form-group.validator";
import { KycService } from "src/app/services/kyc.service";
import { SendKycDto } from "src/app/common/DTO/kyc/send-kyc.dto";
import { KycErrorCode } from "src/app/common/enums/kyc-error-code.enum";
import { Router } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";

@Component({
  templateUrl: "./kyc.component.html",
  styleUrls: ["./kyc.component.css"],
})
export class KycComponent implements OnDestroy {
  public internalError = false;
  public formGroup: FormGroup;
  public isAttachedFileValid = false;

  constructor(private readonly _kycService: KycService, private readonly _router: Router) {
    this.formGroup = kycFormGroupValidator;
    this.formGroup.reset();
  }

  public async sendKyc() {
    if (this.formGroup.invalid) {
      return;
    }

    const dto = new SendKycDto(this.file!.value, this.name!.value, this.passId!.value);
    const response = await this._kycService.sendKyc(dto);

    if (!response.withError || response.errorCode == KycErrorCode.KycAlreadyAdded) {
      this._router.navigate([RouteConstants.profile]);
      return;
    }

    if (response.errorCode == KycErrorCode.InternalError) {
      this.internalError = true;
    }
  }

  public deleteFile(event: any) {
    event.preventDefault();
    this.resetFormControl("file");
  }

  public onFileChange(event: any) {
    this.setFormControlValue("file", event.target.files[0]);
  }

  public onPassFileChange(event: any) {
    this.setFormControlValue("passFile", event.target.files[0]);
  }

  public deletePassFile(event: any) {
    event.preventDefault();
    this.resetFormControl("passFile");
  }

  public get passId() {
    return this.formGroup.get("passId");
  }

  public get pinfl() {
    return this.formGroup.get("pinfl");
  }

  public get name() {
    return this.formGroup.get("name");
  }

  public get file() {
    return this.formGroup.get("file");
  }

  public get passFile() {
    return this.formGroup.get("passFile");
  }

  public ngOnDestroy(): void {
    this.formGroup.reset();
  }

  private resetFormControl(controlName: string) {
    this.formGroup.get(controlName)?.reset();
  }

  private setFormControlValue(controlName: string, value: any) {
    this.formGroup.get(controlName)?.setValue(value);
  }
}
