<section>
  <h3 class="title">{{ "Admin.Home.Multiplier" | translate }}</h3>

  <div class="box mt-5 flex items-center justify-between">
    <p class="text-sm font-medium">
      {{ "Admin.Home.Multiplier_description" | translate }}:
      <span class="font-bold">{{ currentMultiplier }}%</span>
    </p>
    <button class="w-6" (click)="handleOpenModal(editMultiplierModal)">
      <app-svg-icon name="edit" />
    </button>
  </div>
</section>

<ng-template let-modal #editMultiplierModal>
  <form [formGroup]="multiplierForm" (ngSubmit)="handleSaveMultiplier()" class="flex flex-col space-y-4">
    <h4 class="title-big">{{ "Admin.Home.Multiplier_description" | translate }}, %</h4>
    <app-input type="number" formControlName="multiplier" appPositiveDecimals />
    <button type="submit" class="btn" [disabled]="multiplierForm.invalid || isPending">
      {{ "Admin.Home.Change" | translate }}
    </button>
  </form>
</ng-template>
