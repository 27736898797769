import { Component, Input } from "@angular/core";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";

@Component({
  selector: "app-token-growth",
  templateUrl: "./token-growth.component.html",
  styleUrls: ["./token-growth.component.css"],
})
export class TokenGrowthComponent {
  @Input() tokenRate: RateDto | null = null;

  public CryptoSymbol = CryptoSymbol;

  public get rateName(): string {
    switch (this.tokenRate?.symbol) {
      case CryptoSymbol.Uzs:
        return "UZS";
      case CryptoSymbol.Usdt:
        return "USDT";
      case CryptoSymbol.Trx:
        return "TRX";
      case CryptoSymbol.Matic:
        return "MATIC";
      case CryptoSymbol.PolygonUsdt:
        return "USDT";
      case CryptoSymbol.Ton:
        return "TON";
      case CryptoSymbol.Not:
        return "NOT";
      case CryptoSymbol.TonUsdt:
        return "USDT";
      case CryptoSymbol.Bitcoin:
        return "BTC";
      default:
        return "UNKNOWN";
    }
  }
}
