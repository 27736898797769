export class ToncenterRawTxDto {
  account = "";
  account_state_after = {
    account_status: "",
    balance: "",
    code_boc: "",
    code_hash: "",
    data_boc: "",
    data_hash: "",
    frozen_hash: "",
    hash: "",
  };
  account_state_before = {
    account_status: "",
    balance: "",
    code_boc: "",
    code_hash: "",
    data_boc: "",
    data_hash: "",
    frozen_hash: "",
    hash: "",
  };
  block_ref = {
    seqno: 0,
    shard: "0" as `${number}`,
    workchain: 0,
  };
  description = {
    aborted: true,
    action: {
      action_list_hash: "",
      msgs_created: 0,
      no_funds: true,
      result_arg: 0,
      result_code: 0,
      skipped_actions: 0,
      spec_actions: 0,
      status_change: "",
      success: true,
      tot_actions: 0,
      tot_msg_size: {
        bits: "0" as `${number}`,
        cells: "0" as `${number}`,
      },
      total_action_fees: "0" as `${number}`,
      total_fwd_fees: "0" as `${number}`,
      valid: true,
    },
    bounce: {
      fwd_fees: "0" as `${number}`,
      msg_fees: "0" as `${number}`,
      msg_size: {
        bits: "0" as `${number}`,
        cells: "0" as `${number}`,
      },
      req_fwd_fees: "0" as `${number}`,
      type: "",
    },
    compute_ph: {
      account_activated: true,
      exit_arg: 0,
      exit_code: 0,
      gas_credit: "0" as `${number}`,
      gas_fees: "0" as `${number}`,
      gas_limit: "0" as `${number}`,
      gas_used: "0" as `${number}`,
      mode: 0,
      msg_state_used: true,
      reason: "",
      skipped: true,
      success: true,
      vm_final_state_hash: "",
      vm_init_state_hash: "",
      vm_steps: 0,
    },
    credit_first: true,
    credit_ph: {
      credit: "0" as `${number}`,
      due_fees_collected: "0" as `${number}`,
    },
    destroyed: true,
    installed: true,
    is_tock: true,
    split_info: {
      acc_split_depth: 0,
      cur_shard_pfx_len: 0,
      sibling_addr: "",
      this_addr: "",
    },
    storage_ph: {
      status_change: "",
      storage_fees_collected: "0" as `${number}`,
      storage_fees_due: "0" as `${number}`,
    },
    type: "",
  };
  end_status = "";
  hash = "";
  in_msg = {
    bounce: true,
    bounced: true,
    created_at: "0" as `${number}`,
    created_lt: "0" as `${number}`,
    destination: "",
    fwd_fee: "0" as `${number}`,
    hash: "",
    ihr_disabled: true,
    ihr_fee: "0" as `${number}`,
    import_fee: "0" as `${number}`,
    init_state: {
      body: "",
      decoded: {
        comment: "",
        type: "",
      },
      hash: "",
    },
    message_content: {
      body: "",
      decoded: {
        comment: "",
        type: "",
      },
      hash: "",
    },
    opcode: 0,
    source: "",
    value: "0" as `${number}`,
  };
  lt = "0" as `${number}`;
  mc_block_seqno = 0;
  now = 0;
  orig_status = "";
  out_msgs = [
    {
      bounce: true,
      bounced: true,
      created_at: "0" as `${number}`,
      created_lt: "0" as `${number}`,
      destination: "",
      fwd_fee: "0" as `${number}`,
      hash: "",
      ihr_disabled: true,
      ihr_fee: "0" as `${number}`,
      import_fee: "0" as `${number}`,
      init_state: {
        body: "",
        decoded: {
          comment: "",
          type: "",
        },
        hash: "",
      },
      message_content: {
        body: "",
        decoded: {
          comment: "",
          type: "",
        },
        hash: "",
      },
      opcode: 0,
      source: "",
      value: "0" as `${number}`,
    },
  ];
  prev_trans_hash = "";
  prev_trans_lt = "0" as `${number}`;
  total_fees = "0" as `${number}`;
  trace_id = "";
}
