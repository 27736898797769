import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";

export class ConvertCurrencyHelper {
  public static convertToUzs(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    const uzs = rates.find(x => x.symbol == CryptoSymbol.Uzs)!.usd;
    return Math.floor(usd / uzs);
  }

  public static convertToUsd(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    return +usd.toFixed(3);
  }

  public static convertToUsdt(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    const usdt = rates.find(x => x.symbol == CryptoSymbol.Usdt)!.usd;

    return +(usd / usdt).toFixed(3);
  }

  public static convertToTrx(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    const trx = rates.find(x => x.symbol == CryptoSymbol.Trx)!.usd;

    return +(usd / trx).toFixed(3);
  }

  public static convertToMatic(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    const matic = rates.find(x => x.symbol == CryptoSymbol.Matic)!.usd;

    return +(usd / matic).toFixed(3);
  }

  public static convertToPolygonUsdt(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    const polygonUsdt = rates.find(x => x.symbol == CryptoSymbol.PolygonUsdt)!.usd;

    return +(usd / polygonUsdt).toFixed(3);
  }

  public static convertToTon(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    const ton = rates.find(x => x.symbol == CryptoSymbol.Ton)!.usd;

    return +(usd / ton).toFixed(3);
  }

  public static convertToTonUsdt(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    const tonUsdt = rates.find(x => x.symbol == CryptoSymbol.TonUsdt)!.usd;

    return +(usd / tonUsdt).toFixed(3);
  }

  public static convertToNot(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    const not = rates.find(x => x.symbol == CryptoSymbol.Not)!.usd;

    return +(usd / not).toFixed(3);
  }

  public static convertToBitcoin(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    const bitcoin = rates.find(x => x.symbol == CryptoSymbol.Bitcoin)!.usd;

    return +(usd / bitcoin).toFixed(3);
  }
}
