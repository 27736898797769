export class PolygonRawUsdtTx {
  public blockNumber = "";
  public timeStamp = "";
  public hash = "";
  public nonce = "";
  public blockHash = "";
  public from = "";
  public contractAddress = "";
  public to = "";
  public value = "";
  public tokenName = "";
  public tokenSymbol = "";
  public tokenDecimal = "";
  public transactionIndex = "";
  public gas = "";
  public gasPrice = "";
  public gasUsed = "";
  public cumulativeGasUsed = "";
  public input = "";
  public confirmations = "";
}
