import { Pipe, PipeTransform } from "@angular/core";
import { CryptoSymbol } from "../enums/crypto-symbol.enum";

type MfeCustomPipeOptions = {
  currency?: CryptoSymbol;
};

@Pipe({
  name: "mfeCustom",
})
export class MfeCustomPipe implements PipeTransform {
  transform(value: string | number | undefined, options?: MfeCustomPipeOptions): string {
    if (value === undefined) {
      return "0";
    }

    let transformed = Number(value);

    if (Number.isNaN(transformed) || transformed <= 0) {
      return "0";
    }

    let maxDecimals = 2;
    switch (options?.currency) {
      case CryptoSymbol.Uzs:
        maxDecimals = 0;
        break;
      case CryptoSymbol.Bitcoin:
        maxDecimals = 6;
        break;
      default:
        maxDecimals = 2;
        break;
    }

    return new Intl.NumberFormat("mfe", {
      minimumFractionDigits: 0,
      maximumFractionDigits: maxDecimals,
    }).format(transformed);
  }
}
