import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Injectable({ providedIn: "root" })
export class AuthGuard {
  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _router: Router
  ) {}

  public async canActivate() {
    const token = await this._localStorage.accessToken();
    const tokenLifetime = await this._localStorage.accessTokenLifetime();
    const refresh = await this._localStorage.refreshToken();
    const refreshLifetime = await this._localStorage.refreshTokenLifetime();

    if ((token == null || tokenLifetime == null) && (refresh == null || refreshLifetime == null)) {
      this._router.navigateByUrl("/");
      return false;
    }

    return true;
  }
}
