<div *ngIf="internalError" class="text-center bg-invalid-color">
  <div class="text-text-secondary">{{ "Common.Unknown_error" | translate }}</div>
</div>

<app-client-layout>
  <div *ngIf="verificationStatus != 2" class="mt-6 max-md:mt-0 mb-10 max-md:mb-5">
    <h3 class="title">{{ "Kyc.Verification" | translate }}</h3>
    <div class="box mt-4">
      <p class="text-sm" [ngSwitch]="verificationStatus">
        <ng-template ngSwitchCase="0">
          {{ "Kyc.Status_required" | translate }}
        </ng-template>
        <ng-template ngSwitchCase="1">
          {{ "Kyc.Status_pending" | translate }}
        </ng-template>
      </p>
      <button
        class="btn mt-5"
        *ngIf="verificationStatus === 0 || verificationStatus === 3"
        routerLink="/myid"
      >
        {{ "Kyc.Do_verification" | translate }}
      </button>
    </div>
  </div>

  <div>
    <div class="flex items-center justify-between">
      <h3 class="title">{{ "Profile.My_profile" | translate }}</h3>
      <div class="flex items-center justify-between space-x-5 text-sm max-md:text-xs max-md:space-x-2">
        <p>
          {{ verificationStatus === 2 ? ("Kyc.Verified" | translate) : ("Kyc.Not_verified" | translate) }}
        </p>

        <ng-container *ngIf="verificationStatus === 1 || verificationStatus === 3">
          <span class="dot"></span>
          <p>
            {{ verificationStatus === 1 ? ("Kyc.In_process" | translate) : "" }}
            {{ verificationStatus === 3 ? ("Kyc.Rejected" | translate) : "" }}
          </p>
        </ng-container>
      </div>
    </div>

    <div class="box mt-4 py-10">
      <div class="flex flex-col items-center space-y-4">
        <img class="rounded-full h-[6.25rem] w-[6.25rem] object-cover" [src]="profilePhotoSrc" alt="avatar" />

        <div class="flex flex-col space-y-2 min-w-[12.5rem]" *ngIf="!isUserBlocked">
          <button class="btn btn_pale w-full" (click)="openChangeAvatarModal()">
            {{ "Profile.Upload_photo" | translate }}
          </button>
          <!-- <input
            (change)="onPhotoInputChange($event)"
            #photoInput
            type="file"
            name="photo"
            id="photo"
            accept="image/jpeg,image/png"
            class="hidden"
          /> -->
          <button class="btn btn_pale w-full" (click)="openChangePasswordModal()">
            {{ "Profile.Change_password" | translate }}
          </button>
        </div>

        <div class="text-base">{{ user.phoneNumber | phoneDisplayer }}</div>

        <ng-container *ngIf="isUserBlocked">
          <p class="text-sm text-danger">{{ "Profile.Blocked" | translate }}</p>
        </ng-container>
      </div>
    </div>
  </div>
</app-client-layout>

<app-select-avatar-modal [currentAvatar]="profilePhotoSrc" (onChange)="onChangeAvatar($event)" />

<jw-modal [id]="ModalConstants.ChangePassword" [fullScreenOnMobile]="true">
  <form
    class="flex flex-col space-y-4"
    [formGroup]="changePasswordValidator"
    (ngSubmit)="handleChangePassword()"
  >
    <h4 class="title-big">{{ "Profile.Change_password" | translate }}</h4>
    <app-input
      [title]="'Profile.Old_password' | translate"
      type="password"
      placeholder="******"
      id="oldPsw"
      formControlName="oldPsw"
      [errorMsg]="_oldPswError"
    ></app-input>
    <app-input
      [title]="'Profile.New_password' | translate"
      type="password"
      placeholder="******"
      id="newPsw"
      formControlName="newPsw"
      [errorMsg]="_newPswError"
    ></app-input>
    <app-input
      [title]="'Profile.Repeat_new_password' | translate"
      type="password"
      placeholder="******"
      id="repeatNewPsw"
      formControlName="pswRepeat"
      [errorMsg]="_pswRepeatError"
    ></app-input>
    <button type="submit" class="btn" [disabled]="changePasswordValidator.invalid">
      {{ "Common.Change" | translate }}
    </button>
  </form>
</jw-modal>
