import { Injectable } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Injectable({ providedIn: "root" })
export class LogoutGuard {
  constructor(
    private readonly _authService: AuthService,
    public readonly _localStorage: LocalStorageService
  ) {}

  public async canActivate(): Promise<boolean> {
    await this._authService.logout();
    await this._localStorage.clearTokens();
    await this._localStorage.removeUserData();
    window.location.replace("/");
    return false;
  }
}
