<div class="flex items-center gap-5">
  <button
    class="rounded-xl bg-secondary px-[3.75rem] py-6 text-sm font-semibold uppercase text-text-primary max-md:w-full max-md:px-0"
    [ngClass]="{
      'border-2 border-text-secondary !bg-accent text-text-secondary': type === 'buy'
    }"
    (click)="onChangeType('buy')"
  >
    {{ "Crypto_transaction.Deposit" | translate }}
  </button>
  <button
    class="rounded-xl bg-secondary px-[3.75rem] py-6 text-sm font-semibold uppercase text-text-primary max-md:w-full max-md:px-0"
    [ngClass]="{
      'border-2 border-text-secondary !bg-accent text-text-secondary': type === 'sell'
    }"
    (click)="onChangeType('sell')"
  >
    {{ "Crypto_transaction.Withdraw" | translate }}
  </button>
</div>

<div class="mt-5 rounded-tw bg-box-background p-7 shadow-xl max-md:mt-3">
  <div class="grid grid-cols-3 gap-10 max-md:grid-cols-1 max-md:gap-3">
    <app-calculator-token-button
      *ngFor="let currency of inputCurrencies"
      [currency]="currency"
      (onSelect)="onSelectInputCurrency($event)"
      [isActive]="selectedInputCurrency === currency"
    />
  </div>
  <input
    placeholder="0"
    class="mt-10 w-full rounded-xl border border-border-input-color bg-input-bg px-5 py-7 text-2xl font-bold leading-none max-md:mt-3"
    [(ngModel)]="inputAmount"
    (input)="calculateOutputAmount()"
    appPositiveNumbers
  />
</div>

<div class="mx-auto my-5 block h-16 w-16">
  <img [src]="isOnLandingPage ? 'assets/icons/swap.png' : 'assets/icons/swap-colored.png'" alt="swap" />
</div>

<div class="rounded-tw bg-box-background p-7 shadow-xl">
  <div class="grid grid-cols-3 gap-10 max-md:grid-cols-1 max-md:gap-3">
    <app-calculator-token-button
      *ngFor="let currency of outputCurrencies"
      [currency]="currency"
      (onSelect)="onSelectOutputCurrency($event)"
      [isActive]="selectedOutputCurrency === currency"
    />
  </div>
  <input
    placeholder="0"
    class="mt-10 w-full rounded-xl border border-border-input-color bg-input-bg px-5 py-7 text-2xl font-bold leading-none max-md:mt-3"
    appOnlyNumbers
    [ngModel]="outputAmount?.toString() | mfeCustom"
    disabled
  />
  <div class="mt-10 space-y-5 max-md:mt-3">
    <p class="text-xl">
      {{ "Landing.Service_commission" | translate }}:
      <span class="text-2xl font-semibold">{{ uzsCommission | mfeCustom }} UZS</span>
    </p>
    <p class="text-xl">
      {{ "Landing.Network_commission" | translate }}:
      <span class="text-2xl font-semibold">
        {{ networkFee | mfeCustom: true }} {{ networkCurrencyName }} =
        {{ networkFeeUzs | mfeCustom }} UZS</span
      >
    </p>
    <p class="text-xl">
      {{ type === "buy" ? ("Landing.To_receive" | translate) : ("Landing.To_transfer" | translate) }}:
      <span class="text-2xl font-semibold">
        {{ inputAmount ?? 0 | mfeCustom }} {{ getCurrencyName(selectedInputCurrency) }}
      </span>
    </p>
    <p class="text-xl">
      {{ type === "buy" ? ("Landing.To_transfer" | translate) : ("Landing.To_receive" | translate) }}:
      <span class="text-2xl font-semibold">
        {{ outputAmount ?? 0 | mfeCustom }} {{ getCurrencyName(selectedOutputCurrency) }}
      </span>
    </p>
  </div>
</div>
