<app-header></app-header>

<div class="container flex flex-grow items-start">
  <app-client-sidebar />
  <main class="flex-grow py-5 md:pl-5">
    <ng-content *ngIf="!resolverLoading; else loader"></ng-content>
    <ng-template #loader>
      <app-loader />
    </ng-template>
  </main>
</div>

<app-footer></app-footer>
