import { Router } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { TimeHelperUtil } from "src/app/common/utils/time-helper.util";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { AccountRole } from "src/app/common/enums/account-role.enum";

@Injectable({ providedIn: "root" })
export class UnauthorizedGuard {
  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _router: Router
  ) {}

  public async canActivate() {
    const token = await this._localStorage.accessToken();
    const tokenLifetime = await this._localStorage.accessTokenLifetime();
    const refresh = await this._localStorage.refreshToken();
    const refreshLifetime = await this._localStorage.refreshTokenLifetime();

    if ((token == null || tokenLifetime == null) && (refresh == null || refreshLifetime == null)) {
      return true;
    }

    if (
      !TimeHelperUtil.checkLifetimeIsValid(tokenLifetime) &&
      !TimeHelperUtil.checkLifetimeIsValid(refreshLifetime)
    ) {
      return true;
    }

    const data = await this._localStorage.getUserData();
    if (data == null) {
      return true;
    }

    if (
      data.role == AccountRole.Admin ||
      data.role == AccountRole.SuperAdmin ||
      data.role == AccountRole.ObserverAmin
    ) {
      this._router.navigate([RouteConstants.admin, RouteConstants.adminHome]);
    } else {
      this._router.navigate([RouteConstants.wallet]);
    }

    return false;
  }
}
