<div *ngIf="internalError" class="bg-invalid-color text-center">
  <div class="text-text-secondary">
    {{ "Common.Unknown_error" | translate }}
  </div>
</div>

<div class="flex flex-col gap-5 max-md:gap-3">
  <div *ngIf="currentPlatformType == platformType.DEPOSITARY" class="space-y-5">
    <h2 class="title">
      {{ "Wallet.Total_balance" | translate }}:
      <span class="text-5xl font-extrabold max-md:block max-md:text-4xl">
        {{ totalUzsBalance | mfeCustom }} UZS
      </span>
    </h2>
    <p
      *ngFor="let wallet of wallets"
      class="flex flex-wrap items-center gap-1 overflow-hidden text-xl max-md:max-w-[calc(100vw-2rem)] max-md:justify-between max-md:border-b max-md:border-b-border-input-color max-md:pb-5 max-md:text-base max-md:first-of-type:border-t max-md:first-of-type:border-t-border-input-color max-md:first-of-type:pt-5"
    >
      {{ "Wallet.Address" | translate }} {{ returnNetwork(wallet.network) }}:
      <a
        [href]="returnWalletLink(wallet.network, wallet.address)"
        target="_blank"
        class="truncate text-base font-bold text-link-color max-md:order-1 max-md:w-full"
        *ngIf="wallet.address; else walletSkeleton"
      >
        {{ wallet.address }}
      </a>
      <app-copy-btn [value]="wallet.address" />
    </p>

    <ng-template #walletSkeleton>
      <app-skeleton class="h-6 w-96 max-md:w-full"></app-skeleton>
    </ng-template>
  </div>

  <div class="grid grid-cols-[auto_18.75rem] gap-5 max-md:flex max-md:flex-col max-md:gap-3">
    <div class="grid grid-cols-2 gap-5 max-md:grid-cols-1 max-md:gap-3">
      <ng-container *ngIf="wallets.length > 0; else walletsLoader">
        <ng-container *ngFor="let wallet of wallets">
          <app-balance-card *ngFor="let balance of wallet.balances" [balanceItem]="balance" [rates]="rates" />
        </ng-container>
      </ng-container>
      <ng-template #walletsLoader>
        <app-balance-card [isLoading]="true" />
        <app-balance-card [isLoading]="true" />
        <app-balance-card [isLoading]="true" />
        <app-balance-card [isLoading]="true" />
        <app-balance-card [isLoading]="true" />
        <app-balance-card [isLoading]="true" />
        <app-balance-card [isLoading]="true" />
        <app-balance-card [isLoading]="true" />
      </ng-template>
    </div>

    <div class="grid grid-rows-2 gap-5 max-md:-order-1 max-md:grid-cols-2 max-md:grid-rows-1 max-md:gap-3">
      <ng-container *ngIf="currentPlatformType == platformType.DEPOSITARY">
        <button class="btn btn_big max-md:px-3 max-md:py-2 max-md:text-sm" (click)="openSendModal()">
          {{ "Send.Title" | translate }}
          <app-svg-icon name="arrow-up" class="ml-2 w-16 max-md:w-7" />
        </button>
        <button class="btn btn_big max-md:px-3 max-md:py-2 max-md:text-sm" (click)="openReceiveModal()">
          {{ "Receive.Title" | translate }}
          <app-svg-icon name="arrow-up" class="ml-2 w-16 rotate-180 max-md:w-7" />
        </button>
      </ng-container>

      <ng-container *ngIf="currentPlatformType == platformType.SHOP">
        <a routerLink="/shop/wallet/buy" class="btn btn_big">{{ "Buy.Title" | translate }}</a>
        <a routerLink="/shop/wallet/sell" class="btn btn_big">
          {{ "Sell.Title" | translate }}
        </a>
      </ng-container>
    </div>
  </div>

  <div class="mt-10 flex flex-col gap-5 max-md:hidden">
    <h3 class="title">{{ "Transaction.Last_transactions" | translate }}:</h3>
    <ng-container *ngIf="!isTxsLoading; else txSkeleton">
      <ng-container *ngIf="hasTxs; else noTrans">
        <div class="grid grid-cols-2 gap-5">
          <app-transaction-list-item *ngFor="let item of txs" [txDto]="item" />
        </div>
      </ng-container>
      <ng-template #noTrans>
        <div class="mt-3 text-xl font-bold text-select-bg">
          {{ "Transaction.No_transactions" | translate }}
        </div>
      </ng-template>
    </ng-container>

    <ng-template #txSkeleton>
      <div class="grid grid-cols-2 gap-5">
        <app-transaction-list-item-skeleton />
        <app-transaction-list-item-skeleton />
        <app-transaction-list-item-skeleton />
        <app-transaction-list-item-skeleton />
      </div>
    </ng-template>
  </div>
</div>
