<div
  class="box px-7 py-5 max-md:max-w-[calc(100vw-2rem)] max-md:px-4 max-md:py-3"
  [ngClass]="{ 'flex items-center max-md:flex-wrap': fullSize }"
>
  <div *ngIf="fullSize" class="mr-3 h-10 w-10 max-md:h-5 max-md:w-5">
    <app-svg-icon
      name="arrow-up"
      class="stroke-select-bg"
      [ngClass]="{ 'block rotate-180': txDto.type === TxType.In }"
    />
  </div>
  <div class="flex items-center">
    <app-currency-icon [currency]="iconName" />
    <div class="ml-5 max-md:ml-2">
      <p class="font-semibold">
        {{ txDestination }}
        <span *ngIf="txDto.isCommission">({{ "Transaction.Commission_payment" | translate }})</span>
      </p>
      <p class="flex items-center gap-2 max-md:text-sm">
        <span>{{ txDate | customDate: "full" }}</span>
        <span class="h-4 w-4 rounded-full" [ngClass]="statusColor"></span>
        <span>{{ txStatus }}</span>
      </p>
      <div *ngIf="fullSize" class="text-2xl font-bold">
        {{ balanceChange }}
        {{ tokenName }}
      </div>
    </div>
    <div *ngIf="!fullSize" class="ml-auto text-2xl font-bold">
      {{ balanceChange }}
      {{ tokenName }}
    </div>
  </div>

  <div class="mt-3 space-y-2 max-md:w-full" [ngClass]="{ 'ml-auto': fullSize }">
    <p
      class="flex items-center gap-2 text-xl max-md:flex-wrap max-md:justify-between max-md:border-b max-md:border-b-border-input-color max-md:pb-2 max-md:text-sm"
    >
      <span>{{ "Transaction.From" | translate }}:</span>
      <a
        [href]="returnWalletLink(txDto.from)"
        target="_blank"
        class="ml-auto truncate font-semibold max-md:order-1 max-md:flex-grow"
      >
        {{ txDto.from }}
      </a>
      <app-copy-btn [value]="txDto.from" />
    </p>
    <p
      class="flex items-center gap-2 text-xl max-md:flex-wrap max-md:justify-between max-md:border-b max-md:border-b-border-input-color max-md:pb-2 max-md:text-sm"
    >
      <span>{{ "Transaction.To" | translate }}:</span>
      <a
        [href]="returnWalletLink(txDto.to)"
        target="_blank"
        class="ml-auto truncate font-semibold max-md:order-1 max-md:flex-grow"
      >
        {{ txDto.to }}
      </a>
      <app-copy-btn [value]="txDto.to" />
    </p>
    <p class="flex items-center gap-2 text-xl max-md:flex-wrap max-md:justify-between max-md:text-sm">
      <span>{{ "Transaction.Hash" | translate }}:</span>
      <a
        target="_blank"
        [href]="txLink"
        class="ml-auto max-w-[14.5rem] truncate font-semibold max-md:order-1 max-md:ml-0 max-md:flex-grow"
      >
        {{ txDto.hash }}
      </a>
      <app-copy-btn [value]="txDto.hash" />
    </p>
  </div>
</div>
