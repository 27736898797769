import {ValidateConstants} from "src/app/common/constants/validate.constants";
import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function kycNameValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    if (control.value == null) {
      return null;
    }

    const splitStr = control.value.split(" ");
    if (splitStr.length < 2 || splitStr.length > 3) {
      return { kycName: true };
    }

    return splitStr.every(ensureContainsOnlyLetter) ? null : { kycName: true };
  }
}

function ensureContainsOnlyLetter(str: string): boolean {
  if (str == "") {
    return false;
  }

  const regex = new RegExp(ValidateConstants.KycNamePattern);
  for (const char of str) {
    if (!regex.test(char)) {
      return false;
    }
  }

  return true;
}
