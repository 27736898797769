import { IEnv } from "../app/common/interfaces/ienv";

export const environment: IEnv = {
  domain: "https://paynet-stage.unistory.app/",
  isProduction: true,
  serverUrl: "/api/",
  tronScanApiUrl: "https://apilist.tronscanapi.com/api/",
  usdtAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
  tronGridUrl: "https://api.trongrid.io/",
  recaptchaKey: "6LclAicpAAAAACe4lcxzUzvlV6ocnPOCBqW4rgHv",
  polygonScanApiUrl: "https://api.polygonscan.com/api/",
  polygonScanApiKey: "CX1NW8C9WTE9D73T9514EBNY58IAQREI81",
  subdomain: "https://paynet-stage.unistory.app/",
  tmaUrl: "https://t.me/paynet_stage_bot/paynet_stage_app",
  tonCenterApiUrl: "https://toncenter.com/api/v3",
  tonCenterApiKey: "0e5e7f2b30cf9e99f2639280a397cd0e827784d811995608734395c43843e18e",
};
