import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.css"],
})
export class TabsComponent {
  @Input() public tabs: string[] = [];
  @Input() public currentTabNumber: number = 0;

  @Output() public onSelect = new EventEmitter();

  public translatedTabs: string[] = [];

  constructor(private readonly _translateService: TranslateService) {}

  public ngOnInit(): void {
    this._translateService.onLangChange.subscribe(() => {
      this.prepareTabNames();
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes["tabs"]) {
      this.prepareTabNames();
    }
  }

  public onTabSelect(tabNumber: number) {
    this.currentTabNumber = tabNumber;
    this.onSelect.emit(tabNumber);
  }

  private prepareTabNames() {
    const withTranslations = this.tabs.map(el => this._translateService.instant(el));
    this.translatedTabs = withTranslations;
  }
}
