<div class="flex h-screen flex-col">
  <app-header></app-header>

  <main class="bg-gradient flex w-full flex-grow items-center justify-center">
    <div class="w-[53.25rem] space-y-6 rounded-tw bg-box-background p-8 max-md:m-4">
      <h1 class="underlined-btn underlined-btn_active text-center">
        {{ "Blacklist.Account_blocked" | translate }}
      </h1>
      <p *ngIf="phoneNumber" class="text-xl">
        {{ "Blacklist.Account_blocked_text_1" | translate: { number: phoneNumber } }}
      </p>
      <p *ngIf="!phoneNumber" class="text-xl">
        {{ "Blacklist.Account_blocked_text_alternative" | translate }}
      </p>
      <p class="text-xl">
        {{ "Blacklist.Account_blocked_text_2" | translate }}
        <a href="tel:+998712023777" class="text-2xl font-bold text-accent max-md:block">+ 998 71 202-37-77</a>
      </p>
      <a routerLink="/" class="btn">{{ "Blacklist.On_main_page" | translate }}</a>
    </div>
  </main>
</div>
