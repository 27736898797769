import {Component, Input} from "@angular/core";
import {AuthLinkType} from "src/app/common/enums/auth-link-type.enum";

@Component({
  selector: 'app-base-auth-form',
  templateUrl: 'base-auth-form.component.html',
  styleUrls: ['base-auth-form.component.css']
})
export class BaseAuthFormComponent {
  @Input()
  public linkType = AuthLinkType.ToLogin;

  constructor() {
  }
}
