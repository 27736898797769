import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AddCommissionDto } from "src/app/common/DTO/commission/add-commission.dto";
import { CommissionDto } from "src/app/common/DTO/commission/commission.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { CommissionService } from "src/app/services/commission.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-commission-block",
  templateUrl: "./commission-block.component.html",
  styleUrls: ["./commission-block.component.css"],
})
export class CommissionBlockComponent implements OnInit {
  public currencies: CryptoSymbol[] = [
    CryptoSymbol.Trx,
    CryptoSymbol.Usdt,
    CryptoSymbol.Matic,
    CryptoSymbol.PolygonUsdt,
    CryptoSymbol.Ton,
    CryptoSymbol.TonUsdt,
    CryptoSymbol.Not,
    CryptoSymbol.Bitcoin,
  ];
  public selectedCurrency: CryptoSymbol = CryptoSymbol.Usdt;
  public isFormPending = false;
  public form: FormGroup;

  private allCommissions: CommissionDto[] = [];
  public isUserObserverAdmin = false;

  constructor(
    private _localStorage: LocalStorageService,
    private _modalService: NgbModal,
    private _commissionService: CommissionService,
    private _toastService: ToastService,
    private _translateService: TranslateService
  ) {
    this.form = new FormGroup({
      maxAmount: new FormControl(null, [Validators.required]),
      percent: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
    });
  }

  async ngOnInit(): Promise<void> {
    this.isUserObserverAdmin = await this._localStorage.isUserObserverAdmin();
    this.requestCommissions();
  }

  public getCurrencyName(currency: CryptoSymbol) {
    switch (currency) {
      case CryptoSymbol.Trx:
        return "TRX";
      case CryptoSymbol.Usdt:
        return "USDT (TRX)";
      case CryptoSymbol.Matic:
        return "MATIC";
      case CryptoSymbol.PolygonUsdt:
        return "USDT (Polygon)";
      case CryptoSymbol.Ton:
        return "TON";
      case CryptoSymbol.TonUsdt:
        return "USDT (TON)";
      case CryptoSymbol.Not:
        return "NOT";
      case CryptoSymbol.Bitcoin:
        return "BTC";
      default:
        return "";
    }
  }

  public getCommissions(currency: CryptoSymbol) {
    return this.allCommissions.filter(x => x.currency === currency);
  }

  public get amountError() {
    const amount = this.form.get("maxAmount");

    if (amount?.value == null) {
      return null;
    }

    if (amount?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    const cryptoCommissions = this.getCommissions(this.selectedCurrency);
    const alreadyHasAmount = cryptoCommissions.some(x => x.maxAmount == +amount?.value);

    if (alreadyHasAmount) {
      return this._translateService.instant("Admin.Home.Already_has_commission");
    }
  }

  public get percentError() {
    const percent = this.form.get("percent");

    if (percent?.value == null) {
      return null;
    }

    if (percent?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (percent?.hasError("min") || percent?.hasError("max")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
  }

  public handleOpenModal(currency: CryptoSymbol, content: any) {
    this.selectedCurrency = currency;
    this._modalService.open(content);
  }

  public handleCloseModal() {
    this._modalService.dismissAll();
  }

  public async onModalSubmit() {
    this.isFormPending = true;
    await this.createCommission();

    this.isFormPending = false;
    this.form.reset();
    this.handleCloseModal();
  }

  public async deleteCommission(id: number) {
    const result = await this._commissionService.deleteCommission(id);

    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.show(errorMsg);
      return;
    }

    this.requestCommissions();
  }

  private async requestCommissions() {
    const result = await this._commissionService.getAllCommission();
    if (result.withError) {
      return;
    }
    this.allCommissions = result.params ?? [];
  }

  private async createCommission() {
    const newCommission = new AddCommissionDto();
    newCommission.currency = this.selectedCurrency;
    newCommission.maxAmount = this.form.get("maxAmount")?.value;
    newCommission.percent = this.form.get("percent")?.value;

    const result = await this._commissionService.addCommission(newCommission);

    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.show(errorMsg);
      return;
    }

    this.requestCommissions();
  }
}
