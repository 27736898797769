<div class="relative inline-block">
  <button
    type="button"
    class="ml-1 flex h-7 w-7 shrink-0 items-center justify-center rounded-md bg-secondary p-[0.375rem] active:scale-90"
    (click)="copyValueToClipboard($event, t)"
    [ngbTooltip]="copiedMessage"
    #t="ngbTooltip"
    triggers="manual"
    container="body"
    tooltipClass="bg-accent text-text-secondary text-xs font-medium px-2 py-1 rounded z-[200]"
  >
    <img src="assets/icons/copy.svg" alt="copy" />
  </button>

  <ng-template #copiedMessage>
    <p>
      {{ "Common.Copied" | translate }}
    </p>
  </ng-template>
</div>
