<h2 class="title">{{ "Bank.Title" | translate }}:</h2>

<section class="mt-5 grid grid-cols-3 gap-5 max-md:grid-cols-1">
  <app-bank-card
    *ngFor="let card of cardList"
    [card]="card"
    (onDelete)="deleteCard(card.id)"
    class="[&>div]:h-full"
  />

  <ng-container *ngIf="canAddCard">
    <button
      *ngIf="verificationStatus === VerificationStatus.Verified; else notVerifiedAddCard"
      class="btn min-h-[9.625rem]"
      (click)="openAddBankCardModal()"
    >
      <app-svg-icon name="add-circle" class="h-16 w-16" />
    </button>
    <ng-template #notVerifiedAddCard>
      <div
        class="btn min-h-[9.625rem] cursor-default grayscale"
        [ngbTooltip]="'Profile.Add_card_verification_required' | translate"
        tooltipClass="custom-tooltip bg-text-pale"
      >
        <app-svg-icon name="add-circle" class="h-16 w-16" />
      </div>
    </ng-template>
  </ng-container>
</section>
