import { Component, Input } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ResetPasswordModalComponent } from "src/app/components/reset-password-modal/reset-password-modal.component";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-auth-modal",
  templateUrl: "./auth-modal.component.html",
  styleUrls: ["./auth-modal.component.css"],
})
export class AuthModalComponent {
  @Input() variant: "sign-in" | "sign-up" = "sign-in";
  hideOtherVariants = false;

  constructor(
    private _activeModal: NgbActiveModal,
    private _modalService: NgbModal,
    private _translateService: TranslateService,
    private _toastService: ToastService
  ) {}

  changeVariant(variant: "sign-in" | "sign-up") {
    if (this.hideOtherVariants) {
      return;
    }

    this.variant = variant;
  }

  onClose() {
    this._activeModal.close();
  }

  onHideOtherVariants(hide: boolean) {
    this.hideOtherVariants = hide;
  }

  showResetPasswordModal() {
    this.onClose();
    this._modalService.open(ResetPasswordModalComponent);
  }

  onSuccessfulLogin() {
    this.onClose();
  }

  onSuccessfulSignup() {
    this.hideOtherVariants = false;
    this.changeVariant("sign-in");
    const registrationSuccessMsg = this._translateService.instant("Login.Reg_success_message");
    this._toastService.show(registrationSuccessMsg);
  }
}
