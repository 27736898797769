import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AddTerrorDto } from "src/app/common/DTO/terrorists/add-terror.dto";
import { ScannedTerrorDto } from "src/app/common/DTO/terrorists/scanned-terror.dto";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { ModalService } from "src/app/components/_modal";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ReportsService } from "src/app/services/reports.service";
import { TerroristService } from "src/app/services/terrorist.service";
import { ToastService } from "src/app/services/toast.service";
import { BlackListService } from "../../../services/black-list.service";
import { TerroristErrorCode } from "src/app/common/enums/terrorist-error-code.enum";

@Component({
  templateUrl: "./security.component.html",
  styleUrls: ["./security.component.css"],
})
export class SecurityComponent implements OnInit {
  public ModalConstants = ModalConstants;
  public addTerroristform: FormGroup;
  public isUserObserverAdmin: boolean = false;
  public isLoading: boolean = false;

  public page = 1;
  public pageSize = 6;
  public totalCount = 0;

  public switchValues: { [userId: number]: boolean } = {};

  public scannedTerrorItems?: ScannedTerrorDto[] | null;

  constructor(
    public readonly _localStorage: LocalStorageService,
    private readonly _modalService: ModalService,
    private readonly _translateService: TranslateService,
    private readonly _terroristService: TerroristService,
    private readonly _toastService: ToastService,
    private readonly _reportService: ReportsService,
    private readonly _blackListService: BlackListService
  ) {
    this.addTerroristform = new FormGroup({
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      pinFL: new FormControl(null, [Validators.minLength(14), Validators.maxLength(14)]),
      middleName: new FormControl()
    });
  }
  
  async ngOnInit(): Promise<void> {
    this.isUserObserverAdmin = await this._localStorage.isUserObserverAdmin();
  }

  public handleOpenModal() {
    this._modalService.open(ModalConstants.AddTerrorist);
  }

  public handleDownloadTerroristReportButtonClick() {
    this._reportService.getTerroristsDataReport();
  }

  public async onModalSubmit() {
    if (this.addTerroristform.invalid) {
      return;
    }

    const newTerror: AddTerrorDto = {
      firstName : this.addTerroristform.get("firstName")?.value.trim(),
      lastName: this.addTerroristform.get("lastName")?.value.trim(),
      pinFL: this.addTerroristform.get("pinFL")?.value ? this.addTerroristform.get("pinFL")?.value.trim() : null,
      middleName: this.addTerroristform.get("middleName")?.value ? this.addTerroristform.get("middleName")?.value.trim() : null
    }

    const res = await this._terroristService.addTerrorist(newTerror);
    if (res.withError) {
      switch(res.errorCode) {
        case TerroristErrorCode.AlreadyExist:
          this._toastService.show(this._translateService.instant("Admin.Security.Terrorists.Terror_already_exist"));
          this._modalService.close(this.ModalConstants.AddTerrorist);
          break;
          
        default:
          this._toastService.show(this._translateService.instant("Common.Unknown_error"));
          this._modalService.close(this.ModalConstants.AddTerrorist);
          break;
      }
      return;
    }

    this._modalService.close(this.ModalConstants.AddTerrorist);
    const successMessage = this._translateService.instant("Admin.Security.Terrorists.Success_add");
    this._toastService.show(successMessage);
  }

  public async onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (!file) return;

    this.isLoading = true;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const response = await this._terroristService.uploadCsvFile(formData);

    if (response.withError) {
      this._toastService.show(this._translateService.instant("Admin.Security.Terrorists.Fail_import"));
      event.target.value = '';
      this.isLoading = false;
      return;
    }

    if (response.params != null && response.params < 1) {
      this._toastService.show(this._translateService.instant("Admin.Security.Terrorists.No_record_saved"));
    } else {
      this._toastService.show(this._translateService.instant("Admin.Security.Terrorists.Success_import"));
    }

    event.target.value = '';
    this.isLoading = false;
  }
  
  public async requestScannedTerrors() {
    this.isLoading = true;
    
    const response = await this._terroristService.scanTerrorsWithKyc(this.pageSize, this.page);
    this.scannedTerrorItems = response.params?.items;

    this.scannedTerrorItems?.forEach(item => {
      this.switchValues[item.userId] = item.isUserBlocked;
    });

    this.totalCount = response.params!.totalCount;

    this._modalService.open(ModalConstants.AdminGetScannedTerrors);

    this.isLoading = false;
  }

  public get addTerrorNameErrorMessage() {
    const field = this.addTerroristform.get("name");

    if (!field?.touched) {
      return null;
    }

    if (field.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    return null;
  }

  public get addTerrorLastNameErrorMessage() {
    const field = this.addTerroristform.get("lastName");

    if (!field?.touched) {
      return null;
    }

    if (field.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    return null;
  }

  public get addTerrorPinFLErrorMessage() {
    const field = this.addTerroristform.get("pinFL");

    if (!field?.touched) {
      return null;
    }

    if(field.hasError("minlength") || field.hasError("maxlength")){
      return this._translateService.instant("Admin.Security.Terrorists.PinFL_error");
    }

    return null;
  }

  public async onSwitchChange(userId: number){
    const switchValue = this.switchValues[userId];

    if(switchValue) {
      await this.blockUser(userId);
    } else {
      await this.unblockUser(userId);
    }
  }

  private async blockUser(userId: number) {
    await this._blackListService.blockUser(userId, false);
  }

  private async unblockUser(userId: number) {
    await this._blackListService.unblockUser(userId);
  }
}