<button
  *ngIf="step === 'otp'"
  class="absolute left-2 top-2 rounded-md bg-gray-25 dark:bg-slate-800 max-md:left-0 max-md:top-0"
  (click)="onBack()"
>
  <app-svg-icon name="actions/back" class="block h-8 w-8 text-black dark:text-white" />
</button>

<button (click)="onClose()" class="absolute right-2 top-2 max-md:right-0 max-md:top-0">
  <app-svg-icon name="actions/close" class="block h-8 w-8 text-black dark:text-white" />
</button>

<div class="px-15 py-7 max-md:p-0">
  <ng-container [ngSwitch]="step">
    <ng-template ngSwitchCase="input">
      <div class="md:px-6">
        <h1 class="mb-6 text-center text-xl font-semibold dark:text-white">Войти</h1>

        <form class="flex flex-col gap-y-6" [formGroup]="inputForm" (ngSubmit)="submitCredentials()">
          <div class="flex flex-col gap-y-4 rounded-2xl bg-gray-25 p-4 dark:bg-slate-800">
            <app-input
              id="phoneNumber"
              type="phone"
              formControlName="phoneNumber"
              [title]="'Login.Phone_number' | translate"
              placeholder="11 222 33 44"
              mask="00 000 00 00"
              [errorMsg]="phoneNumberError"
              [autofocus]="true"
            />

            <app-input
              id="psw"
              type="password"
              formControlName="psw"
              [title]="'Login.Password' | translate"
              placeholder="********"
              [errorMsg]="pswError"
              [withPasswordToggle]="true"
            />
          </div>

          <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>

          <button
            type="submit"
            class="btn-primary btn-medium w-full"
            [disabled]="inputForm.invalid || isPending"
          >
            {{ "Login.Enter" | translate }}
          </button>
        </form>
      </div>
    </ng-template>

    <ng-template ngSwitchCase="otp">
      <form [formGroup]="otpForm" (ngSubmit)="submitOtp()">
        <h1 class="mb-2 text-center text-xl font-semibold">Введите код</h1>
        <p class="mb-6 text-center font-medium dark:text-slate-200">
          Для подтверждения телефона отправили код на номер<br />+998{{ phoneNumber }}
        </p>

        <div class="flex flex-col gap-y-4 rounded-2xl bg-gray-25 p-4 dark:bg-slate-800">
          <app-input
            id="otp"
            type="number"
            formControlName="code"
            [title]="'Login.Sms_code' | translate"
            placeholder="------"
            [errorMsg]="codeError"
            [autocomplete]="'off'"
            [autofocus]="true"
            [withTimer]="true"
            [timer]="otpTimer"
            (onRefresh)="sendConfirmCode()"
            max="6"
          />
        </div>

        <re-captcha
          *ngIf="otpInputCount >= validateConstants.MaxOtpTries"
          (resolved)="captchaResolved($event)"
        ></re-captcha>

        <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>

        <div class="mt-6 flex items-stretch gap-6">
          <button
            type="submit"
            class="btn-primary btn-medium w-full"
            [disabled]="otpForm.invalid || isPending"
          >
            {{ "Login.Enter" | translate }}
          </button>
        </div>
      </form>
    </ng-template>
  </ng-container>

  <div *ngIf="!hideOtherVariants" class="mt-6 flex flex-col gap-y-1">
    <button class="btn-text" (click)="showSignUpModal()">Зарегистрироваться</button>
    <button class="btn-text" (click)="showResetPasswordModal()">Забыли пароль?</button>
  </div>
</div>
