import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { KycDto } from "src/app/common/DTO/kyc/kyc.dto";
import { VerdictKycDto } from "src/app/common/DTO/kyc/verdict-kyc.dto";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { BlackListService } from "src/app/services/black-list.service";
import { EnvService } from "src/app/services/env.service";
import { KycService } from "src/app/services/kyc.service";

@Component({
  templateUrl: "./kyc-record.component.html",
  styleUrls: ["./kyc-record.component.css"],
})
export class KycRecordComponent implements OnInit {
  private id: number = 0;
  public kycDto: KycDto = new KycDto();
  public kycImageSrc: string = "";
  public isVerdictError: boolean = false;
  public rejectionReason: string = "";

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _kycService: KycService,
    private readonly _router: Router,
    private readonly _envService: EnvService,
    private readonly _blackListService: BlackListService
  ) {
    this._route.params.subscribe(params => {
      this.id = params["id"];
    });
  }

  async ngOnInit(): Promise<void> {
    const res = await this._kycService.getKycById(this.id);

    if (res.withError) {
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.adminKyc}`);
      return;
    }

    this.kycDto = res.params!;
  }

  public async handleApprove() {
    await this.handleVerdict(true, null);
  }

  public async handleReject() {
    await this.handleVerdict(false, this.rejectionReason);
  }

  public async handleBlock() {
    const res = await this._blackListService.blockUser(this.kycDto.userId, true);

    if (res && res.withError) {
      this.isVerdictError = true;
      return;
    }

    this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.adminKyc}`);
  }

  private async handleVerdict(isApprove: boolean, reason: string | null) {
    const verdictBody = new VerdictKycDto(this.id, isApprove, reason);

    const res = await this._kycService.verdictKyc(verdictBody);

    if (res && res.withError) {
      this.isVerdictError = true;
      return;
    }

    this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.adminKyc}`);
  }
}
