export class HttpResultDto<TEnum, TParam> {
  public errorCode: TEnum | null;
  public params: TParam | null;
  public withError: boolean;

  public constructor(withError: boolean, errorCode: TEnum | null = null, params: TParam | null = null) {
    this.withError = withError;
    this.params = params;
    this.errorCode = errorCode;
  }
}
