import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ControlPanelSettingsDto } from "src/app/common/DTO/control-panel/control-panel-settings.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { ControlPanelService } from "src/app/services/control-panel.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  templateUrl: "./admin-home.component.html",
  styleUrls: ["./admin-home.component.css"],
})
export class AdminHomeComponent implements OnInit {
  public functionalityForm: FormGroup;
  public currencies: CryptoSymbol[] = [];
  public isUserObserverAdmin: boolean = false;

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _controlPanelService: ControlPanelService,
    private readonly _toastService: ToastService,
    private readonly _translateService: TranslateService,
    private readonly _formBuilder: FormBuilder
  ) {
    this.functionalityForm = this._formBuilder.group({});
  }

  async ngOnInit(): Promise<void> {
    await this.getFunctionality();

    Object.keys(this.functionalityForm.controls).forEach(key => {
      this.functionalityForm.controls[key].valueChanges.subscribe(() => {
        this.updateFunctionality(key);
      });
    });
  }

  public getCurrencyName(currency: CryptoSymbol): string {
    return getCurrencyName(currency);
  }

  private async getFunctionality() {
    const res = await this._controlPanelService.getControlPanel();
    if (res.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.show(errorMsg);
      return;
    }

    this.isUserObserverAdmin = await this._localStorage.isUserObserverAdmin();
    this.currencies = res.params!.map(x => x.currency);
    res.params!.forEach(element => {
      this.functionalityForm.addControl(
        element.currency.toString(),
        this._formBuilder.group({
          canBuy: new FormControl({
            value: element.canBuy,
            disabled: this.isUserObserverAdmin,
          }),
          canSell: new FormControl({
            value: element.canSell,
            disabled: this.isUserObserverAdmin,
          }),
          canSend: new FormControl({
            value: element.canSend,
            disabled: this.isUserObserverAdmin,
          }),
        })
      );
    });
  }

  private async updateFunctionality(control: string) {
    const form = this.functionalityForm.get(control);
    const currency = this.currencies.find(x => x.toString() === control);

    if (!form || !currency) {
      return;
    }

    const dto: ControlPanelSettingsDto = {
      currency: currency,
      canBuy: form.value.canBuy,
      canSell: form.value.canSell,
      canSend: form.value.canSend,
    };

    const res = await this._controlPanelService.updateControlPanel(dto);
    if (res.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.show(errorMsg);
      return;
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.show(successMsg);
    }
  }
}
