import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ResetPasswordDto } from "src/app/common/DTO/auth/reset-password.dto";
import { Constants } from "src/app/common/constants/constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { AuthErrorCode } from "src/app/common/enums/auth-error-code.enum";
import { lengthEqualValidator } from "src/app/common/validators/length-equal.validator";
import { matchValidator } from "src/app/common/validators/match.validator";
import { numericStrValidator } from "src/app/common/validators/numeric-str.validator";
import { validCodeRangeValidator } from "src/app/common/validators/valid-code-range.validator";
import { AuthService } from "src/app/services/auth.service";
import { EnvService } from "src/app/services/env.service";

@Component({
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent {
  public smsForm: FormGroup;
  public form: FormGroup;
  public step: "sms" | "password" = "sms";
  public businessError: string | null = null;
  public isPending = false;
  public otpInputCount: number = 0;
  public validateConstants = ValidateConstants;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _authService: AuthService,
    private readonly _router: Router
  ) {
    this.smsForm = new FormGroup({
      phoneNumber: new FormControl(null, [
        Validators.required,
        numericStrValidator(),
        lengthEqualValidator(ValidateConstants.PhoneLengthWithoutCode),
      ]),
    });

    this.form = new FormGroup(
      {
        psw: new FormControl(null, [
          Validators.required,
          Validators.minLength(ValidateConstants.MinPswLength),
          Validators.maxLength(ValidateConstants.MaxPswLength),
          Validators.pattern(ValidateConstants.PswPattern),
        ]),
        pswRepeat: new FormControl(null, [Validators.required]),
        code: new FormControl(null, [Validators.required, numericStrValidator(), validCodeRangeValidator()]),
      },
      { validators: matchValidator("psw", "pswRepeat") }
    );
  }

  public get phoneNumberError() {
    const phoneNumber = this.smsForm.get("phoneNumber");

    if (phoneNumber?.value == null) {
      return null;
    }

    if (phoneNumber.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (phoneNumber.hasError("lengthEqual")) {
      return this._translateService.instant("Login.Phone_length_error");
    }

    return null;
  }

  public get pswError() {
    const psw = this.form.get("psw");

    if (psw?.value == null) {
      return null;
    }

    if (psw.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (psw.hasError("minlength") || psw.hasError("maxlength")) {
      return this._translateService.instant("Login.Password_length_error");
    }

    if (psw.hasError("pattern")) {
      return this._translateService.instant("Login.Password_validation_error");
    }

    return null;
  }

  public get pswRepeatError() {
    const pswRepeat = this.form.get("pswRepeat");

    if (pswRepeat?.value == null) {
      return null;
    }

    if (pswRepeat.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (pswRepeat.hasError("match")) {
      return this._translateService.instant("Login.Password_not_match");
    }

    return null;
  }

  public get codeError() {
    const code = this.form.get("code");

    if (code?.value == null) {
      return null;
    }

    if (code.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (code.hasError("codeOutOfRange")) {
      return this._translateService.instant("Common.Field_not_correct");
    }

    return null;
  }

  public async onPhoneSubmit() {
    this.isPending = true;
    this.businessError = null;
    const phoneNumber = Constants.UzsPhoneCode + this.smsForm.get("phoneNumber")?.value;

    try {
      const res = await this._authService.sendPasswordResetCode({ phoneNumber });
      if (res.withError) {
        this.businessError = this.errorParser(res.errorCode);
      } else {
        this.step = "password";
        this.businessError = null;
      }
    } catch (error) {
      this.businessError = this._translateService.instant("Common.Unknown_error");
    } finally {
      this.isPending = false;
    }
  }

  public async onSubmit() {
    this.isPending = true;
    this.businessError = null;

    const dto = new ResetPasswordDto();
    dto.phoneNumber = Constants.UzsPhoneCode + this.smsForm.get("phoneNumber")?.value;
    dto.code = this.form.get("code")?.value;
    dto.newPassword = this.form.get("psw")?.value;

    try {
      // Check if captcha is not bypassed
      if(this.otpInputCount >= ValidateConstants.MaxOtpTries) {
        this.businessError = this._translateService.instant("Login.Pass_captcha");
        return;
      }

      const res = await this._authService.resetPassword(dto);
      if (res.withError) {
        this.businessError = this.errorParser(res.errorCode);
        // If user inputed wrong OTP, then increment OTP inputs counter
        if(res.errorCode == AuthErrorCode.IncorrectCode) {
          this.otpInputCount++;
        }
      } else {
        this._router.navigateByUrl("/auth/login");
      }
    } catch (error) {
      this.businessError = this._translateService.instant("Common.Unknown_error");
    } finally {
      this.isPending = false;
    }
  }

  public resolved(captchaResponse: string) {
    if(captchaResponse && captchaResponse != "") {
      this.otpInputCount = 0;
    }
  }

  private errorParser(errorCode: AuthErrorCode | null) {
    switch (errorCode) {
      case AuthErrorCode.CodeRequestLimitExceeds:
        return this._translateService.instant("Login.Code_request_limit_exceeds");
      case AuthErrorCode.IncorrectCode:
        return this._translateService.instant("Login.Wrong_code_or_expired");
      case AuthErrorCode.InternalError:
        return this._translateService.instant("Common.Unknown_error");
      case AuthErrorCode.UserNotFound:
        return this._translateService.instant("Login.Phone_user_not_found");
      case AuthErrorCode.IncorrectPhoneOrPsw:
        return this._translateService.instant("Login.Wrong_phone_or_pass");
      case AuthErrorCode.DisallowSmsSentTo:
        return this._translateService.instant("Login.Disallow_sms_sent");
      default:
        return this._translateService.instant("Common.Unknown_error");
    }
  }
}
