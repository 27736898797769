import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { Constants } from "src/app/common/constants/constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { EnvService } from "src/app/services/env.service";
import { LimitPanelSettingsDto } from "src/app/common/DTO/limit-panel/limit-panel-settings.dto";
import { LimitPanelErrorCode } from "src/app/common/enums/limit-panel-error-code.enum";

@Injectable({
  providedIn: "root",
})
export class LimitPanelService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(
    private readonly _http: HttpClient,
    private readonly _envService: EnvService
  ) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  public async getLimits() {
    const uri = `${this._envService.serverUrl}${UrnConstants.LimitPanelAll}`;
    const result = new HttpResultDto<LimitPanelErrorCode, LimitPanelSettingsDto[]>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri, this._baseReqOpts))) as ApiResponseDto<
        LimitPanelSettingsDto[]
      >;
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }
    return result;
  }

  public async updateLimits(dto: LimitPanelSettingsDto) {
    const uri = `${this._envService.serverUrl}${UrnConstants.LimitPanel}`;
    const result = new HttpResultDto<LimitPanelErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.put(uri, dto, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): LimitPanelErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return LimitPanelErrorCode.InternalError;
      case Constants.Unauthorized:
        return LimitPanelErrorCode.Unauthorized;
      default:
        return LimitPanelErrorCode.InternalError;
    }
  }
}
