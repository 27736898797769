import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbDatepicker, NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Constants } from "src/app/common/constants/constants";
import { Network } from "src/app/common/enums/network.enum";
import { PlatformType } from "src/app/common/enums/platform-type.enum";
import { TxPageType } from "src/app/common/enums/tx-page-type.enum";
import { Transaction } from "src/app/common/models/transaction";
import { UserResolver } from "src/app/common/resolvers/user.resolver";
import { BitcoinService } from "src/app/services/bitcoin.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PolygonService } from "src/app/services/polygon.service";
import { TonService } from "src/app/services/ton.service";
import { TronService } from "src/app/services/tron.service";

@Component({
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.css"],
})
export class TransactionsComponent implements OnInit {
  public internalError = false;
  public platformType = PlatformType;

  private txPageType = TxPageType.Trx;

  private basePgSize = 10;
  private bitcoinPgSize = 25; // Because of the fixed transactions count from API

  public startDate: NgbDateStruct | undefined = undefined;
  public endDate: NgbDateStruct | undefined = undefined;

  public currentMobileCalendar: "start" | "end" = "start";

  public isLoading: boolean = true;

  private trxWallet = "";
  private polygonWallet = "";
  private tonWallet = "";
  private bitcoinWallet = "";

  private trxTxs: Transaction[] = [];
  private trxFingerprintHistory: any[] = [null];

  private usdtTxs: Transaction[] = [];
  private usdtFingerprintHistory: any[] = [null];

  public maticTxs: Transaction[] = [];
  public maticTxsPage = 1;

  public polygonUsdtTxs: Transaction[] = [];
  public polygonUsdtTxsPage = 1;

  public tonTxs: Transaction[] = [];
  public tonFingerprintHistory: (string | null)[] = [null];

  public notTxs: Transaction[] = [];
  public notFingerprintHistory: (string | null)[] = [null];

  public tonUsdtTxs: Transaction[] = [];
  public tonUsdtFingerprintHistory: (string | null)[] = [null];

  public bitcoinTxs: Transaction[] = [];
  public bitcoinTxIdHistory: (string | null)[] = [null];

  constructor(
    private _tronService: TronService,
    public _localStorage: LocalStorageService,
    private _polygonService: PolygonService,
    private _tonService: TonService,
    private _bitcoinService: BitcoinService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _modalService: NgbModal,
    private _userResolver: UserResolver
  ) {}

  public async ngOnInit(): Promise<void> {
    const data = this._userResolver.user;

    if (data) {
      this.trxWallet = data.wallets.find(x => x.network === Network.Tron)?.address ?? "";
      this.polygonWallet = data.wallets.find(x => x.network === Network.Polygon)?.address ?? "";
      this.tonWallet = data.wallets.find(x => x.network === Network.Ton)?.address ?? "";
      this.bitcoinWallet = data.wallets.find(x => x.network === Network.Bitcoin)?.address ?? "";
    }

    this._route.queryParams.subscribe(async params => {
      if (params["type"]) {
        this.txPageType = Number(params["type"]) as TxPageType;
        this.resetPagination();
        this.loadTxs();
      }
    });

    await this.loadTxs();
  }

  public get txs(): Transaction[] {
    switch (this.txPageType) {
      case TxPageType.Usdt:
        return this.usdtTxs;
      case TxPageType.Trx:
        return this.trxTxs;
      case TxPageType.Matic:
        return this.maticTxs;
      case TxPageType.PolygonUsdt:
        return this.polygonUsdtTxs;
      case TxPageType.Ton:
        return this.tonTxs;
      case TxPageType.Not:
        return this.notTxs;
      case TxPageType.TonUsdt:
        return this.tonUsdtTxs;
      case TxPageType.Bitcoin:
        return this.bitcoinTxs;
      default:
        throw new Error(`txPageType value ${this.txPageType} out of range`);
    }
  }

  public get datesArray(): string[] {
    return Object.keys(this.txs);
  }

  public get hasPrevPage(): boolean {
    switch (this.txPageType) {
      case TxPageType.Trx:
        return this.trxFingerprintHistory.length > 2;
      case TxPageType.Usdt:
        return this.usdtFingerprintHistory.length > 2;
      case TxPageType.Matic:
        return this.maticTxsPage > 1;
      case TxPageType.PolygonUsdt:
        return this.polygonUsdtTxsPage > 1;
      case TxPageType.Ton:
        return this.tonFingerprintHistory.length > 2;
      case TxPageType.TonUsdt:
        return this.tonUsdtFingerprintHistory.length > 2;
      case TxPageType.Not:
        return this.notFingerprintHistory.length > 2;
      case TxPageType.Bitcoin:
        return this.bitcoinTxIdHistory.length > 2;
      default:
        return false;
    }
  }

  public get hasNextPage(): boolean {
    switch (this.txPageType) {
      case TxPageType.Trx:
        return this.trxFingerprintHistory.at(-1) !== null;
      case TxPageType.Usdt:
        return this.usdtFingerprintHistory.at(-1) !== null;
      case TxPageType.Matic:
        return this.maticTxs.length === this.basePgSize;
      case TxPageType.PolygonUsdt:
        return this.polygonUsdtTxs.length === this.basePgSize;
      case TxPageType.Ton:
        return this.tonFingerprintHistory.at(-1) !== null;
      case TxPageType.TonUsdt:
        return this.tonUsdtFingerprintHistory.at(-1) !== null;
      case TxPageType.Not:
        return this.notFingerprintHistory.at(-1) !== null;
      case TxPageType.Bitcoin:
        return this.bitcoinTxIdHistory.at(-1) !== null;
      default:
        return false;
    }
  }

  public onPrevPage() {
    switch (this.txPageType) {
      case TxPageType.Trx:
        this.trxFingerprintHistory.splice(-2);
        this.loadTrxTxs();
        break;
      case TxPageType.Usdt:
        this.usdtFingerprintHistory.splice(-2);
        this.loadUsdtTxs();
        break;
      case TxPageType.Matic:
        this.maticTxsPage--;
        this.loadMaticTxs();
        break;
      case TxPageType.PolygonUsdt:
        this.polygonUsdtTxsPage--;
        this.loadPolygonUsdtTxs();
        break;
      case TxPageType.Ton:
        this.tonFingerprintHistory.splice(-2);
        this.loadTonTxs();
        break;
      case TxPageType.TonUsdt:
        this.tonUsdtFingerprintHistory.splice(-2);
        this.loadTonUsdtTxs();
        break;
      case TxPageType.Not:
        this.notFingerprintHistory.splice(-2);
        this.loadNotTxs();
        break;
      case TxPageType.Bitcoin:
        this.bitcoinTxIdHistory.splice(-2);
        this.loadBitcoinTxs();
        break;
    }
  }

  public onNextPage() {
    switch (this.txPageType) {
      case TxPageType.Trx:
        this.loadTrxTxs();
        break;
      case TxPageType.Usdt:
        this.loadUsdtTxs();
        break;
      case TxPageType.Matic:
        this.maticTxsPage++;
        this.loadMaticTxs();
        break;
      case TxPageType.PolygonUsdt:
        this.polygonUsdtTxsPage++;
        this.loadPolygonUsdtTxs();
        break;
      case TxPageType.Ton:
        this.loadTonTxs();
        break;
      case TxPageType.TonUsdt:
        this.loadTonUsdtTxs();
        break;
      case TxPageType.Not:
        this.loadNotTxs();
        break;
      case TxPageType.Bitcoin:
        this.loadBitcoinTxs();
        break;
    }
  }

  public switchTxType(tabNumber: number) {
    const type = Array.from(Constants.TokenSwitchValues.keys())[tabNumber];
    this._router.navigate([], { relativeTo: this._route, queryParams: { type } });
    this.resetPagination();
  }

  public get shouldRenderDateFilters() {
    return (
      this.txPageType !== TxPageType.Bitcoin &&
      this.txPageType !== TxPageType.Trx &&
      this.txPageType !== TxPageType.Usdt
    );
  }

  public get weekdays() {
    return ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];
  }

  public navigateToDate(datepicker: NgbDatepicker, number: number) {
    const { state, calendar } = datepicker;
    datepicker.navigateTo(calendar.getNext(state.firstDate, "m", number));
  }

  public get tokenSwitchValues(): string[] {
    return Array.from(Constants.TokenSwitchValues.values()).slice(0, -2);
  }

  public get currentSwitchNumber(): number {
    const index = Array.from(Constants.TokenSwitchValues.keys()).indexOf(this.txPageType);
    return index === -1 ? 0 : index;
  }

  public get hasTxs() {
    return Object.keys(this.txs).length > 0;
  }

  public get minEndDate(): NgbDateStruct {
    if (this.startDate) {
      return this.startDate as NgbDateStruct;
    }
    const nowDate = new Date();
    return { year: nowDate.getFullYear() - 10, month: nowDate.getMonth() + 1, day: nowDate.getDate() };
  }

  public handleDateFilterChange() {
    this.resetPagination();
    this.loadTxs();
  }

  public resetDates() {
    this.startDate = undefined;
    this.endDate = undefined;
    this.resetPagination();
    this.loadTxs();
  }

  private resetPagination() {
    this.trxFingerprintHistory = [null];
    this.usdtFingerprintHistory = [null];
    this.maticTxsPage = 1;
    this.polygonUsdtTxsPage = 1;
    this.tonFingerprintHistory = [null];
    this.notFingerprintHistory = [null];
    this.tonUsdtFingerprintHistory = [null];
    this.bitcoinTxIdHistory = [null];
  }

  public openModal(content: any) {
    this._modalService.open(content);
  }

  public closeModalAndResetDates(modal: any) {
    this.resetDates();
    modal?.close?.();
  }

  public closeModalAndApplyDates(modal: any) {
    this.handleDateFilterChange();
    modal?.close?.();
  }

  private prepareDate(date?: NgbDateStruct | string): Date | undefined {
    if (!date || typeof date === "string") {
      return undefined;
    }
    return new Date(date.year, date.month - 1, date.day);
  }

  private prepareTimestamp(date?: Date): number | undefined {
    if (!date) {
      return undefined;
    }
    return date.getTime() / 1000;
  }

  public async loadTxs() {
    this.isLoading = true;
    switch (this.txPageType) {
      case TxPageType.Matic:
        await this.loadMaticTxs();
        break;
      case TxPageType.PolygonUsdt:
        await this.loadPolygonUsdtTxs();
        break;
      case TxPageType.Usdt:
        await this.loadUsdtTxs();
        break;
      case TxPageType.Trx:
        await this.loadTrxTxs();
        break;
      case TxPageType.Ton:
        await this.loadTonTxs();
        break;
      case TxPageType.TonUsdt:
        await this.loadTonUsdtTxs();
        break;
      case TxPageType.Not:
        await this.loadNotTxs();
        break;
      case TxPageType.Bitcoin:
        await this.loadBitcoinTxs();
        break;
      default:
        break;
    }
    this.isLoading = false;
  }

  private async loadTrxTxs() {
    let nextPageFingerprint;
    if (this.trxFingerprintHistory.length <= 1) {
      nextPageFingerprint = null;
    } else {
      nextPageFingerprint = this.trxFingerprintHistory[this.trxFingerprintHistory.length - 1];
    }

    const startDate = this.prepareDate(this.startDate);
    const endDate = this.prepareDate(this.endDate);

    const trxTxsRes = await this._tronService.getTrxTransactions(
      this.trxWallet,
      true,
      nextPageFingerprint,
      startDate?.toISOString?.(),
      endDate?.toISOString?.()
    );
    this.trxFingerprintHistory.push(trxTxsRes.fingerPrint);
    this.trxTxs = trxTxsRes.items;
  }

  private async loadUsdtTxs() {
    let nextPageFingerprint;
    if (this.usdtFingerprintHistory.length <= 1) {
      nextPageFingerprint = null;
    } else {
      nextPageFingerprint = this.usdtFingerprintHistory[this.usdtFingerprintHistory.length - 1];
    }

    const startDate = this.prepareDate(this.startDate);
    const endDate = this.prepareDate(this.endDate);

    const usdtTxsRes = await this._tronService.getUsdtTransactions(
      this.trxWallet,
      true,
      nextPageFingerprint,
      startDate?.toISOString?.(),
      endDate?.toISOString?.()
    );
    this.usdtFingerprintHistory.push(usdtTxsRes.fingerPrint);
    this.usdtTxs = usdtTxsRes.items;
  }

  private async loadMaticTxs() {
    const startDate = this.prepareDate(this.startDate);
    const endDate = this.prepareDate(this.endDate);

    const maticTxsRes = await this._polygonService.getMaticTransactions(
      this.polygonWallet,
      this.maticTxsPage,
      this.prepareTimestamp(startDate),
      this.prepareTimestamp(endDate)
    );
    this.maticTxs = maticTxsRes;
  }

  private async loadPolygonUsdtTxs() {
    const startDate = this.prepareDate(this.startDate);
    const endDate = this.prepareDate(this.endDate);

    const usdtTxsRes = await this._polygonService.getUsdtTransactions(
      this.polygonWallet,
      this.polygonUsdtTxsPage,
      this.prepareTimestamp(startDate),
      this.prepareTimestamp(endDate)
    );
    this.polygonUsdtTxs = usdtTxsRes;
  }

  private async loadTonTxs() {
    let nextPageFingerprint: number | null = null;
    if (this.tonFingerprintHistory.length > 1) {
      const fingerprint = this.tonFingerprintHistory.at(-1) ?? null;
      nextPageFingerprint = fingerprint !== null ? Number(fingerprint) : null;
    }

    const startDate = this.prepareDate(this.startDate);
    const endDate = this.prepareDate(this.endDate);

    const tonTxsRes = await this._tonService.getTonTransactions(
      this.tonWallet,
      nextPageFingerprint,
      this.prepareTimestamp(startDate),
      this.prepareTimestamp(endDate)
    );
    this.tonFingerprintHistory.push(tonTxsRes.fingerPrint);
    this.tonTxs = tonTxsRes.items;
  }

  private async loadTonUsdtTxs() {
    let nextPageFingerprint: number | null = null;
    if (this.tonUsdtFingerprintHistory.length > 1) {
      const fingerprint = this.tonUsdtFingerprintHistory.at(-1) ?? null;
      nextPageFingerprint = fingerprint !== null ? Number(fingerprint) : null;
    }

    const startDate = this.prepareDate(this.startDate);
    const endDate = this.prepareDate(this.endDate);

    const tonUsdtTxsRes = await this._tonService.getUsdtTransactions(
      this.tonWallet,
      nextPageFingerprint,
      this.prepareTimestamp(startDate),
      this.prepareTimestamp(endDate)
    );
    this.tonUsdtFingerprintHistory.push(tonUsdtTxsRes.fingerPrint);
    this.tonUsdtTxs = tonUsdtTxsRes.items;
  }

  private async loadNotTxs() {
    let nextPageFingerprint: number | null = null;
    if (this.notFingerprintHistory.length > 1) {
      const fingerprint = this.notFingerprintHistory.at(-1) ?? null;
      nextPageFingerprint = fingerprint !== null ? Number(fingerprint) : null;
    }

    const startDate = this.prepareDate(this.startDate);
    const endDate = this.prepareDate(this.endDate);

    const notTxsRes = await this._tonService.getNotTransactions(
      this.tonWallet,
      nextPageFingerprint,
      this.prepareTimestamp(startDate),
      this.prepareTimestamp(endDate)
    );
    this.notFingerprintHistory.push(notTxsRes.fingerPrint);
    this.notTxs = notTxsRes.items;
  }

  private async loadBitcoinTxs() {
    let nextPageTxId: string | null = null;
    if (this.bitcoinTxIdHistory.length > 1) {
      nextPageTxId = this.bitcoinTxIdHistory.at(-1) ?? null;
    }
    const bitcoinTxsRes = await this._bitcoinService.getBitcoinTransactions(this.bitcoinWallet, nextPageTxId);
    const lastTxId = bitcoinTxsRes.at(-1)?.id;
    if (bitcoinTxsRes.length >= this.bitcoinPgSize && lastTxId) {
      this.bitcoinTxIdHistory.push(lastTxId);
    }
    this.bitcoinTxs = bitcoinTxsRes;
  }
}
