import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EnvService } from "src/app/services/env.service";
import { Constants } from "src/app/common/constants/constants";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { PagedListDto } from "src/app/common/DTO/paged-list.dto";
import { TxDto } from "src/app/common/DTO/txs/tx.dto";
import { TxErrorCode } from "src/app/common/enums/tx-error-code.enum";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { firstValueFrom } from "rxjs";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";

@Injectable({
  providedIn: "root",
})
export class TxService {
  private readonly _baseReqOpts: { headers: HttpHeaders } = {
    headers: new HttpHeaders(Constants.JsonContentTypeHeader),
  };

  constructor(private readonly _http: HttpClient, private readonly _envService: EnvService) {}

  public async getAllMy(
    size: number,
    page: number
  ): Promise<HttpResultDto<TxErrorCode, PagedListDto<TxDto> | null>> {
    const params = `?size=${size}&index=${page}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.GetAllMyTxs}${params}`;

    const result = new HttpResultDto<TxErrorCode, PagedListDto<TxDto> | null>(false);
    try {
      const res = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<PagedListDto<TxDto>>;
      res.data.items = this.formattingTxDtoDate(res.data.items);
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async getById(id: number): Promise<HttpResultDto<TxErrorCode, TxDto | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetTxById}/${id}`;
    const result = new HttpResultDto<TxErrorCode, TxDto | null>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<TxDto>;
      res.data.createdAt = new Date(res.data.createdAt);
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async getAllUserTxs(
    userId: number,
    size: number,
    index: number
  ): Promise<HttpResultDto<TxErrorCode, PagedListDto<TxDto> | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetAllTxs}/${userId}?size=${size}&index=${index}`;
    const result = new HttpResultDto<TxErrorCode, PagedListDto<TxDto> | null>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<PagedListDto<TxDto>>;
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async getAllTxs(
    size: number,
    index: number
  ): Promise<HttpResultDto<TxErrorCode, PagedListDto<TxDto> | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetAllTxs}?size=${size}&index=${index}`;
    const result = new HttpResultDto<TxErrorCode, PagedListDto<TxDto> | null>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<PagedListDto<TxDto>>;
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async getWaitingTxs(size: number, index: number) {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetWaitingTxs}?size=${size}&index=${index}`;
    const result = new HttpResultDto<TxErrorCode, PagedListDto<TxDto> | null>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<PagedListDto<TxDto>>;
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async verdictWaitingTx(txId: string, verdict: boolean): Promise<HttpResultDto<TxErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetTxById}/${txId}/${verdict}`;
    const result = new HttpResultDto<TxErrorCode, null>(false);

    try {
      (await firstValueFrom(this._http.post(uri, null))) as ApiResponseDto<null>;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async revertWithdraw(txId: number): Promise<HttpResultDto<TxErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.WithdrawReverse}/${txId}`;
    const result = new HttpResultDto<TxErrorCode, null>(false);

    try {
      (await firstValueFrom(this._http.post(uri, null))) as ApiResponseDto<null>;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): TxErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return TxErrorCode.InternalError;
      case Constants.Unauthorized:
        return TxErrorCode.Unauthorized;
      case "Wallet not found":
        return TxErrorCode.WalletNotFound;
      case "Tx not found":
        return TxErrorCode.TxNotFound;
      default:
        return TxErrorCode.InternalError;
    }
  }

  private formattingTxDtoDate(txs: TxDto[]): TxDto[] {
    const result: TxDto[] = [];

    for (const item of txs) {
      item.createdAt = new Date(item.createdAt);
      result.push(item);
    }

    return result;
  }
}
