import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Constants } from "src/app/common/constants/constants";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() public errorMsg: string | null = null;
  @Input() public type: string = "text";
  @Input() public name: string = "";
  @Input() public id: string = "";

  @Input() public placeholder: string = "";
  @Input() public title: string = "";
  @Input() public comment: string[] = [];
  @Input() public mask: string = "";
  @Input() public dropSpecialCharacters: boolean = true;
  @Input() public autocomplete: string = "";
  @Input() public max: string = "";
  @Input() public min: string = "";
  @Input() public timer: number = 0;
  @Input() public withPasswordToggle: boolean = false;
  @Input() public autofocus: boolean = false;

  public isPasswordShown: boolean = false;
  public timeCounter: number = 0;
  private _timer: any;

  @Output() public onRefresh: EventEmitter<void> = new EventEmitter<void>();

  public control = new FormControl();
  private onChange: any = (_: any) => {};
  private onTouch: any = () => {};

  public readonly phoneCode = Constants.UzsPhoneCode;

  constructor() {}

  public ngOnInit(): void {
    this.control.valueChanges.subscribe(x => {
      if (this.onChange != null) {
        this.onChange(x);
        this.onTouch();
      }
    });

    if (this.timer) {
      this.timeCounter = this.timer;

      this._timer = setInterval(() => {
        if (this.timeCounter > 0) {
          this.timeCounter--;
        } else {
          return;
        }
      }, Constants.MsInSec);
    }
  }

  public writeValue(obj: any) {
    this.control.setValue(obj);
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  public onRefreshClick() {
    this.onRefresh.emit();
    this.timeCounter = this.timer;
  }

  public togglePassword() {
    this.isPasswordShown = !this.isPasswordShown;
    this.type = this.isPasswordShown ? "text" : "password";
  }

  ngOnDestroy() {
    clearInterval(this._timer);
  }
}
