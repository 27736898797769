<div *ngIf="internalError" class="text-center bg-invalid-color">
  <div class="text-text-secondary">
    {{ "Common.Unknown_error" | translate }}
  </div>
</div>

<app-header></app-header>

<section class="main-container max-w-[33.25rem] mx-auto py-[3.75rem] space-y-4">
  <h3 class="text-base uppercase text-center">{{ "Kyc.Verification" | translate }}</h3>

  <form class="customForm" [formGroup]="formGroup" (ngSubmit)="sendKyc()">
    <app-input
      [title]="'Kyc.Passport_num' | translate"
      formControlName="passId"
      type="text"
      name="passport"
      id="passport"
      placeholder="AA00000"
      [errorMsg]="
        passId?.hasError('maxlength')
          ? ('Common.Field_not_correct' | translate)
          : passId?.hasError('required') && passId?.value != null
          ? ('Common.Field_not_filled' | translate)
          : null
      "
    ></app-input>
    <app-input
      title="ПИНФЛ"
      formControlName="pinfl"
      type="number"
      name="pinfl"
      id="pinfl"
      placeholder="30101800050014"
      [errorMsg]="
        pinfl?.hasError('pattern')
          ? ('Common.Field_not_correct' | translate)
          : pinfl?.hasError('required') && pinfl?.value != null
          ? ('Common.Field_not_filled' | translate)
          : null
      "
    ></app-input>
    <app-input
      [title]="'Kyc.Name' | translate"
      formControlName="name"
      type="text"
      name="name"
      id="name"
      placeholder="Том Сойер"
      [errorMsg]="
        name?.hasError('required') && name?.value != null
          ? ('Common.Field_not_filled' | translate)
          : name?.hasError('minlength') || name?.hasError('kycLength') || name?.hasError('kycName')
          ? ('Common.Field_not_correct' | translate)
          : null
      "
    ></app-input>

    <div class="relative overflow-hidden">
      <p class="text-base mb-1">
        {{ "Kyc.Image_instruction" | translate }}
      </p>
      <label class="customInput">
        <input
          type="file"
          name="photo"
          id="photo"
          (change)="onFileChange($event)"
          [disabled]="file?.value != null"
          accept="image/jpeg,image/png"
        />
        <div
          class="customInput__file"
          [class.cursor-pointer]="!file?.value"
          [class.customInput__file_error]="file?.value && file?.invalid"
        >
          <p *ngIf="file?.value == null">{{ "Kyc.Your_photo" | translate }}</p>
          <p *ngIf="file?.value != null" class="truncate">
            {{ file?.value.name }}
          </p>
        </div>
        <button
          *ngIf="file?.value != null"
          type="button"
          class="absolute top-1/2 -translate-y-1/2 right-4"
          (click)="deleteFile($event)"
        >
          <img src="assets/icons/close.svg" alt="delete" />
        </button>
      </label>

      <label class="customInput block mt-3">
        <input
          type="file"
          name="photo"
          id="photo"
          (change)="onPassFileChange($event)"
          [disabled]="passFile?.value != null"
          accept="image/jpeg,image/png"
        />
        <div
          class="customInput__file"
          [class.cursor-pointer]="!passFile?.value"
          [class.customInput__file_error]="passFile?.value && passFile?.invalid"
        >
          <p *ngIf="passFile?.value == null">{{ "Kyc.Passport_photo" | translate }}</p>
          <p *ngIf="passFile?.value != null" class="truncate">
            {{ passFile?.value.name }}
          </p>
        </div>
        <button
          *ngIf="passFile?.value != null"
          type="button"
          class="absolute top-1/2 -translate-y-1/2 right-4"
          (click)="deletePassFile($event)"
        >
          <img src="assets/icons/close.svg" alt="delete" />
        </button>
      </label>

      <small
        *ngIf="file?.hasError('invalidExtension') || passFile?.hasError('invalidExtension')"
        class="text-text-pale text-sm"
      >
        {{ "Kyc.Image_format_error" | translate }}
      </small>
    </div>

    <button type="submit" class="btn" [disabled]="formGroup.invalid">
      {{ "Login.Continue" | translate }}
    </button>
  </form>

  <p class="text-sm font-light text-center">
    {{ "Login.Bottom_caption" | translate }}
  </p>
</section>
