<ul class="list-none space-y-2">
  <li
    *ngFor="let r of passwordRequirements"
    class="flex items-center"
    [ngClass]="getIsPasswordReqValid(r.pattern) ? 'stroke-accent text-accent' : 'stroke-danger text-danger'"
  >
    <svg
      class="mr-2 h-6 w-6 shrink-0"
      width="28"
      height="17"
      viewBox="0 0 28 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1875 1L13.0625 16L7.4375 10.375M7.4375 16L1.8125 10.375M20.5625 1L12.5938 10.1406"
        stroke="currentColor"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span>{{ r.text | translate }}</span>
  </li>
</ul>
