import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { GeneralPanelDto } from "src/app/common/DTO/general-panel/general-panel.dto";
import { GeneralPanelService } from "src/app/services/general-panel.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-multiplier-block",
  templateUrl: "./multiplier-block.component.html",
  styleUrls: ["./multiplier-block.component.css"],
})
export class MultiplierBlockComponent implements OnInit {
  public currentMultiplier: number = 0;
  public isPending = false;

  public multiplierForm: FormGroup;

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _generalPanelService: GeneralPanelService,
    private readonly _translateService: TranslateService,
    private readonly _toastService: ToastService
  ) {
    this.multiplierForm = new FormGroup({
      multiplier: new FormControl(null, [Validators.required]),
    });
  }

  async ngOnInit() {
    await this.getRateFactor();
  }

  public handleOpenModal(content: any) {
    this.multiplierForm.setValue({ multiplier: this.currentMultiplier });
    this.multiplierForm.updateValueAndValidity();
    this._modalService.open(content);
  }

  public handleCloseModal() {
    this._modalService.dismissAll();
    this.multiplierForm.reset();
    this.isPending = false;
  }

  public async handleSaveMultiplier() {
    const factor = this.multiplierForm.value.multiplier;
    const dto: GeneralPanelDto = {
      factor,
    };
    this.isPending = true;
    const res = await this._generalPanelService.updateGeneralPanel(dto);

    if (res.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.show(errorMsg);
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.show(successMsg);
    }

    this.isPending = false;
    this.handleCloseModal();
    this.getRateFactor();
  }

  private async getRateFactor() {
    const res = await this._generalPanelService.getGeneralPanel();
    if (res.params) {
      this.currentMultiplier = res.params.factor;
    }
  }
}
