import { Component, OnInit } from "@angular/core";
import { KycDto } from "src/app/common/DTO/kyc/kyc.dto";
import { KycService } from "src/app/services/kyc.service";

@Component({
  templateUrl: "./admin-kyc.component.html",
  styleUrls: ["./admin-kyc.component.css"],
})
export class AdminKycComponent implements OnInit {
  public page = 1;
  public pageSize = 5;
  public totalCount = 0;

  public kycItems: KycDto[] = [];

  constructor(private readonly _kycService: KycService) {}

  async ngOnInit() {

    await this.requestKycItems();
  }

  public async onPageChange() {
    await this.requestKycItems();
  }

  private async requestKycItems() {
    const res = await this._kycService.getAllKyc(this.pageSize, this.page);
    this.kycItems = res.params!.items;
    this.totalCount = res.params!.totalCount;
  }
}
