<div class="flex h-screen flex-col">
  <app-header></app-header>

  <div class="bg-gradient flex flex-grow items-center justify-center">
    <div class="w-[59rem] space-y-6 rounded-tw bg-box-background p-8 max-md:m-4">
      <h1 class="underlined-btn underlined-btn_active text-center">
        {{ isFail ? ("Kyc.Verify_fail" | translate) : ("Kyc.Verify_success" | translate) }}
      </h1>
      <a *ngIf="isFail" routerLink="/" class="btn">{{ "Blacklist.On_main_page" | translate }}</a>

      <ng-container *ngIf="!isFail">
        <p class="text-xl">
          {{ "Kyc.Welcome_message_1" | translate }}
        </p>

        <div class="rounded-md border border-border-input-color px-6 py-3">
          <p
            *ngFor="let wallet of wallets"
            class="flex items-center justify-start gap-1 text-xl max-md:flex-wrap max-md:justify-between"
          >
            <span class="whitespace-nowrap"
              >{{ "Kyc.Wallet_address" | translate }} {{ getNetworkName(wallet.network) }}:</span
            >
            <a
              class="truncate text-2xl font-bold text-link-color max-md:order-1 max-md:w-full max-md:max-w-[70vw]"
              target="_blank"
              [href]="getWalletLink(wallet.network, wallet.address)"
            >
              {{ wallet.address }}
            </a>
            <app-copy-btn [value]="wallet.address" />
          </p>
        </div>
        <a [routerLink]="'/' + walletRoute" class="btn">{{ "Kyc.Go_to_wallet" | translate }}</a>
      </ng-container>
    </div>
  </div>
</div>
