export class ModalConstants {
  public static readonly BankCardAdd = "bank-card-add-modal";
  public static readonly BankCardDelete = "bank-card-delete-modal";
  public static readonly BankCardConfirmation = "bank-card-confirmation-modal";
  public static readonly ChangeAvatar = "change-avatar-modal";
  public static readonly ChangePassword = "change-password-modal";
  public static readonly AdminEditRate = "edit-rate-modal";
  public static readonly AdminEditCommission = "edit-commission-modal";
  public static readonly AdminEditLimit = "edit-limit-modal";
  public static readonly AdminAddAdmin = "add-admin-modal";
  public static readonly AdminAddAdminConfirmation = "add-admin-confirmation-modal";
  public static readonly AdminGetReportForPeriod = "admin-get-report-for-period-modal";
  public static readonly AdminGetRegisteredUsersReportForPeriod =
    "admin-get-registered-users-report-for-period-modal";

  public static readonly AuthModal = "auth-modal";

  public static readonly AddTerrorist = "add-terror-modal";
  public static readonly AdminGetScannedTerrors = "admin-get-scanned-terror-modal";

  public static readonly DeleteKycConfirmation = "delete-kyc-confirmation-modal";
  public static readonly DeactivateUserConfirmation = "deactivate-user-confirmation-modal";
}
