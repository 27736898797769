<a
  [routerLink]="'/' + item.link.split('?')[0]"
  class="flex items-center rounded-tw bg-secondary fill-text-primary px-7 py-5 text-text-primary hover:bg-accent-hover hover:fill-text-secondary hover:text-text-secondary max-md:rounded-none max-md:px-5 max-md:py-2"
  [ngClass]="{ '!bg-accent !fill-text-secondary !text-text-secondary': isCurrent }"
  (click)="onItemClick($event)"
>
  <app-svg-icon [name]="item.icon" class="h-8 w-8 shrink-0 max-md:h-7 max-md:w-7" />
  <span
    [@buttonExpand]="isFullMenu"
    class="ml-5 mr-4 overflow-hidden whitespace-nowrap text-sm font-semibold uppercase"
  >
    {{ item.title | translate }}
  </span>
</a>
