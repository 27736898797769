<ng-container [ngSwitch]="step">
  <ng-template ngSwitchCase="input">
    <h2 class="underlined-btn underlined-btn_active text-center">
      {{ "Password_change.Title" | translate }}
    </h2>
    <form
      [formGroup]="changePasswordForm"
      (ngSubmit)="onChangePassword()"
      class="mt-6 flex flex-col space-y-6"
    >
      <app-input
        type="password"
        [title]="'Profile.Old_password' | translate"
        placeholder="********"
        formControlName="oldPsw"
        id="oldPsw"
        [autocomplete]="'off'"
        [errorMsg]="getFieldHasErrors('oldPsw')"
        [withPasswordToggle]="true"
      />
      <app-input
        type="text"
        [title]="'Profile.New_password' | translate"
        placeholder="********"
        formControlName="newPsw"
        id="newPsw"
        [autocomplete]="'off'"
        [errorMsg]="getFieldHasErrors('newPsw')"
      />
      <app-input
        type="text"
        [title]="'Profile.Repeat_new_password' | translate"
        placeholder="********"
        formControlName="pswRepeat"
        id="pswRepeat"
        [autocomplete]="'off'"
        [errorMsg]="getFieldHasErrors('pswRepeat')"
      />
      <app-password-checker
        [password]="changePasswordForm.get('newPsw')?.value"
        [confirmPassword]="changePasswordForm.get('pswRepeat')?.value"
      />

      <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>

      <button type="submit" class="btn w-full" [disabled]="changePasswordForm.invalid || isPending">
        {{ "Common.Confirm" | translate }}
      </button>
    </form>
  </ng-template>

  <!-- Success -->
  <ng-template ngSwitchCase="success">
    <h2 class="underlined-btn underlined-btn_active text-center">
      {{ "Password_change.Success" | translate }}
    </h2>
    <p class="mt-6 text-xl">
      {{ "Password_change.Success_msg" | translate }}
    </p>
    <button class="btn mt-6 w-full" (click)="onClose()">Закрыть</button>
  </ng-template>
</ng-container>
