import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function lengthEqualValidator(length: number): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

    const value = control.value;

    if (!value) {
      return null;
    }

    return value.length == length ? null : {lengthEqual: true};
  }
}
