export class SendKycDto {
  public file: File;
  public name: string;
  public passId: string;

  constructor(file: File, name: string, passId: string) {
    this.file = file;
    this.name = name;
    this.passId = passId;
  }
}
