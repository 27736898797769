import { Component, OnInit } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { TelegramToMyIdRedirectPayload } from "src/app/common/interfaces/telegram-to-my-id-redirect-payload";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  selector: "app-tma-my-id",
  templateUrl: "./tma-to-my-id-redirect.component.html",
  styleUrls: ["./tma-to-my-id-redirect.component.css"],
})
export class TmaToMyIdRedirectComponent implements OnInit {
  constructor(
    private _router: Router,
    private _localStorageService: LocalStorageService
  ) {}

  async ngOnInit(): Promise<void> {
    const currentRoute: ActivatedRouteSnapshot = this._router.routerState.snapshot.root;

    let payload: TelegramToMyIdRedirectPayload = {};

    try {
      const encodedPayload = currentRoute.queryParams["payload"];
      const decodedPayload = window.atob(encodedPayload);
      payload = JSON.parse(decodedPayload);
    } catch (error) {
      console.error("payload parse error:", error);
    }

    if (
      !payload.accessToken ||
      !payload.accessTokenLifetime ||
      !payload.refreshToken ||
      !payload.refreshTokenLifetime ||
      !payload.myIdUrl
    ) {
      this._router.navigateByUrl("/");
      return;
    }

    await this._localStorageService.saveTokens({
      accessToken: payload.accessToken,
      refreshToken: payload.refreshToken,
      accessExpiryAt: payload.accessTokenLifetime,
      refreshExpiryAt: payload.refreshTokenLifetime,
    });

    window.open(payload.myIdUrl, "_self");
  }
}
