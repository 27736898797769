<section class="admin-body">
  <app-back-button link="/admin/home"></app-back-button>

  <h2 class="title">{{ "Admin.Kyc.Active_requests" | translate }}</h2>

  <ng-container *ngIf="kycItems.length > 0; else noItems">
    <div class="grid grid-rows-1 gap-y-2">
      <div class="flex items-center gap-5" *ngFor="let kyc of kycItems">
        <a class="btn btn_dark h-full w-[12.5rem]" [routerLink]="kyc.id.toString()">{{
          "Admin.Kyc.Show" | translate
        }}</a>
      </div>
    </div>

    <ngb-pagination
      [collectionSize]="totalCount"
      [(page)]="page"
      [pageSize]="pageSize"
      [maxSize]="3"
      [boundaryLinks]="true"
      (pageChange)="onPageChange()"
    >
    </ngb-pagination>
  </ng-container>

  <ng-template #noItems>
    <div class="text-base text-center">{{ "Admin.Kyc.No_active_requests" | translate }}</div>
  </ng-template>
</section>
