import {Constants} from "src/app/common/constants/constants";

export class TimeHelperUtil {
  public static getTimeDiffInSec(date: Date): number {
    const now = new Date();
    const msDiff = date.getTime() - now.getTime();

    return Math.round(msDiff / Constants.MsInSec);
  }

  public static checkLifetimeIsValid(data: Date | null): boolean {
    if (data == null) {
      return false;
    }

    const now = new Date();
    return data.getTime() > now.getTime();
  }
}
