<section class="flex flex-col space-y-4">
  <app-back-button link="/admin/home"></app-back-button>

  <div class="flex flex-col space-y-3">
    <h3 class="title">{{ "Admin.Reports.Accounting_reports" | translate }}</h3>
    <div class="box grid grid-rows-1 gap-3">
      <ng-container>
        <div class="flex items-center gap-2">
          <span class="text-sm font-bold"> {{ "Admin.Reports.Sell_buy_report" | translate }}</span>
          <button
            class="ml-auto w-6"
            (click)="handleOpenModal(ModalConstants.AdminGetReportForPeriod)"
            [disabled]="isUserObserverAdmin"
          >
            <app-svg-icon name="download" />
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="flex flex-col space-y-3 py-5">
    <h3 class="title">{{ "Admin.Reports.User_reports" | translate }}</h3>
    <div class="box grid grid-rows-1 gap-3">
      <ng-container>
        <div class="flex items-center gap-2">
          <span class="text-sm font-bold"> {{ "Admin.Reports.Users_registration_report" | translate }}</span>
          <button
            class="ml-auto w-6"
            (click)="handleOpenModal(ModalConstants.AdminGetRegisteredUsersReportForPeriod)"
            [disabled]="isUserObserverAdmin"
          >
            <app-svg-icon name="download" />
          </button>
        </div>
      </ng-container>
    </div>
    <div class="box grid grid-rows-1 gap-3">
      <ng-container>
        <div class="flex items-center gap-2">
          <span class="text-sm font-bold"> {{ "Admin.Reports.Users_personal_data_report" | translate }}</span>
          <button class="ml-auto w-6" (click)="handleDownloadButtonClick()" [disabled]="isUserObserverAdmin">
            <app-svg-icon name="download" />
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</section>

<jw-modal [id]="ModalConstants.AdminGetReportForPeriod">
  <form class="space-y-4" [formGroup]="reportForm">
    <h4 class="title-big text-center">{{ "Admin.Reports.Select_date" | translate }}</h4>
    <div class="grid grid-cols-2">
      <app-input
        formControlName="startDate"
        type="date"
        class="w-[9rem]"
        (change)="onStartDateChanged()"
        [max]="maxStartDate"
        [title]="_translateService.instant('Admin.Reports.Date_from')"
      ></app-input>
      <app-input
        formControlName="endDate"
        type="date"
        class="w-[9rem]"
        (change)="onEndDateChenged()"
        [max]="maxEndDate"
        [min]="minEndDate"
        [title]="_translateService.instant('Admin.Reports.Date_to')"
      ></app-input>
    </div>
    <button
      [disabled]="reportForm.invalid"
      type="submit"
      class="btn m-auto"
      (click)="handleSubmit(ModalConstants.AdminGetReportForPeriod)"
    >
      {{ "Admin.Reports.Download" | translate }}
    </button>
  </form>
</jw-modal>

<jw-modal [id]="ModalConstants.AdminGetRegisteredUsersReportForPeriod" (onClose)="handleClose()">
  <form class="space-y-4" [formGroup]="reportForm">
    <h4 class="title-big text-center">{{ "Admin.Reports.Select_date" | translate }}</h4>
    <div class="grid grid-cols-2">
      <app-input
        formControlName="startDate"
        type="date"
        class="w-[9rem]"
        (change)="onStartDateChanged()"
        [max]="maxStartDate"
        [title]="_translateService.instant('Admin.Reports.Date_from')"
      ></app-input>
      <app-input
        formControlName="endDate"
        type="date"
        class="w-[9rem]"
        (change)="onEndDateChenged()"
        [max]="maxEndDate"
        [min]="minEndDate"
        [title]="_translateService.instant('Admin.Reports.Date_to')"
      ></app-input>
    </div>
    <button
      [disabled]="reportForm.invalid"
      type="submit"
      class="btn m-auto"
      (click)="handleSubmit(ModalConstants.AdminGetRegisteredUsersReportForPeriod)"
    >
      {{ "Admin.Reports.Download" | translate }}
    </button>
  </form>
</jw-modal>
