import { Directive, ElementRef, AfterContentInit, Input } from "@angular/core";

@Directive({
  selector: "[appAutofocus]",
})
export class AutofocusDirective implements AfterContentInit {
  private focus = true;

  constructor(private el: ElementRef) {}

  ngAfterContentInit() {
    if (this.focus) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 500);
    }
  }

  @Input() set appAutofocus(condition: boolean) {
    this.focus = condition !== false;
  }
}
