import { Component, OnInit } from "@angular/core";
import { RatesService } from "../../../services/rates.service";
import { RateDto } from "../../../common/DTO/rates/rate.dto";
import { ToastService } from "../../../services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { CryptoSymbol } from "../../../common/enums/crypto-symbol.enum";
import { getCurrencyName } from "../../../common/utils/currency-name-helper.util";

@Component({
  selector: "app-rate-block",
  templateUrl: "./rate-block.component.html",
  styleUrls: ["./rate-block.component.css"],
})
export class RateBlockComponent implements OnInit {
  public rates: RateDto[] = [];
  public symbols = CryptoSymbol;
  public getCurrencyName = getCurrencyName;

  constructor(
    private _ratesService: RatesService,
    private _toastService: ToastService,
    private _translateService: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.rates = await this.getRates();
  }

  private async getRates(): Promise<RateDto[]> {
    const ratesResult = await this._ratesService.getRates();
    if (ratesResult.withError) {
      const errorMsg = this._translateService.instant("Admin.Home.Unable_to_get_rates");
      this._toastService.show(errorMsg);
      return [];
    }
    return ratesResult.params ?? [];
  }
}
