<div class="grid w-max grid-flow-col gap-x-5">
  <button
    *ngFor="let tab of translatedTabs; index as i"
    class="rounded-main p-5 text-sm font-semibold transition-colors"
    [ngClass]="currentTabNumber == i ? 'bg-accent text-text-secondary' : 'bg-secondary text-text-primary'"
    (click)="onTabSelect(i)"
    type="button"
  >
    {{ tab }}
  </button>
</div>
