import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AUTO_STYLE, animate, state, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "app-faq-accordion",
  templateUrl: "./faq-accordion.component.html",
  styleUrls: ["./faq-accordion.component.css"],
  animations: [
    trigger("openClose", [
      state("true", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state("false", style({ height: "0", visibility: "hidden" })),
      transition("false => true", animate("200ms")),
      transition("true => false", animate("200ms")),
    ]),
  ],
})
export class FaqAccordionComponent {
  faqList: { question: string; answer: string; opened: boolean }[] = [];
  private faqLength = 14;

  constructor(private readonly _translateService: TranslateService) {
    this.generateFaqList();

    this._translateService.onLangChange.subscribe(() => {
      this.generateFaqList();
    });
  }

  toggleAccordion(index: number): void {
    this.faqList[index].opened = !this.faqList[index].opened;
  }

  private generateFaqList(): void {
    this.faqList = Array.from({ length: this.faqLength }, (_, i) => {
      return {
        question: this._translateService.instant(`Faq.${i + 1}.Q`),
        answer: this._translateService.instant(`Faq.${i + 1}.A`),
        opened: false,
      };
    });
  }
}
