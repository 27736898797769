import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Address } from "@ton/core";
import { Subject, debounceTime, distinctUntilChanged } from "rxjs";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { SendCryptoDto } from "src/app/common/DTO/wallets/send-crypto.dto";
import { WalletBalanceDto } from "src/app/common/DTO/wallets/wallet-balance.dto";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { Network } from "src/app/common/enums/network.enum";
import { WalletErrorCode } from "src/app/common/enums/wallet-error-code.enum";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { TelegramMiniAppHelper } from "src/app/common/utils/telegram-mini-app-helper.util";
import { lengthEqualValidator } from "src/app/common/validators/length-equal.validator";
import { CommissionService } from "src/app/services/commission.service";
import { PolygonService } from "src/app/services/polygon.service";
import { RatesService } from "src/app/services/rates.service";
import { TonConnectService } from "src/app/services/ton-connect.service.ts.service";
import { WalletService } from "src/app/services/wallet.service";

enum Step {
  Input = 1,
  Confirm = 2,
  Processing = 3,
}

@Component({
  selector: "app-send-modal",
  templateUrl: "./send-modal.component.html",
  styleUrls: ["./send-modal.component.css"],
})
export class SendModalComponent implements OnInit {
  step: Step = Step.Input;
  businessError: string | null = null;
  wallets: WalletDto[] = [];
  selectedWalletBalance: WalletDto = new WalletDto();
  confirmationTime = 0;
  isPending = false;
  networkFee = 0;
  isCalculating = false;
  Network = Network;
  isTelegramMiniApp = TelegramMiniAppHelper.isMiniApp();
  tonWalletAddress = "";

  sendForm: FormGroup;

  private _timer: any;
  private polygonGasPrice = 0;
  private rates: RateDto[] = [];
  private feeCallSubject = new Subject<void>();

  private cleanUpFunctionsList: (VoidFunction | undefined)[] = [];

  constructor(
    private _translateService: TranslateService,
    private _walletService: WalletService,
    private _activeModal: NgbActiveModal,
    private _polygonService: PolygonService,
    private _ratesService: RatesService,
    private _commissionService: CommissionService,
    private _tonConnectService: TonConnectService
  ) {
    this.sendForm = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(Number.MIN_VALUE)]),
      to: new FormControl(null, [
        Validators.required,
        lengthEqualValidator(ValidateConstants.TrxAddressLength),
        Validators.pattern(ValidateConstants.TrxAddressPattern),
      ]),
    });

    // Call the fee calculation function when the form changes
    this.sendForm.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(async () => {
      if (this.sendForm.valid) {
        await this.calculateNetworkFee();
      }
    });

    // Call the fee calculation function every 10 seconds after the last call
    this.feeCallSubject.pipe(debounceTime(10000)).subscribe(async () => {
      await this.getGasPrice();
      await this.calculateNetworkFee();
    });

    this.tonWalletAddress = this._tonConnectService?.tonConnectUi?.account?.address || "";
    const tonStatusUnsubscribe = this._tonConnectService?.tonConnectUi?.onStatusChange?.(wallet => {
      this.tonWalletAddress = wallet?.account?.address || "";
    });
    this.cleanUpFunctionsList.push(tonStatusUnsubscribe);
  }

  async ngOnInit() {
    const wallets = await this._walletService.getMy();
    this.wallets = wallets?.params || [];
    this.selectedWalletBalance = { ...this.wallets[0], balances: [this.wallets[0].balances[0]] };
    await this.getGasPrice();
    await this.getRates();
    await this.calculateNetworkFee();
    this.updateAmountValidator();
    this.updateWalletValidator();
  }

  async onSelectBalance(balance: WalletBalanceDto, walletAddress: string) {
    const foundWallet = this.wallets.find(w => w.address === walletAddress)!;
    this.selectedWalletBalance = { ...foundWallet, balances: [balance] };
    await this.calculateNetworkFee();
    this.updateAmountValidator();
    this.updateWalletValidator();
  }

  onBack() {
    if (this.step === Step.Confirm) {
      this.step = Step.Input;
    }
    clearInterval(this._timer);
    this.businessError = null;
  }

  closeModal() {
    this._activeModal.close();
  }

  async submitInput() {
    if (this.sendForm.valid) {
      this.step = Step.Confirm;
      this.confirmationTime = 10;
      this._timer = setInterval(() => {
        this.confirmationTime -= 1;
        if (this.confirmationTime === 0) {
          clearInterval(this._timer);
        }
      }, 1000);
    }
  }

  async onConfirm() {
    this.isPending = true;
    this.businessError = null;
    const balance = this.selectedWalletBalance!.balances[0];
    const amount = this.sendForm.controls["amount"].value;
    const to = this.sendForm.controls["to"].value;

    const dto: SendCryptoDto = {
      to,
      amount,
      currency: balance.currency,
    };

    if (balance.currency === CryptoSymbol.Bitcoin) {
      dto.amount *= 10 ** 8;
    }

    const response = await this._walletService.send(dto);
    this.isPending = false;

    if (response.withError) {
      switch (response.errorCode) {
        case WalletErrorCode.SendLimitReached:
          this.businessError = this._translateService.instant("Send.Send_limit_reached_error");
          break;
        case WalletErrorCode.BlockchainError:
          this.businessError = this._translateService.instant("Send.Blockchain_error");
          break;
        case WalletErrorCode.SendDisabled:
          this.businessError = this._translateService.instant("Send.Send_disabled_error");
          break;
        default:
          this.businessError = this._translateService.instant("Common.Unknown_error");
          break;
      }
      return;
    } else {
      this.step = Step.Processing;
      this.businessError = null;
    }
  }

  public get selectedCurrencyName() {
    if (this.selectedWalletBalance && this.selectedWalletBalance.balances.length > 0) {
      return getCurrencyName(this.selectedWalletBalance!.balances[0].currency);
    } else {
      return "-";
    }
  }

  public get nativeCurrencyName() {
    if (this.selectedWalletBalance.balances.length === 0) {
      return "";
    }

    switch (this.selectedWalletBalance.balances[0].currency) {
      case CryptoSymbol.Trx:
      case CryptoSymbol.Usdt:
        return "TRX";
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "MATIC";
      case CryptoSymbol.Ton:
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Not:
        return "TON";
      case CryptoSymbol.Bitcoin:
        return "BTC";
      default:
        return "";
    }
  }

  public get minimumAmount() {
    switch (this.selectedWalletBalance?.balances[0]?.currency) {
      case CryptoSymbol.Trx:
        return 5;
      case CryptoSymbol.Usdt:
        return 50;
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
      case CryptoSymbol.Ton:
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Not:
        return 1;
      case CryptoSymbol.Bitcoin:
        return 0.00005;
      default:
        return 50;
    }
  }

  public get networkName() {
    if (this.selectedWalletBalance.balances.length === 0) {
      return "";
    }
    return getNetworkName(this.selectedWalletBalance.network);
  }

  public get receiveAmount() {
    return this.sendForm.controls["amount"].value || 0;
  }

  public get sendAmount() {
    const inputAmount = +this.sendForm.controls["amount"].value;
    let convertedFee = 0;
    switch (this.selectedWalletBalance?.balances[0]?.currency) {
      case CryptoSymbol.Matic:
      case CryptoSymbol.Trx:
      case CryptoSymbol.Ton:
        convertedFee = this.networkFee;
        break;
      case CryptoSymbol.Usdt:
        convertedFee = ConvertCurrencyHelper.convertToUsdt(this.networkFee, CryptoSymbol.Trx, this.rates);
        break;
      case CryptoSymbol.PolygonUsdt:
        convertedFee = ConvertCurrencyHelper.convertToPolygonUsdt(
          this.networkFee,
          CryptoSymbol.Matic,
          this.rates
        );
        break;
      case CryptoSymbol.TonUsdt:
        convertedFee = ConvertCurrencyHelper.convertToTonUsdt(this.networkFee, CryptoSymbol.Ton, this.rates);
        break;
      case CryptoSymbol.Not:
        convertedFee = ConvertCurrencyHelper.convertToNot(this.networkFee, CryptoSymbol.Ton, this.rates);
        break;
      case CryptoSymbol.Bitcoin:
        convertedFee = ConvertCurrencyHelper.convertToBitcoin(this.networkFee, CryptoSymbol.Ton, this.rates);
        break;
      default:
        break;
    }

    return inputAmount + convertedFee;
  }

  public get amountError(): string | null {
    const amount = this.sendForm.controls["amount"];
    const balanceCurrency = this.selectedWalletBalance?.balances[0]?.currency;

    if (amount.value == null) {
      return null;
    }

    if (amount?.hasError("required")) {
      return this._translateService.instant("Send.Amount_required_error");
    }

    if (amount?.hasError("min")) {
      return this._translateService.instant("Send.Amount_min_error");
    }

    if (amount?.hasError("max")) {
      return this._translateService.instant("Send.Amount_insuf_error");
    }

    let nativeBalance = 0;
    switch (balanceCurrency) {
      case CryptoSymbol.Usdt:
        nativeBalance =
          this.wallets.find(w => w.balances[0].currency === CryptoSymbol.Trx)?.balances[0].amount ?? 0;
        if (this.networkFee > nativeBalance) {
          return this._translateService.instant("Send.Amount_commission_error");
        }
        break;
      case CryptoSymbol.PolygonUsdt:
        nativeBalance =
          this.wallets.find(w => w.balances[0].currency === CryptoSymbol.Matic)?.balances[0].amount ?? 0;
        if (this.networkFee > nativeBalance) {
          return this._translateService.instant("Send.Amount_commission_error");
        }
        break;
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Not:
        nativeBalance =
          this.wallets.find(w => w.balances[0].currency === CryptoSymbol.Ton)?.balances[0].amount ?? 0;
        if (this.networkFee > nativeBalance) {
          return this._translateService.instant("Send.Amount_commission_error");
        }
        break;
      default:
        break;
    }

    return null;
  }

  public get toError(): string | null {
    const to = this.sendForm.controls["to"];
    if (to?.value == null) {
      return null;
    }
    if (to?.hasError("required")) {
      return this._translateService.instant("Send.Receiver_required_error");
    }
    if (to?.hasError("lengthEqual")) {
      return this._translateService.instant("Send.Receiver_incorrect_error");
    }
    if (to?.hasError("pattern")) {
      return this._translateService.instant("Send.Receiver_incorrect_error");
    }
    return null;
  }

  public get walletPlaceholder() {
    if (this.selectedWalletBalance.network === Network.Tron) {
      return "TM3RXbAn2u7ujLFnmxkaeobRqh162ft3dK";
    } else if (this.selectedWalletBalance.network === Network.Polygon) {
      return "0x9511972dec304fB5d06F562FA44C93aE26866F51";
    } else {
      return "";
    }
  }

  public useTonWalletAddressAsReceiver() {
    const userFriendlyTonAddress = Address.parseRaw(this.tonWalletAddress).toString();
    this.sendForm.controls["to"].setValue(userFriendlyTonAddress);
  }

  private async calculateNetworkFee() {
    this.isCalculating = true;
    const currency = this.selectedWalletBalance.balances[0].currency;
    this.networkFee = await this._commissionService.calculateNetworkCommission({
      currency,
      gasPrices: { polygonGasPrice: this.polygonGasPrice },
      amount: this.sendForm.controls["amount"].value,
      toAddress: this.sendForm.controls["to"].value,
      fromAddress: this.selectedWalletBalance.address,
    });
    this.isCalculating = false;
    this.feeCallSubject.next();
  }

  private updateAmountValidator() {
    const amount = this.sendForm.controls["amount"];
    const balanceAmount = this.selectedWalletBalance?.balances[0]?.amount;
    let maxValue = 0;

    switch (this.selectedWalletBalance?.balances[0]?.currency) {
      case CryptoSymbol.Trx:
      case CryptoSymbol.Matic:
      case CryptoSymbol.Ton:
      case CryptoSymbol.Bitcoin:
        maxValue = balanceAmount - this.networkFee;
        break;
      case CryptoSymbol.Usdt:
      case CryptoSymbol.PolygonUsdt:
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Not:
        maxValue = balanceAmount;
        break;
      default:
        break;
    }

    amount.setValidators([Validators.required, Validators.min(Number.MIN_VALUE), Validators.max(maxValue)]);
    amount.updateValueAndValidity();
  }

  private updateWalletValidator() {
    const to = this.sendForm.controls["to"];
    if (this.selectedWalletBalance.network === Network.Tron) {
      to.setValidators([
        Validators.required,
        lengthEqualValidator(ValidateConstants.TrxAddressLength),
        Validators.pattern(ValidateConstants.TrxAddressPattern),
      ]);
    } else if (this.selectedWalletBalance.network === Network.Polygon) {
      to.setValidators([
        Validators.required,
        lengthEqualValidator(ValidateConstants.PolygonAddressLength),
        Validators.pattern(ValidateConstants.PolygonAddressPattern),
      ]);
    } else if (this.selectedWalletBalance.network === Network.Ton) {
      to.setValidators([
        Validators.required,
        lengthEqualValidator(ValidateConstants.TonAddressLength),
        Validators.pattern(ValidateConstants.TonAddressPattern),
      ]);
    } else if (this.selectedWalletBalance.network === Network.Bitcoin) {
      to.setValidators([
        Validators.required,
        lengthEqualValidator(ValidateConstants.BitcoinAddressLength),
        Validators.pattern(ValidateConstants.BitcoinAddressPattern),
      ]);
    }
    to.updateValueAndValidity();
  }

  private async getGasPrice() {
    const polygonRes = await this._polygonService.getGasPrice();
    this.polygonGasPrice = polygonRes?.SafeGasPrice ? +polygonRes.SafeGasPrice : 0;
  }

  private async getRates() {
    const res = await this._ratesService.getRates();
    if (res.withError) {
      return;
    }
    this.rates = res.params!;
  }

  ngOnDestroy() {
    clearInterval(this._timer);
    this.feeCallSubject.unsubscribe();
    this.cleanUpFunctionsList.map(cleanUp => cleanUp?.());
  }
}
