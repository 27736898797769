import {AbstractControl, ValidationErrors} from "@angular/forms";
import {Constants} from "src/app/common/constants/constants";

export function monthYearDateStrValidator(checkDateInFuture = false) {
  return (control:AbstractControl) : ValidationErrors | null => {
    if (control.value == null) {
      return null;
    }

    const error = { monthYearDate: true };

    const splitStr = control.value.split("/");
    if (splitStr.length != 2) {
      return error;
    }

    const month = +splitStr[0];
    const year = splitStr[1];

    if (!isDigit(month.toString()) || !isDigit(year)) {
      return error;
    }

    if (month > 12 || month < 1 || year.length != 2 || month.toString().length < 1) {
      return error;
    }

    if (!checkDateInFuture) {
      return null;
    }

    const fullYear = `20${year}`;
    const date = new Date(+fullYear, month - 1);
    const now = new Date();

    if (date.getTime() < now.getTime() + Constants.MsInDay) {
      return { dateInPast: true };
    }

    return null;
  }
}

function isDigit(val: string): boolean {
  return /^\d+$/.test(val);
}
