export enum AuthErrorCode {
  InternalError = 1,
  PhoneNumberAlreadyUse = 2,
  IncorrectCode = 3,
  PhoneAlreadyApprove = 4,
  CodeRequestLimitExceeds = 5,
  UserNotFound = 6,
  IncorrectPhoneOrPsw = 7,
  UserNotApproved = 8,
  InvalidAccessOrRefresh = 9,
  IncorrectRefreshToken = 10,
  LoginAlreadyUse = 11,
  IncorrectPassword = 12,
  DisallowSmsSentTo = 13
}
