import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { RouteConstants } from "../constants/route.constants";

@Injectable({
  providedIn: "root",
})
export class BlackListGuard {
  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _router: Router
  ) {}

  async canActivate() {
    const data = await this._localStorage.getUserData();

    if (data?.blackList) {
      this._router.navigate([`${RouteConstants.blackList}`]);
      return false;
    }

    return true;
  }
}
