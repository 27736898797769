<app-header></app-header>

<section class="main-container max-w-[33.25rem] mb-10">
  <div class="space-y-4 pt-[3.75rem]">
    <ng-content></ng-content>

    <div class="text-center">
      <p *ngIf="linkType == 2 || linkType === 3" class="text-sm text-text-pale">
        {{ "Login.Have_account" | translate }}
        <a class="text-link-color" routerLink="/login">{{ "Login.Enter" | translate }}</a>
      </p>
      <p *ngIf="linkType == 1" class="text-sm text-text-pale">
        {{ "Login.No_account" | translate }}
        <a class="text-link-color" routerLink="/signup">{{ "Login.Register" | translate }}</a>
      </p>
      <p *ngIf="linkType !== 3" class="text-sm text-text-pale">
        {{ "Login.Forgot_password" | translate }}
        <a class="text-link-color" routerLink="/reset-password">{{ "Login.Change_password" | translate }}</a>
      </p>
    </div>

    <p class="text-sm font-light text-center">
      {{ "Login.Bottom_caption" | translate }}
    </p>
  </div>
</section>

<app-footer></app-footer>
