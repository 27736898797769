import { Resolve } from "@angular/router";
import { Injectable } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { UserDto } from "../DTO/users/user.dto";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class UserResolver implements Resolve<any> {
  public user: UserDto | null = null;

  constructor(
    private readonly _userService: UserService,
    private readonly _localStorage: LocalStorageService
  ) {}

  public async resolve() {
    const response = await this._userService.getMe();
    if (response.withError || !response.params) {
      return;
    }

    this.user = response.params;
    await this._localStorage.saveUserData(this.user);

    return response.params;
  }
}
