import { environment } from "src/environments/environment";
import { RouteConstants } from "./route.constants";

export interface IMenuItem {
  title: string;
  link: string;
  icon: string;
}

export const MENU_ITEMS: IMenuItem[] = [
  {
    title: "Wallet.My_wallet",
    link: `${RouteConstants.depositary}`,
    icon: "menu/wallet-outline",
  },
  {
    title: "Header.Cards",
    link: `${RouteConstants.depositary}/${RouteConstants.cards}`,
    icon: "menu/card-outline",
  },
  {
    title: "Header.BuySell",
    link: `${RouteConstants.depositary}/${RouteConstants.buySell}`,
    icon: "menu/bag-handle-outline",
  },
  {
    title: "Transaction.Transaction_history",
    link: `${RouteConstants.depositary}/${RouteConstants.transactions}`,
    icon: "menu/history-outline",
  },
  {
    title: "Landing.Commission",
    link: `${RouteConstants.depositary}/${RouteConstants.commissions}`,
    icon: "menu/percent-outline",
  },
  {
    title: "Landing.Calculator",
    link: `${RouteConstants.depositary}/${RouteConstants.calculator}`,
    icon: "menu/calculator-outline",
  },
  {
    title: "Landing.Faq",
    link: `${RouteConstants.depositary}/${RouteConstants.faq}`,
    icon: "menu/help-outline",
  },
];
