<div>
  <h4 class="text-center text-2xl font-bold">{{ "Bank.Adding_card" | translate }}</h4>
  <app-stepper class="mb-5 mt-2 block" [totalSteps]="2" [currentStep]="step" />

  <ng-container [ngSwitch]="step">
    <ng-template ngSwitchCase="1">
      <form [formGroup]="addCardForm" (ngSubmit)="onCardSubmit()" class="space-y-5">
        <div class="flex items-start gap-5 max-xxs:flex-wrap">
          <app-input
            [title]="'Bank.Card_number' | translate"
            placeholder="8600 02** **** 5698"
            formControlName="number"
            [errorMsg]="getNumberErrMsg"
            id="number"
            mask="0000 0000 0000 0000"
            class="flex-grow"
          />
          <app-input
            [title]="'Bank.Card_expiration_date' | translate"
            placeholder="01/26"
            type="text"
            formControlName="expiryAt"
            [errorMsg]="getExpiryAtErrMsg"
            id="expiryAt"
            mask="00/00"
            [dropSpecialCharacters]="false"
            class="w-2/5 max-md:w-4/6 max-xxs:w-full"
          />
        </div>

        <div class="flex items-center justify-center gap-3">
          <img src="assets/icons/banks/uzcard.png" alt="uzcard" class="h-16 w-16" />
          <img src="assets/icons/banks/humo.png" alt="humo" class="h-16 w-16" />
        </div>

        <button type="submit" class="btn w-full" [disabled]="addCardForm.invalid || isPending">
          {{ "Common.Next" | translate }}
        </button>
      </form>

      <p *ngIf="businessError" class="mt-4 text-sm text-danger">{{ businessError }}</p>
    </ng-template>

    <ng-template ngSwitchCase="2">
      <form [formGroup]="addCardOtpForm" (ngSubmit)="onCodeSubmit()">
        <app-input
          type="number"
          [title]="'Login.Sms_code' | translate"
          placeholder="999788"
          formControlName="cardVerificationCode"
          [timer]="otpTimer"
          (onRefresh)="sendCodeToPhone()"
        />
        <re-captcha
          *ngIf="otpInputCount >= validateConstants.MaxOtpTries"
          (resolved)="captchaResolved($event)"
        ></re-captcha>

        <p *ngIf="businessError" class="text-sm text-danger">{{ businessError }}</p>

        <div class="mt-5 flex items-stretch gap-x-5">
          <button
            type="button"
            class="flex w-16 items-center justify-center rounded-xl bg-select-bg px-5"
            (click)="onBack()"
          >
            <img src="assets/icons/arrow-back-outline.svg" alt="back" />
          </button>
          <button type="submit" class="btn flex-grow" [disabled]="addCardOtpForm.invalid || isPending">
            {{ "Common.Confirm" | translate }}
          </button>
        </div>
      </form>
    </ng-template>
  </ng-container>
</div>
