import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { CryptoErrorCode } from "src/app/common/enums/crypto-error-code.enum";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { Constants } from "src/app/common/constants/constants";
import { EnvService } from "src/app/services/env.service";
import { BuyOrderDto } from "src/app/common/DTO/crypto/buy-order.dto";
import { SellOrderDto } from "src/app/common/DTO/crypto/sell-order.dto";
import { PagedListDto } from "src/app/common/DTO/paged-list.dto";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";

@Injectable({
  providedIn: "root",
})
export class CryptoService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _envService: EnvService
  ) {}

  public async buyCrypto(order: BuyOrderDto): Promise<HttpResultDto<CryptoErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.BuyCrypto}`;
    const result = new HttpResultDto<CryptoErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.post(uri, order));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async sellCrypto(order: SellOrderDto): Promise<HttpResultDto<CryptoErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.SellCrypto}`;
    const result = new HttpResultDto<CryptoErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.post(uri, order));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async getTransactions(
    page: number,
    size: number,
    userId?: number
  ): Promise<HttpResultDto<CryptoErrorCode, PagedListDto<CryptoTxDto> | null>> {
    const pageParams = `?PageIndex=${page}&PageSize=${size}`;
    const userParam = userId ? `&UserId=${userId}` : "";
    const uri = `${this._envService.serverUrl}${UrnConstants.CryptoTxs}${pageParams}${userParam}`;
    const result = new HttpResultDto<CryptoErrorCode, PagedListDto<CryptoTxDto> | null>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as PagedListDto<CryptoTxDto>;
      result.params = res;
    } catch (error) {
      const apiError = ErrorParserUtil.parse(error);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async getMyTransactions(
    page: number,
    size: number
  ): Promise<HttpResultDto<CryptoErrorCode, PagedListDto<CryptoTxDto> | null>> {
    const params = `?PageIndex=${page}&PageSize=${size}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.MyCryptoTxs}${params}`;
    const result = new HttpResultDto<CryptoErrorCode, PagedListDto<CryptoTxDto> | null>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as PagedListDto<CryptoTxDto>;
      result.params = res;
    } catch (error) {
      const apiError = ErrorParserUtil.parse(error);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async approveTx(txId: number): Promise<HttpResultDto<CryptoErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.CryptoTxs}/${txId}/approve`;
    const result = new HttpResultDto<CryptoErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.post(uri, {}));
    } catch (error) {
      const apiError = ErrorParserUtil.parse(error);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async rejectTx(txId: number): Promise<HttpResultDto<CryptoErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.CryptoTxs}/${txId}/reject`;
    const result = new HttpResultDto<CryptoErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.post(uri, {}));
    } catch (error) {
      const apiError = ErrorParserUtil.parse(error);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): CryptoErrorCode {
    const disabledRegex = /^.+ is not allowed$/;
    if (disabledRegex.test(error)) {
      return CryptoErrorCode.Disabled;
    }

    switch (error) {
      case Constants.InternalServerError:
        return CryptoErrorCode.InternalError;
      case Constants.Unauthorized:
        return CryptoErrorCode.Unauthorized;
      default:
        return CryptoErrorCode.InternalError;
    }
  }
}
