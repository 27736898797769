<jw-modal [id]="ModalConstants.ChangeAvatar" [fullScreenOnMobile]="true">
  <div class="flex flex-col space-y-4">
    <h4 class="title-big">{{ "Profile.Select_avatar" | translate }}</h4>

    <div class="grid grid-cols-6 gap-4">
      <button
        *ngFor="let item of avatars"
        (click)="onSelect(item)"
        class="relative w-auto aspect-square flex items-center justify-center"
        [ngClass]="{
          'ring-2 ring-accent rounded-full': selectedAvatar === item,
        }"
      >
        <img [src]="item" alt="Avatar" class="w-full rounded-full" />

        <span
          *ngIf="isCurrentAvatar(item)"
          class="absolute -top-1 -right-1 w-4 h-4 bg-text-secondary rounded-full"
        >
          <img src="assets/icons/success.svg" alt="checkmark" />
        </span>
      </button>
    </div>

    <button class="btn" (click)="onConfirm()" [disabled]="!selectedAvatar">
      {{ "Common.Approve" | translate }}
    </button>
  </div>
</jw-modal>
