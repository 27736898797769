<section class="flex flex-col space-y-4">
  <app-commission-block></app-commission-block>

  <app-limits-block></app-limits-block>

  <div class="flex flex-col space-y-3 py-5">
    <h3 class="title">{{ "Admin.Home.Functionality" | translate }}</h3>
    <div class="grid grid-cols-2 gap-5" [formGroup]="functionalityForm">
      <div *ngFor="let currency of currencies">
        <div [formGroupName]="currency" class="box">
          <div class="flex items-center gap-2">
            <app-currency-icon [currency]="currency" />
            <h4>{{ getCurrencyName(currency) }}</h4>
          </div>
          <div class="mt-5 grid grid-cols-3">
            <div class="mr-3 flex items-center gap-x-2 border-r border-r-border-input-color">
              <p class="font-medium">{{ "Admin.Home.Buy" | translate }}:</p>
              <app-switch
                [disabled]="isUserObserverAdmin"
                [caption]="'Common.Active' | translate"
                formControlName="canBuy"
              />
            </div>
            <div class="mr-3 flex items-center gap-x-2 border-r border-r-border-input-color">
              <p class="font-medium">{{ "Admin.Home.Sell" | translate }}:</p>
              <app-switch
                [disabled]="isUserObserverAdmin"
                [caption]="'Common.Active' | translate"
                formControlName="canSell"
              />
            </div>
            <div class="flex items-center gap-x-2">
              <p class="font-medium">{{ "Admin.Home.Send" | translate }}:</p>
              <app-switch
                [disabled]="isUserObserverAdmin"
                [caption]="'Common.Active' | translate"
                formControlName="canSend"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-rate-block></app-rate-block>

  <app-multiplier-block></app-multiplier-block>
</section>
