<div *ngIf="!isLoading; else skeleton">
  <!-- Desktop -->
  <div
    class="grid grid-cols-wallet-balances-table justify-items-end gap-x-6 px-6 font-medium leading-5 dark:text-white max-md:hidden"
  >
    <div class="flex items-start gap-x-6 justify-self-start">
      <app-currency-icon [currency]="balanceItem.currency" />
      <div class="space-y-2">
        <span class="block">{{ getCurrencyName() }}</span>
        <span class="block">{{ getUsdValue(1) | mfeCustom }} $</span>
      </div>
    </div>

    <span>
      {{ balanceItem.amount | mfeCustom: { currency: balanceItem.currency } }}
    </span>

    <span>{{ getUsdValue(balanceItem.amount) | mfeCustom }}</span>

    <span>{{ getUzsValue(balanceItem.amount) | mfeCustom: { currency: CryptoSymbol.Uzs } }}</span>

    <div class="flex items-start gap-x-4 justify-self-start">
      <button class="btn-text px-6" (click)="handleReceiveClick()">Получить</button>
      <button class="btn-text px-6" (click)="handleSendClick()">Отправить</button>
      <button class="btn-text px-6" (click)="handleSwapClick()">Обмен</button>
    </div>
  </div>

  <!-- Mobile -->
  <div
    class="px-2 py-3 text-black transition-all dark:text-white md:hidden"
    [ngClass]="{ 'rounded-2xl bg-blue-50 dark:bg-slate-800': mobileVariantExpanded }"
  >
    <div class="flex items-center">
      <app-currency-icon [currency]="balanceItem.currency" size="sm" />

      <div class="ml-3 flex grow justify-between">
        <div class="space-y-1">
          <span class="text-sm font-medium">{{ getCurrencyName() }}</span>
          <span class="block text-xs text-slate-400 dark:text-slate-200"
            >{{ getUsdValue(1) | mfeCustom }} $</span
          >
        </div>

        <div class="space-y-1 text-end">
          <span class="text-sm font-medium">
            {{ balanceItem.amount | mfeCustom: { currency: balanceItem.currency } }}
          </span>
          <span class="block text-xs text-slate-400 dark:text-slate-200"
            >$ {{ getUsdValue(balanceItem.amount) | mfeCustom }}</span
          >
        </div>
      </div>

      <button
        class="ml-1 mt-1 flex h-5 w-5 items-center justify-center self-start rounded-full bg-red-50 transition-all dark:bg-slate-400"
        [ngClass]="{ 'rotate-180 dark:bg-green-400': mobileVariantExpanded }"
        (click)="toggleMobileView()"
      >
        <app-svg-icon name="chevron" class="block h-3 w-3 text-slate-400 dark:text-white" />
      </button>
    </div>

    <div [@openClose]="mobileVariantExpanded" class="overflow-hidden">
      <hr class="my-3 border-gray-100 dark:border-slate-700" />
      <a
        [routerLink]="'/' + RouteConstants.depositary + '/' + RouteConstants.transactions"
        [queryParams]="{ type: balanceItem.currency }"
        class="ml-auto flex w-fit items-center gap-x-1 text-sm text-slate-400 dark:text-slate-200"
      >
        История транзакций
        <app-svg-icon name="history" class="block h-4 w-4 text-black dark:text-white" />
      </a>

      <div class="mt-3 grid grid-cols-3 items-start justify-between gap-x-10">
        <button
          class="flex flex-col items-center border-0 px-0 font-medium text-green-400"
          (click)="handleReceiveClick()"
        >
          Получить
          <app-svg-icon
            name="actions/receive"
            class="h-6 w-6 max-md:mt-1 max-md:rounded-full max-md:bg-green-400 max-md:p-1 max-md:text-white"
          />
        </button>
        <button
          class="flex flex-col items-center border-0 px-0 font-medium text-green-400"
          (click)="handleSendClick()"
        >
          Отправить
          <app-svg-icon
            name="actions/send"
            class="h-6 w-6 max-md:mt-1 max-md:rounded-full max-md:bg-green-400 max-md:p-1 max-md:text-white"
          />
        </button>
        <button
          class="flex flex-col items-center border-0 px-0 font-medium text-green-400"
          (click)="handleSwapClick()"
        >
          Обмен
          <app-svg-icon
            name="actions/swap"
            class="h-6 w-6 max-md:mt-1 max-md:rounded-full max-md:bg-green-400 max-md:p-1 max-md:text-white"
          />
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #skeleton>
  <div
    class="grid grid-cols-wallet-balances-table justify-items-end gap-x-6 px-6 font-medium leading-5 max-md:flex max-md:gap-x-0 max-md:px-2 max-md:py-3"
  >
    <div class="flex items-start gap-x-6 justify-self-start max-md:gap-x-2">
      <app-skeleton class="h-16 w-16 rounded-full max-md:h-9 max-md:w-9" />
      <div class="space-y-2">
        <app-skeleton class="h-5 w-28 rounded-md max-md:w-full" />
        <app-skeleton class="h-5 w-28 rounded-md" />
      </div>
    </div>
    <app-skeleton class="h-5 w-18 rounded-md max-md:invisible" />
    <app-skeleton class="h-5 w-18 rounded-md max-md:h-12 max-md:w-10 max-md:grow" />
    <app-skeleton class="h-5 w-18 rounded-md max-md:ml-1 max-md:w-5" />

    <div class="flex items-start gap-x-4 justify-self-start max-md:hidden">
      <button class="btn-text" disabled>Получить</button>
      <button class="btn-text" disabled>Отправить</button>
      <button class="btn-text" disabled>Обмен</button>
    </div>
  </div>
</ng-template>
