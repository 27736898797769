import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CurrencyIconName } from "../../_shared/currency-icon/currency-icon.component";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";

@Component({
  selector: "app-calculator-token-button",
  templateUrl: "./calculator-token-button.component.html",
  styleUrls: ["./calculator-token-button.component.css"],
})
export class CalculatorTokenButtonComponent {
  @Input() currency: CryptoSymbol = CryptoSymbol.Uzs;
  @Input() isActive: boolean = false;

  @Output() onSelect = new EventEmitter<CryptoSymbol>();

  public get currencyName(): string {
    switch (this.currency) {
      case CryptoSymbol.Uzs:
        return "UZS";
      case CryptoSymbol.Usdt:
        return "USDT (TRON)";
      case CryptoSymbol.Trx:
        return "TRX (TRON)";
      case CryptoSymbol.Matic:
        return "MATIC (POLYGON)";
      case CryptoSymbol.PolygonUsdt:
        return "USDT (POLYGON)";
      case CryptoSymbol.Ton:
        return "TON (TON)";
      case CryptoSymbol.TonUsdt:
        return "USDT (TON)";
      case CryptoSymbol.Not:
        return "NOT (TON)";
      case CryptoSymbol.Bitcoin:
        return "BTC (BITCOIN)";
      default:
        return "Unknown";
    }
  }

  public onSelectCurrency() {
    this.onSelect.emit(this.currency);
  }
}
