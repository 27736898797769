import { Injectable } from '@angular/core';

export interface ToastInfo {
  body: string;
  delay?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor() {}

  toasts: ToastInfo[] = [];

  show(body: string, delay?: number) {
    this.toasts.push({ body, delay });
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter((t) => t != toast);
  }
}
