import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ResetPasswordDto } from "src/app/common/DTO/auth/reset-password.dto";
import { Constants } from "src/app/common/constants/constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { AuthErrorCode } from "src/app/common/enums/auth-error-code.enum";
import { lengthEqualValidator } from "src/app/common/validators/length-equal.validator";
import { matchValidator } from "src/app/common/validators/match.validator";
import { numericStrValidator } from "src/app/common/validators/numeric-str.validator";
import { validCodeRangeValidator } from "src/app/common/validators/valid-code-range.validator";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-reset-password-modal",
  templateUrl: "./reset-password-modal.component.html",
  styleUrls: ["./reset-password-modal.component.css"],
})
export class ResetPasswordModalComponent {
  step: "phone" | "password" | "success" = "phone";
  businessError: string | null = null;
  isPending = false;
  otpTimer: number = 0;
  otpInputCount: number = 0;

  phoneForm: FormGroup;
  passwordForm: FormGroup;
  validateConstants = ValidateConstants;

  constructor(
    private readonly _activeModal: NgbActiveModal,
    private readonly _translateService: TranslateService,
    private readonly _authService: AuthService
  ) {
    this.phoneForm = new FormGroup({
      phoneNumber: new FormControl(null, [
        Validators.required,
        numericStrValidator(),
        lengthEqualValidator(ValidateConstants.PhoneLengthWithoutCode),
      ]),
    });

    this.passwordForm = new FormGroup(
      {
        newPsw: new FormControl(null, [
          Validators.required,
          Validators.minLength(ValidateConstants.MinPswLength),
          Validators.maxLength(ValidateConstants.MaxPswLength),
          Validators.pattern(ValidateConstants.PswPattern),
        ]),
        pswRepeat: new FormControl(null, [Validators.required]),
        code: new FormControl(null, [Validators.required, numericStrValidator(), validCodeRangeValidator()]),
      },
      { validators: matchValidator("newPsw", "pswRepeat") }
    );
  }

  onClose() {
    this._activeModal.close();
  }

  async onPhoneSubmit() {
    this.isPending = true;
    this.businessError = null;

    try {
      const isCodeSent = await this.sendCode();
      if (isCodeSent) {
        this.step = "password";
      }
    } catch (error) {
      this.businessError = this._translateService.instant("Common.Unknown_error");
    } finally {
      this.isPending = false;
    }
  }

  async onPasswordSubmit() {
    this.isPending = true;
    this.businessError = null;

    const dto = new ResetPasswordDto();
    dto.phoneNumber = Constants.UzsPhoneCode + this.phoneForm.get("phoneNumber")?.value;
    dto.code = this.passwordForm.get("code")?.value;
    dto.newPassword = this.passwordForm.get("newPsw")?.value;

    try {
      // Check if captcha is not bypassed
      if (this.otpInputCount >= ValidateConstants.MaxOtpTries) {
        this.businessError = this._translateService.instant("Login.Pass_captcha");
        return;
      }

      const res = await this._authService.resetPassword(dto);
      if (res.withError) {
        this.businessError = this.errorParser(res.errorCode);
        // If user inputed wrong OTP, then increment OTP inputs counter
        if (res.errorCode == AuthErrorCode.IncorrectCode) {
          this.otpInputCount++;
        }
      } else {
        this.step = "success";
      }
    } catch (error) {
      this.businessError = this._translateService.instant("Common.Unknown_error");
    } finally {
      this.isPending = false;
    }
  }

  onBack() {
    if (this.step === "password") {
      this.step = "phone";
    }
  }

  async sendCode() {
    const phoneNumber = Constants.UzsPhoneCode + this.phoneForm.get("phoneNumber")?.value;

    try {
      const res = await this._authService.sendPasswordResetCode({ phoneNumber });
      if (res.withError) {
        this.businessError = this.errorParser(res.errorCode);
        return false;
      } else {
        this.businessError = null;
        this.otpTimer = Constants.SecInMin;
        return true;
      }
    } catch (error) {
      this.businessError = this._translateService.instant("Common.Unknown_error");
      return false;
    }
  }

  public get phoneNumberError() {
    const phoneNumber = this.phoneForm.get("phoneNumber");

    if (phoneNumber?.value == null) {
      return null;
    }
    if (phoneNumber.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (phoneNumber.hasError("lengthEqual")) {
      return this._translateService.instant("Login.Phone_length_error");
    }
    return null;
  }

  public captchaResolved(captchaResponse: string) {
    if (captchaResponse && captchaResponse != "") {
      this.otpInputCount = 0;
    }
  }

  private errorParser(errorCode: AuthErrorCode | null) {
    switch (errorCode) {
      case AuthErrorCode.CodeRequestLimitExceeds:
        return this._translateService.instant("Login.Code_request_limit_exceeds");
      case AuthErrorCode.IncorrectCode:
        return this._translateService.instant("Login.Wrong_code_or_expired");
      case AuthErrorCode.InternalError:
        return this._translateService.instant("Common.Unknown_error");
      case AuthErrorCode.UserNotFound:
        return this._translateService.instant("Login.Phone_user_not_found");
      case AuthErrorCode.IncorrectPhoneOrPsw:
        return this._translateService.instant("Login.Wrong_phone_or_pass");
      case AuthErrorCode.DisallowSmsSentTo:
        return this._translateService.instant("Login.Disallow_sms_sent");
      default:
        return this._translateService.instant("Common.Unknown_error");
    }
  }
}
