<footer class="bg-[#f0f0f0] py-10 max-md:pb-1 max-md:pt-5">
  <div
    class="container grid grid-cols-[15.75rem_13.875rem_17rem_15rem] justify-between max-md:flex max-md:flex-col max-md:gap-4"
  >
    <div
      class="space-y-7 text-sm max-md:space-y-2 max-md:border-b max-md:border-border-input-color max-md:pb-4 max-md:text-sm md:font-semibold md:uppercase"
    >
      <img class="w-40 max-md:mx-auto max-md:w-64" src="assets/icons/logo-new.png" alt="Paynet Crypto" />
      <p>{{ "Footer.Description_1" | translate }}</p>
      <p>
        {{ "Footer.Description_2" | translate }}
      </p>
    </div>

    <div
      class="space-y-7 text-sm font-semibold max-md:space-y-2 max-md:border-b max-md:border-border-input-color max-md:pb-4 md:uppercase"
    >
      <a
        [href]="'assets/public-offers/' + language + '/offer-dep-shop-' + language + '.pdf'"
        target="_blank"
        class="text-accent hover:underline"
      >
        {{ "Footer.Public_offer" | translate }}
      </a>
      <p>* - {{ "Footer.Depositary_license" | translate }}</p>
      <p>* - {{ "Footer.Store_license" | translate }}</p>
    </div>

    <div class="space-y-7 text-sm max-md:space-y-2 md:font-semibold md:uppercase">
      <p>
        {{ "Landing.Address" | translate }}: <br />
        {{ "Landing.Full_address" | translate }}
      </p>
      <p></p>
      <p class="whitespace-pre-line">
        {{ "Footer.Schedule" | translate }}
      </p>
    </div>

    <div class="space-y-7 text-sm font-semibold uppercase max-md:hidden">
      <p>
        <span class="block">{{ "Footer.Phone_text" | translate }}:</span>
        <a href="tel:+998712023777"> +998 71 202-37-77 </a>
      </p>
      <p>
        <span class="block">telegram:</span>
        <a href="https://t.me/paynet_crypto_bot" target="_blank" rel="noreferrer noopener" class="underline">
          &#64;paynet_crypto_bot
        </a>
      </p>
      <p>
        <span class="block">{{ "Footer.Email_text" | translate }}:</span>
        <a href="mailto:info@paynetcrypto.uz" class="underline"> info&#64;paynetcrypto.uz </a>
      </p>
    </div>

    <!-- Mobile contacts block -->
    <div class="border-t border-t-border-input-color pt-4 md:hidden">
      <p class="text-sm font-bold">{{ "Footer.Feedback" | translate }}:</p>
      <div class="mt-4 flex items-center justify-around">
        <a href="tel:+998712023777" class="aspect-square w-[4.375rem]">
          <img src="assets/icons/phone.svg" alt="phone" />
        </a>
        <a href="mailto:info@paynetcrypto.uz" class="aspect-square w-[4.375rem]">
          <img src="assets/icons/mail.svg" alt="email" />
        </a>
        <a
          href="https://t.me/paynet_crypto_bot"
          target="_blank"
          rel="noreferrer noopener"
          class="aspect-square w-[4.375rem]"
        >
          <img src="assets/icons/telegram-logo.svg" alt="telegram" />
        </a>
      </div>
    </div>
  </div>

  <hr class="my-7 border-border-input-color max-md:my-4" />

  <div class="flex items-center justify-center gap-16">
    <img src="assets/icons/banks/uzcard.png" alt="Uzcard" class="h-16 w-16 grayscale" />
    <img src="assets/icons/banks/humo.png" alt="Humo" class="h-16 w-16 grayscale" />
  </div>

  <hr class="my-7 border-border-input-color max-md:my-4" />

  <p class="text-center text-xs font-medium">{{ "Footer.Copyright" | translate }}</p>
</footer>
