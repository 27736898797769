import { Component, Input } from "@angular/core";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-copy-btn",
  templateUrl: "./copy-btn.component.html",
  styleUrls: ["./copy-btn.component.css"],
})
export class CopyBtnComponent {
  @Input() value: string = "";

  private timer: any;

  constructor() {}

  copyValueToClipboard(event: any, tooltip: NgbTooltip) {
    event.stopPropagation();

    if (!this.value) return;

    const tempEl = document.createElement("textarea");
    document.body.appendChild(tempEl);
    tempEl.value = this.value;
    tempEl.select();
    document.execCommand("copy", false);
    tempEl.remove();

    this.triggerCopiedMessage(tooltip);
  }

  private triggerCopiedMessage(tooltip: NgbTooltip) {
    tooltip.open();

    this.timer = setTimeout(() => {
      tooltip.close();
    }, 2000);
  }

  ngOnDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
