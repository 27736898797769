export class ToncenterJettonTxDto {
  amount = "";
  custom_payload = "";
  destination = "";
  forward_payload = "";
  forward_ton_amount = "";
  jetton_master = "";
  query_id = "";
  response_destination = "";
  source = "";
  source_wallet = "";
  trace_id = "";
  transaction_aborted = true;
  transaction_hash = "";
  transaction_lt = "0" as `${number}`;
  transaction_now = 0;
}
