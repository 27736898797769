import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-svg-icon",
  templateUrl: "./svg-icon.component.html",
  styleUrls: ["./svg-icon.component.css"],
})
export class SvgIconComponent implements OnInit {
  @Input() public name?: string;

  @Input() public class?: string;

  public svgIcon: any;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnInit(): void {
    if (!this.name) {
      this.svgIcon = "";
      return;
    }
    this.httpClient.get(`assets/icons/${this.name}.svg`, { responseType: "text" }).subscribe({
      next: value => {
        this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
      },
      error: errors => {
        this.svgIcon = "";
      },
    });
  }
}
