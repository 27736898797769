<div class="select">
  <p *ngIf="caption" class="select__caption">{{ caption }}</p>
  <button
    type="button"
    class="select__toggle"
    [class.select__toggle_centered]="centered"
    (click)="toggleSelect()"
  >
    <span>{{ displaySelectedItem() }}</span>
    <svg
      class="select__arrow"
      [class.rotate-180]="isOpen"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4L5.95855 8L2 4"
        stroke-width="1.5"
        stroke-linecap="square"
      />
    </svg>
  </button>

  <ul *ngIf="isOpen" class="select__list">
    <li
      class="select__listItem"
      *ngFor="let item of items"
      (click)="pickItem(item)"
    >
      {{ item }}
    </li>
  </ul>
</div>
