<div class="box flex items-center gap-5 border-accent" [ngClass]="{ 'py-2': withSelect }">
  <img [src]="'assets/icons/banks/' + getBankType() + '.png'" alt="" class="h-16 w-16" />
  <div
    *ngIf="!showConfirmationDelete; else deleteConfirmation"
    class="flex-grow"
    [ngClass]="{ 'space-y-3': !withSelect }"
  >
    <div class="flex items-center justify-between">
      <h3 class="text-xl">{{ card.bankName }}</h3>
      <button *ngIf="!withSelect" (click)="handleFirstDeleteClick()">
        <app-svg-icon name="close-circle-outline" class="block h-7 w-7 stroke-text-primary" />
      </button>
    </div>
    <p class="text-2xl font-bold">{{ card.balance | mfeCustom }} UZS</p>
    <p class="text-xl">{{ card.maskedPan }}</p>
  </div>

  <button
    *ngIf="withSelect"
    class="ml-auto flex h-8 w-8 items-center justify-center rounded-main border-2 border-accent"
    (click)="onClickSelect()"
  >
    <span *ngIf="selected" class="aspect-square w-4 rounded-full bg-accent"></span>
  </button>

  <ng-template #deleteConfirmation>
    <div class="flex items-center justify-between">
      <p class="text-xl text-danger">{{ "Bank.Press_again_to_delete" | translate }}</p>
      <button (click)="deleteCard()">
        <app-svg-icon name="close-circle-outline" class="block h-12 w-12 stroke-danger" />
      </button>
    </div>
  </ng-template>
</div>
