<section class="grid grid-rows-1 gap-3">
  <app-back-button [link]="'/admin/users/' + userId"></app-back-button>

  <h3 class="title">{{ "Transaction.All_transactions" | translate }}</h3>
  <app-tabs
    [tabs]="tokenSwitchValues"
    (onSelect)="switchTxType($event)"
    class="block overflow-x-auto overflow-y-hidden"
  />

  <ng-container *ngIf="!isLoading; else loader">
    <ng-container *ngIf="hasTxs; else noTrans">
      <div class="mt-3 grid grid-rows-1">
        <ng-container *ngIf="isBuySellTab; else bcTxs">
          <app-crypto-transaction-list-item *ngFor="let item of buySellTxs" [txDto]="item" />
        </ng-container>

        <ng-template #bcTxs>
          <app-transaction-list-item
            *ngFor="let item of txs"
            [txDto]="item"
            class="border-t border-gray-100 first:border-none dark:border-slate-700"
          />
        </ng-template>
      </div>

      <div class="flex items-center justify-end gap-x-5 max-md:justify-center">
        <button
          class="btn-flat py-3 max-md:flex-grow"
          (click)="onPrevPage()"
          [disabled]="!hasPrevPage || isLoading"
        >
          <app-svg-icon name="arrow-up" class="block aspect-square w-16 -rotate-[135deg] max-md:w-10" />
        </button>
        <button
          class="btn-flat py-3 max-md:flex-grow"
          (click)="onNextPage()"
          [disabled]="!hasNextPage || isLoading"
        >
          <app-svg-icon name="arrow-up" class="block aspect-square w-16 rotate-45 max-md:w-10" />
        </button>
      </div>
    </ng-container>

    <ng-template #noTrans>
      <div class="mt-3 text-center text-base">{{ "Transaction.No_transactions" | translate }}</div>
    </ng-template>
  </ng-container>

  <ng-template #loader>
    <app-loader></app-loader>
  </ng-template>
</section>
