import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.css"],
})
export class LogoutComponent implements OnInit {
  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthService,
    public readonly _localStorage: LocalStorageService
  ) {}

  async ngOnInit(): Promise<void> {
    await this._authService.logout();
    await this._localStorage.clearTokens();
    await this._localStorage.removeUserData();
    this._router.navigateByUrl("/");
  }
}
