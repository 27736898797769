<button
  (click)="onClose()"
  class="absolute right-0 top-[-4.5rem] !m-0 flex h-16 w-16 items-center justify-center rounded-tw bg-select-bg"
>
  <img src="assets/icons/close-circle-outline-white.svg" alt="close" class="h-7 w-7" />
</button>

<ng-container [ngSwitch]="step">
  <!-- Enter phone -->
  <ng-template ngSwitchCase="phone">
    <h2 class="underlined-btn underlined-btn_active text-center">{{ "Login.Password_reset" | translate }}</h2>
    <form [formGroup]="phoneForm" (ngSubmit)="onPhoneSubmit()" class="mt-6">
      <app-input
        [title]="'Login.Phone_number' | translate"
        type="phone"
        placeholder="11 222 33 44"
        id="phoneNumber"
        formControlName="phoneNumber"
        [errorMsg]="phoneNumberError"
        mask="00 000 00 00"
        [autocomplete]="'off'"
        class="mt-6"
      />
      <p *ngIf="businessError" class="mt-2 text-sm font-medium text-danger">{{ businessError }}</p>
      <button type="submit" class="btn mt-6 w-full" [disabled]="phoneForm.invalid || isPending">
        {{ "Common.Next" | translate }}
      </button>
    </form>
  </ng-template>

  <!-- Set new password and enter code -->
  <ng-template ngSwitchCase="password">
    <h2 class="underlined-btn underlined-btn_active text-center">{{ "Login.Password_reset" | translate }}</h2>
    <form [formGroup]="passwordForm" (ngSubmit)="onPasswordSubmit()" class="mt-6 flex flex-col space-y-6">
      <app-input
        type="text"
        [title]="'Profile.New_password' | translate"
        placeholder="********"
        formControlName="newPsw"
        id="newPsw"
        [autocomplete]="'off'"
      />
      <app-input
        type="text"
        [title]="'Profile.Repeat_new_password' | translate"
        placeholder="********"
        formControlName="pswRepeat"
        id="pswRepeat"
        [autocomplete]="'off'"
      />
      <app-input
        type="text"
        [title]="'Login.Sms_code' | translate"
        placeholder="------"
        formControlName="code"
        id="code"
        [timer]="otpTimer"
        (onRefresh)="sendCode()"
        [autocomplete]="'off'"
      />
      <app-password-checker
        [password]="passwordForm.get('newPsw')?.value"
        [confirmPassword]="passwordForm.get('pswRepeat')?.value"
      />

      <re-captcha
        *ngIf="otpInputCount >= validateConstants.MaxOtpTries"
        (resolved)="captchaResolved($event)"
      ></re-captcha>

      <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>

      <div class="mt-6 flex items-stretch gap-6">
        <button
          type="button"
          class="flex w-16 items-center justify-center rounded-xl bg-select-bg px-5"
          (click)="onBack()"
        >
          <img src="assets/icons/arrow-back-outline.svg" alt="back" />
        </button>
        <button
          type="submit"
          class="btn w-full"
          [disabled]="passwordForm.invalid || isPending || otpInputCount >= validateConstants.MaxOtpTries"
        >
          {{ "Login.Change_password" | translate }}
        </button>
      </div>
    </form>
  </ng-template>

  <!-- Success -->
  <ng-template ngSwitchCase="success">
    <h2 class="underlined-btn underlined-btn_active text-center">
      {{ "Password_change.Success" | translate }}
    </h2>
    <p class="mt-6 text-xl">
      {{ "Password_change.Success_msg" | translate }}
    </p>
    <button class="btn mt-6 w-full" (click)="onClose()">{{ "Common.Close" | translate }}</button>
  </ng-template>
</ng-container>
