import {ValidateConstants} from "src/app/common/constants/validate.constants";
import {AbstractControl, ValidationErrors} from "@angular/forms";

export function kycLengthValidator() {
  return (control:AbstractControl) : ValidationErrors | null => {
    if (control.value == null) {
      return null;
    }

    const lengthSum = (control.value as string)
      .split(" ")
      .reduce((acc, cur) => acc + cur.length, 0);

    return lengthSum <= ValidateConstants.MaxKycNameLength ? null : { kycLength: true };
  }
}
