export enum BankingTransactionStatus {
  PREPARE = 1,
  CONFIRM = 2,
  CONFIRM_FAILED = 3,
  PENDING = 4,
  SUCCESS = 5,
  ERROR = 6,
  REVERSE_ERROR = 7,
  REVERSED = 8,
  NOT_SEND = 9,
}
