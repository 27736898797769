import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { EnvService } from "./env.service";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { CommissionErrorCode } from "src/app/common/enums/commission-error-code.enum";
import { CommissionDto } from "src/app/common/DTO/commission/commission.dto";
import { Constants } from "src/app/common/constants/constants";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { AddCommissionDto } from "src/app/common/DTO/commission/add-commission.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { TronService } from "./tron.service";
import { PolygonService } from "./polygon.service";
import { TonService } from "./ton.service";

interface GasPrice {
  polygonGasPrice: number;
}

@Injectable({
  providedIn: "root",
})
export class CommissionService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _envService: EnvService,
    private readonly _tronService: TronService,
    private readonly _polygonService: PolygonService,
    private readonly _tonService: TonService
  ) {}

  public async getAllCommission() {
    const uri = `${this._envService.serverUrl}${UrnConstants.CommissionAll}`;
    const result = new HttpResultDto<CommissionErrorCode, CommissionDto[]>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as CommissionDto[];
      result.params = res;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }
    return result;
  }

  public async getCommissionWithParams(amount: number, currency: CryptoSymbol) {
    const params = `?Amount=${amount}&Currency=${currency}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.Commission}${params}`;
    const result = new HttpResultDto<CommissionErrorCode, CommissionDto>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as CommissionDto;
      result.params = res;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }
    return result;
  }

  public async addCommission(dto: AddCommissionDto) {
    const uri = `${this._envService.serverUrl}${UrnConstants.Commission}`;
    const result = new HttpResultDto<CommissionErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.post(uri, dto));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async deleteCommission(id: number) {
    const uri = `${this._envService.serverUrl}${UrnConstants.Commission}/${id}`;
    const result = new HttpResultDto<CommissionErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.delete(uri));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async calculateNetworkCommission({
    currency,
    gasPrices,
    amount,
    toAddress,
    fromAddress,
  }: {
    currency: CryptoSymbol;
    gasPrices: GasPrice;
    amount: string;
    toAddress?: string;
    fromAddress?: string;
  }) {
    const adjustment = 1.1;

    if (currency === CryptoSymbol.Trx) {
      const defaultFee = 0.002;
      const fee = await this._tronService.estimateTrxTransferCost();
      return (fee || defaultFee) * adjustment;
    }

    if (currency === CryptoSymbol.Usdt) {
      const defaultFee = 27;
      const fee = await this._tronService.estimateUsdtTransferCost();
      return (fee || defaultFee) * adjustment;
    }

    if (currency === CryptoSymbol.Matic) {
      const fee = await this._polygonService.estimateMaticTransferCost(
        amount,
        gasPrices.polygonGasPrice,
        toAddress
      );
      return fee * adjustment;
    }

    if (currency === CryptoSymbol.PolygonUsdt) {
      const fee = await this._polygonService.estimatePolygonUsdtTransferCost(
        amount,
        gasPrices.polygonGasPrice,
        fromAddress,
        toAddress
      );
      return fee * adjustment;
    }

    if (currency === CryptoSymbol.Ton || currency === CryptoSymbol.TonUsdt || currency === CryptoSymbol.Not) {
      const fee = await this._tonService.estimateTonTransferCost();
      return fee * adjustment;
    }

    if (currency === CryptoSymbol.Bitcoin) {
      return 0.000042 * adjustment;
    }

    return 0;
  }

  private getErrorCode(error: string): CommissionErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return CommissionErrorCode.InternalError;
      case Constants.Unauthorized:
        return CommissionErrorCode.Unauthorized;
      default:
        return CommissionErrorCode.InternalError;
    }
  }
}
