import { Component, HostListener } from "@angular/core";
import { DomainEventsConstants } from "src/app/common/constants/domain-event.constants";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  templateUrl: "./event-listener-page.component.html",
  styleUrls: ["./event-listener-page.component.css"],
})
export class EventListenerPageComponent {
  constructor(private _localStorage: LocalStorageService) {}

  @HostListener("window:message", ["$event"])
  async onMessage(event: MessageEvent) {
    const data = event.data;
    switch (data.key) {
      case DomainEventsConstants.authTokens:
        await this._localStorage.saveTokens(data.params);
        break;
      case DomainEventsConstants.userData:
        await this._localStorage.saveUserData(data.params);
        break;
      case DomainEventsConstants.userLogout:
        await this._localStorage.clearTokens();
        await this._localStorage.removeUserData();
        break;
      case DomainEventsConstants.sidebarState:
        await this._localStorage.saveSidebarState(data.params);
        break;
      case DomainEventsConstants.language:
        await this._localStorage.saveLanguage(data.params);
        break;
      default:
        break;
    }
  }
}
