export class StorageConstants {
  public static readonly TokenKey = "AccessToken";
  public static readonly RefreshKey = "RefreshToken";
  public static readonly TokenLifetimeKey = "TokenExpiryAt";
  public static readonly RefreshLifetimeKey = "RefreshLifetime";
  public static readonly UserData = "UserData";
  public static readonly Language = "Language";
  public static readonly PlatformType = "PlatformType";
  public static readonly SessionExpiration = "SessionExpirationAt";
  public static readonly SidebarState = "SidebarStateOpen";
  public static readonly isWebVerification = "isWebVerification";
}
