<div *ngIf="internalError" class="bg-invalid-color text-center">
  <div class="text-text-secondary">
    {{ "Common.Unknown_error" | translate }}
  </div>
</div>

<div
  class="mb-4.5 flex items-center justify-between rounded-2xl border border-gray-100 bg-blue-50 px-9 py-6 dark:border-slate-800 dark:bg-slate-800 max-md:flex-col max-md:items-start max-md:p-4"
>
  <div class="space-y-2 text-slate-400">
    <h2 class="text-lg dark:text-slate-200 max-md:text-sm">Баланс</h2>
    <span class="block text-3xl font-semibold text-black dark:text-white max-md:text-2xl">
      {{ totalUzsBalance | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS
    </span>
    <span class="block max-md:text-sm">Учитываем только UZS и USD</span>
  </div>

  <div
    class="grid grid-cols-3 gap-x-4 dark:border-slate-400 max-md:mt-4 max-md:w-full max-md:justify-between max-md:gap-x-10 max-md:border-t max-md:border-gray-100 max-md:pt-2"
  >
    <button
      class="btn-outline btn-medium gap-x-3 max-md:flex-col max-md:border-none max-md:p-0 max-md:font-medium"
      (click)="openReceiveModal()"
    >
      <span>Получить</span>
      <app-svg-icon
        name="actions/receive"
        class="h-6 w-6 max-md:mt-1 max-md:rounded-full max-md:bg-green-400 max-md:p-1 max-md:text-white"
      />
    </button>

    <button
      class="btn-outline btn-medium gap-x-3 max-md:flex-col max-md:border-none max-md:p-0 max-md:font-medium"
      (click)="openSendModal()"
    >
      <span>Отправить</span>
      <app-svg-icon
        name="actions/send"
        class="h-6 w-6 max-md:mt-1 max-md:rounded-full max-md:bg-green-400 max-md:p-1 max-md:text-white"
      />
    </button>

    <button
      class="btn-outline btn-medium gap-x-3 max-md:flex-col max-md:border-none max-md:p-0 max-md:font-medium"
      (click)="openSwapModal()"
    >
      <span>Обмен</span>
      <app-svg-icon
        name="actions/swap"
        class="h-6 w-6 max-md:mt-1 max-md:rounded-full max-md:bg-green-400 max-md:p-1 max-md:text-white"
      />
    </button>
  </div>
</div>

<div
  class="space-y-6 rounded-2xl border border-gray-100 bg-blue-50 px-9 py-6 text-slate-400 dark:border-slate-800 dark:bg-slate-800 max-md:border-none max-md:bg-transparent max-md:p-0 dark:max-md:bg-transparent"
>
  <div class="space-y-2 max-md:space-y-1">
    <h2 class="text-xl font-semibold dark:text-white max-md:text-lg">Ваши активы и курсы криптовалют</h2>
    <p class="font-medium dark:text-slate-200 max-md:text-sm">*Цены не включают комиссию обмена</p>
  </div>

  <div class="space-y-3">
    <div
      class="grid grid-cols-wallet-balances-table justify-items-end gap-x-6 rounded-xl bg-red-50 px-6 py-3 text-sm font-medium dark:bg-slate-900 dark:text-white max-md:hidden"
    >
      <span class="justify-self-start">Крипто-активы</span>
      <span>Всего</span>
      <span>Оценка в USD</span>
      <span>Оценка в сумах</span>
      <span class="justify-self-start px-6">Действие</span>
    </div>

    <div class="flex flex-col gap-y-4 max-md:gap-y-0">
      <ng-container *ngIf="wallets.length > 0; else balancesSkeleton">
        <ng-container *ngFor="let wallet of wallets">
          <ng-container *ngFor="let balance of wallet.balances">
            <app-balance-row
              [balanceItem]="balance"
              [rates]="rates"
              (receiveClick)="openReceiveModal(wallet, balance)"
              (sendClick)="openSendModal(wallet, balance)"
              (swapClick)="openSwapModal(balance)"
            />
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #balancesSkeleton>
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
      </ng-template>
    </div>
  </div>
</div>
