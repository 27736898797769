<div class="box flex justify-between items-start" (click)="handleClick()">
  <div>
    <h5 class="text-base font-medium">{{ txDestination }}</h5>
    <div class="flex items-center gap-2 text-sm font-light max-md:text-xs">
      <span>{{ txDto.createdAt | customDate : "full" }}</span>
    </div>
    <div class="text-sm flex items-center md:hidden" *ngIf="txDto.cryptoTxHash != ''">
      <span class="font-light mr-2 lowercase">{{ "Transaction.Hash" | translate }}:</span>
      <a target="_blank" [href]="'https://tronscan.io/#/transaction/' + txDto.cryptoTxHash" class="truncate max-w-[6.75rem]">
        {{ txDto.cryptoTxHash }}
      </a>
      <app-copy-btn [value]="txDto.cryptoTxHash" />
    </div>
    <div class="mt-2 max-md:mt-0 max-md:max-w-[14.5rem]">
      <div class="text-sm flex items-center max-md:text-xs">
        <span class="font-light mr-2">{{ "Crypto_transaction.Bank" | translate }}:</span>
        <span>{{ bankingStatus }}</span>
      </div>
      <div class="text-sm flex items-end">
        <span class="font-light mr-2">{{ "Crypto_transaction.Blockchain" | translate }}:</span>
        <span class="truncate">{{ internalStatus }}</span>
      </div>
    </div>
  </div>

  <div class="text-right">
    <div class="text-xl font-medium">
      {{ getCurrencyValue(txDto.toAmount, txDto.toCurrency) | mfeCustom }}
      {{ getCurrencyName(txDto.toCurrency) }}
    </div>
    <div class="text-sm">
      {{ getCurrencyValue(txDto.fromAmount, txDto.fromCurrency) | mfeCustom }}
      {{ getCurrencyName(txDto.fromCurrency) }}
    </div>
    <div class="text-sm flex items-center max-md:hidden" *ngIf="txDto.cryptoTxHash != ''">
      <span class="font-light mr-2 lowercase">{{ "Transaction.Hash" | translate }}:</span>
      <a target="_blank" [href]="'https://tronscan.io/#/transaction/' + txDto.cryptoTxHash" class="truncate max-w-[9.375rem]">
        {{ txDto.cryptoTxHash }}
      </a>
      <app-copy-btn [value]="txDto.cryptoTxHash" />
    </div>
  </div>
</div>
