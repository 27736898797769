<section class="admin-body">
  <app-back-button link="/admin/home"></app-back-button>

  <div class="flex items-center justify-between">
    <h3 class="title">{{ "Admin.Users.Title" | translate }}</h3>
    <div class="searchInput">
      <input
        type="search"
        name="userSearch"
        id="userSearch"
        [(ngModel)]="search"
        (keyup.enter)="onSearch()"
      />
      <button type="button" class="searchInput__btn" (click)="onSearch()">
        <img src="assets/icons/search.svg" alt="Search" />
      </button>
    </div>
  </div>

  <div class="grid grid-cols-3">
    <div>
      <app-dropdown [items]="userIdentificationFilterNames" (onSelect)="onIdentificationFilterSelect($event)">
        <button class="flex items-center gap-2">
          <p class="flex w-[15rem] justify-items-start">
            {{ "Admin.Users.Status" | translate }}:
            {{ userFilterNameByIdentificationOption(selectedIdentificationFilter) }}
          </p>
          <img src="assets/icons/arrow.svg" alt="lang-arrow" class="w-3" />
        </button>
      </app-dropdown>
    </div>

    <div>
      <app-dropdown [items]="userActivationFilterNames" (onSelect)="onActivationFilterSelect($event)">
        <button class="flex items-center gap-2">
          <p class="flex w-[12rem] justify-items-start">
            {{ "Admin.Users.Activity" | translate }}:
            {{ userFilterNameByActivationOption(selectedActivationFilter) }}
          </p>
          <img src="assets/icons/arrow.svg" alt="lang-arrow" class="w-3" />
        </button>
      </app-dropdown>
    </div>

    <div>
      <p class="font-bold">{{ "Admin.Users.Overall_users" | translate }}: {{ totalCount }}</p>
    </div>
  </div>

  <table class="adminTable">
    <thead>
      <tr>
        <th scope="col">{{ "Admin.Users.Phone_number" | translate }}</th>
        <th scope="col">{{ "Admin.Users.Status" | translate }}</th>
        <th scope="col">{{ "Admin.Users.Activity" | translate }}</th>
        <th scope="col">{{ "Admin.Users.Registration_date" | translate }}</th>
        <th scope="col">{{ "Admin.Users.Profile" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users">
        <td>{{ user.phoneNumber | phoneDisplayer }}</td>
        <td>{{ renderStatus(user) | translate }}</td>
        <td>{{ isActive(user) }}</td>
        <td>
          {{ user.createdAt.toString().split("T")[0] }}
          {{ user.createdAt.toString().split("T")[1].split(".")[0] }}
        </td>
        <td>
          <a
            class="h-6 w-6"
            [routerLink]="user.id.toString()"
            [title]="'Admin.Users.Open_user_page' | translate"
            target="_blank"
          >
            <img src="assets/icons/link.svg" alt="Accept" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>

  <ngb-pagination
    [collectionSize]="totalCount"
    [(page)]="page"
    [pageSize]="pageSize"
    [maxSize]="3"
    [boundaryLinks]="true"
    (pageChange)="requestAllUsers()"
  >
  </ngb-pagination>
</section>
