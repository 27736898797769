<ng-container [ngSwitch]="step">
  <ng-template ngSwitchCase="phone">
    <form [formGroup]="phoneForm" (ngSubmit)="submitPhone()" class="flex flex-col space-y-6">
      <app-input
        type="phone"
        [title]="'Login.Phone_number' | translate"
        placeholder="11 222 33 44"
        formControlName="phoneNumber"
        id="phoneNumber"
        [errorMsg]="phoneNumberError"
        mask="00 000 00 00"
        [autofocus]="true"
      />

      <input type="checkbox" formControlName="checkDepositary" class="customCheckbox" id="checkDepositary" />
      <label for="checkDepositary">
        <span>
          {{ "Login.Agree_1" | translate }}
          <a
            [href]="'assets/public-offers/' + language + '/offer-dep-' + language + '.pdf'"
            target="_blank"
            class="text-accent"
            >{{ "Login.Agree_2" | translate }}</a
          >
        </span>
      </label>

      <input type="checkbox" formControlName="checkShop" class="customCheckbox" id="checkShop" />
      <label for="checkShop">
        <span>
          {{ "Login.Agree_1" | translate }}
          <a
            [href]="'assets/public-offers/' + language + '/offer-shop-' + language + '.pdf'"
            target="_blank"
            class="text-accent"
            >{{ "Login.Agree_3" | translate }}</a
          >
        </span>
      </label>

      <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>

      <button type="submit" class="btn w-full" [disabled]="phoneForm.invalid || isPending">
        {{ "Common.Next" | translate }}
      </button>
    </form>
  </ng-template>

  <ng-template ngSwitchCase="password">
    <form [formGroup]="passwordForm" (ngSubmit)="submitPassword()" class="flex flex-col space-y-6">
      <app-input
        type="password"
        [title]="'Login.Password' | translate"
        placeholder="********"
        formControlName="psw"
        id="psw"
        [autocomplete]="'off'"
        [autofocus]="true"
        [withPasswordToggle]="true"
      />
      <app-input
        type="password"
        [title]="'Login.Repeat_password' | translate"
        placeholder="********"
        formControlName="pswRepeat"
        id="pswRepeat"
        [autocomplete]="'off'"
        [withPasswordToggle]="true"
      />
      <app-password-checker
        [password]="passwordForm.get('psw')?.value"
        [confirmPassword]="passwordForm.get('pswRepeat')?.value"
      />

      <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>

      <div class="mt-6 flex items-stretch gap-6">
        <button
          type="button"
          class="flex w-16 items-center justify-center rounded-xl bg-select-bg px-5"
          (click)="onBack()"
        >
          <img src="assets/icons/arrow-back-outline.svg" alt="back" />
        </button>
        <button type="submit" class="btn w-full" [disabled]="passwordForm.invalid || isPending">
          {{ "Common.Next" | translate }}
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template ngSwitchCase="otp">
    <form [formGroup]="otpForm" (ngSubmit)="submitOtp()">
      <app-input
        type="number"
        [title]="'Login.Sms_code' | translate"
        placeholder="------"
        formControlName="code"
        id="otp"
        [errorMsg]="codeError"
        [timer]="otpTimer"
        (onRefresh)="sendConfirmCode()"
        [autofocus]="true"
      />

      <re-captcha
        *ngIf="otpInputCount >= validateConstants.MaxOtpTries"
        (resolved)="captchaResolved($event)"
      ></re-captcha>

      <p *ngIf="businessError" class="mt-6 text-sm font-medium text-invalid-color">{{ businessError }}</p>

      <div class="mt-6 flex items-stretch gap-6">
        <button
          type="button"
          class="flex w-16 items-center justify-center rounded-xl bg-select-bg px-5"
          (click)="onBack()"
        >
          <img src="assets/icons/arrow-back-outline.svg" alt="back" />
        </button>
        <button type="submit" class="btn w-full" [disabled]="otpForm.invalid || isPending">
          {{ "Login.Enter" | translate }}
        </button>
      </div>
    </form>
  </ng-template>
</ng-container>
