import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  filter,
  from,
  mergeMap,
  Observable,
  switchMap,
  take,
  throwError,
} from "rxjs";
import { EnvService } from "src/app/services/env.service";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { AuthService } from "src/app/services/auth.service";
import { SuccessLoginDto } from "src/app/common/DTO/auth/success-login.dto";
import { Router } from "@angular/router";
import { TimeHelperUtil } from "src/app/common/utils/time-helper.util";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { SessionService } from "src/app/services/session.service";
import { ApiResponseDto } from "../DTO/api-response.dto";
import { EventTypeConstants } from "../constants/event-type.constants";
import { EventData } from "../models/event-data";
import { EventBusService } from "src/app/services/event-bus.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private readonly _ignoredUrls: string[];
  private readonly _verifyKycUrl: string;
  private readonly _getCurrencyCommissionUrl: string;

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _envService: EnvService,
    private readonly _authService: AuthService,
    private readonly _sessionService: SessionService,
    private readonly _routes: Router,
    private readonly _eventBusService: EventBusService
  ) {
    this._ignoredUrls = [
      `${_envService.serverUrl}${UrnConstants.SignupUrn}`,
      `${_envService.serverUrl}${UrnConstants.LoginUrn}`,
      `${_envService.serverUrl}${UrnConstants.RefreshTokenUrn}`,
      `${_envService.serverUrl}${UrnConstants.ApprovePhoneUrn}`,
      `${_envService.serverUrl}${UrnConstants.SendConfirmCodeUrn}`,
      `${_envService.serverUrl}${UrnConstants.LoginAdminUrn}`,
      `${_envService.serverUrl}${UrnConstants.GetRates}`,
      `${_envService.serverUrl}${UrnConstants.SendPasswordResetCodeUrn}`,
      `${_envService.serverUrl}${UrnConstants.ResetPasswordUrn}`,
      `${_envService.serverUrl}${UrnConstants.CheckPhoneIsExistsUrn}`,
      `${_envService.serverUrl}${UrnConstants.ValidateLoginUrn}`,
      `${_envService.serverUrl}${UrnConstants.LimitPanelAll}`,
      `${_envService.serverUrl}${UrnConstants.CommissionAll}`,
    ];

    this._verifyKycUrl = `${_envService.serverUrl}${UrnConstants.VerifyKyc}`;
    this._getCurrencyCommissionUrl = `${_envService.serverUrl}${UrnConstants.Commission}?Amount`;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      this._ignoredUrls.includes(req.url) ||
      req.url.includes(this._envService.tronGridUrl) ||
      req.url.includes(this._envService.tronScanApiUrl) ||
      req.url.includes(this._envService.polygonScanApiUrl) ||
      req.url.includes(this._envService.tonCenterApiUrl) ||
      req.url.includes(this._verifyKycUrl) ||
      req.url.includes(this._getCurrencyCommissionUrl)
    ) {
      return next.handle(req);
    }

    const request = req.clone();

    return from(this.getTokens()).pipe(
      switchMap(tokens => {
        const req = this.addToken(request, tokens.token);
        return next.handle(req);
      }),
      catchError(error => {
        console.log(error);
        if (error.message === "Incorrect or not valid tokens") {
          this.logout();
        }

        if (
          (error instanceof HttpErrorResponse && error.status === 401) ||
          error.message === "Token is expired"
        ) {
          return this.handle401Error(req, next);
        }

        if (error.status === 400 && req.url.includes("refresh")) {
          this.logout();
        }

        return throwError(() => error);
      })
    );
  }

  private async getTokens() {
    const token = await this._localStorage.accessToken();
    const tokenLifetime = await this._localStorage.accessTokenLifetime();
    const refresh = await this._localStorage.refreshToken();
    const refreshLifetime = await this._localStorage.refreshTokenLifetime();

    if ((token == null || tokenLifetime == null) && (refresh == null || refreshLifetime == null)) {
      throw new Error("Incorrect or not valid tokens");
    }

    const isValidToken = TimeHelperUtil.checkLifetimeIsValid(tokenLifetime);
    const isValidRefresh = TimeHelperUtil.checkLifetimeIsValid(refreshLifetime);

    if (!isValidToken && isValidRefresh) {
      throw new Error("Token is expired");
    }

    return { token, tokenLifetime, refresh, refreshLifetime };
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (!request.url.includes("assets/icons")) {
      if (this._sessionService.isSessionExpired) {
        this._sessionService.onSessionExpired();
      } else {
        this._sessionService.resetTimeout();
      }
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private async logout() {
    this._eventBusService.dispatch(new EventData(EventTypeConstants.Logout));
    await this._localStorage.clearTokens();
    await this._localStorage.removeUserData();
    this._routes.navigateByUrl("/");
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(this._authService.refreshTokens()).pipe(
        mergeMap((data: Observable<ApiResponseDto<SuccessLoginDto>>) => data),
        switchMap((data: ApiResponseDto<SuccessLoginDto>) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(data);

          return from(this._localStorage.saveTokens(data.data)).pipe(
            mergeMap(() => next.handle(this.addToken(request, data.data.accessToken)))
          );
        }),
        catchError(err => {
          this.isRefreshing = false;

          if (err.status === 400 || err.status === 403) {
            this.logout();
          }

          return throwError(() => err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(data => data != null),
        take(1),
        switchMap((data: ApiResponseDto<SuccessLoginDto>) => {
          return from(this._localStorage.saveTokens(data.data)).pipe(
            mergeMap(() => next.handle(this.addToken(request, data.data.accessToken)))
          );
        })
      );
    }
  }
}

export const tokenInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
];
