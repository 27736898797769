import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { EventData } from "src/app/common/models/event-data";
import { TelegramMiniAppHelper } from "src/app/common/utils/telegram-mini-app-helper.util";
import { EnvService } from "src/app/services/env.service";
import { EventBusService } from "src/app/services/event-bus.service";
import { KycService } from "src/app/services/kyc.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { UserService } from "src/app/services/user.service";

enum MyIDStatus {
  EXCEPTION = -1,
  IN_PROGRESS = 0,
  LIVENESS_PASSED = 1,
  LIVENESS_FAILED = 2,
  RETRY = 3,
}

interface MyIdEvent {
  source?: "MyIDWebSDK";
  status?: MyIDStatus;
  data?: {
    auth_code?: string;
    result_code?: number;
    result_note?: string;
  };
}

@Component({
  selector: "app-new-my-id-verification",
  templateUrl: "./new-my-id-verification.component.html",
  styleUrls: ["./new-my-id-verification.component.css"],
})
export class NewMyIdVerificationComponent implements OnInit, OnDestroy {
  @ViewChild("myIdIframe") iframeRef: ElementRef<HTMLIFrameElement> | null = null;

  step: "form" | "photo" | "qr-code" | "success" | "error" = "form";
  myIdUrl = "";
  isCameraAccessPrompting = true;
  errorMessage = "";
  adviseList: string[] = [
    "New_my_id_verification.Advices.1",
    "New_my_id_verification.Advices.2",
    "New_my_id_verification.Advices.3",
  ];
  walletRoute = RouteConstants.wallet;
  isTelegramMiniApp = TelegramMiniAppHelper.isMiniApp();

  formGroup: FormGroup;

  constructor(
    private readonly envService: EnvService,
    private readonly kycService: KycService,
    private readonly userService: UserService,
    private readonly localStorage: LocalStorageService,
    private readonly eventBusService: EventBusService,
    private readonly translateService: TranslateService
  ) {
    this.formGroup = new FormGroup({
      passport: new FormControl(null, [Validators.required]),
      birthDate: new FormControl(null, [Validators.required]),
    });
  }

  async ngOnInit(): Promise<void> {
    const availableDevices = await navigator.mediaDevices.enumerateDevices();

    const isCameraAvailable = availableDevices.some(device => device.kind === "videoinput");

    if (!isCameraAvailable) {
      this.isCameraAccessPrompting = false;
      const redirectLink = await this.buildRedirectLink();
      if (!redirectLink) {
        this.errorMessage = "Debug message: Server responded with error";
        this.step = "error";
      } else {
        this.step = "qr-code";
      }
    }
  }

  async ngOnDestroy(): Promise<void> {
    await this.kycService.cancelVerificationSession();
  }

  private async buildIframeUrl(): Promise<string> {
    const passport = (this.formGroup.controls["passport"]?.value as string) ?? "";
    const birthDate = (this.formGroup.controls["birthDate"]?.value as string) ?? "";

    await this.kycService.cancelVerificationSession();
    const res = await this.kycService.startVerificationSession();
    if (res.withError || !res.params?.sessionId) {
      return "";
    }

    const baseUrl = this.envService.isProduction ? "https://web.myid.uz/" : "https://web.devmyid.uz/";

    let language = await this.localStorage.getLanguage();
    if (!language) {
      language = this.translateService.getDefaultLang();
    }

    const urlSearchParams = new URLSearchParams({
      session_id: res.params.sessionId,
      pass_data: passport,
      birth_date: this.formatInputDateString(birthDate),
      iframe: "true",
      lang: String(language),
    });

    return `${baseUrl}?${urlSearchParams.toString()}`;
  }

  private formatInputDateString(dateString: string): string {
    if (dateString.length === 0) {
      return "";
    }
    const [day, month, year] = dateString.split(".");
    return `${year}-${month}-${day}`;
  }

  private async buildRedirectLink(): Promise<string> {
    const res = await this.kycService.buildMyIdQrPayload();
    if (res.withError || !res.params) {
      return "";
    }

    const baseUrl = this.envService.isProduction
      ? "https://myid.uz/api/v1/oauth2/authorization"
      : "https://devmyid.uz/api/v1/oauth2/authorization";

    const params = new URLSearchParams({
      client_id: res.params.clientId,
      method: res.params.verificationMethod,
      response_type: "code",
      scope: "address,contacts,doc_data,common_data",
      state: res.params.securityStamp,
    });

    // Handles My ID issue with redirect uri validation
    if (!this.envService.isProduction) {
      params.set(
        "redirect_uri",
        `${this.envService.domain}${RouteConstants.myIdRedirect}?mode=telegramMiniApp`
      );
    }

    return `${baseUrl}?${params.toString()}`;
  }

  async handleFormSubmit(): Promise<void> {
    const iframeUrl = await this.buildIframeUrl();
    if (!iframeUrl) {
      this.errorMessage = "Debug message: Server responded with error";
      this.step = "error";
      return;
    }
    console.log("iframe url:", iframeUrl);
    this.myIdUrl = iframeUrl;
    this.isCameraAccessPrompting = false;
    this.step = "photo";
  }

  @HostListener("window:resize")
  onWindowResize(): void {
    this.handleWindowResize();
  }

  handleWindowResize() {
    this.iframeRef?.nativeElement?.contentWindow?.postMessage?.(
      {
        cmd: "screen",
        // screen: window.screen,
        height: window.innerHeight,
        width: window.innerWidth,
      },
      "*"
    );
  }

  @HostListener("window:message", ["$event"])
  onIframeMessageEvent(event: MessageEvent<MyIdEvent>): void {
    if (event.data.source !== "MyIDWebSDK") {
      return;
    }
    switch (event.data.status) {
      case MyIDStatus.RETRY: {
        console.log("retry event data:", event.data);
        break;
      }
      case MyIDStatus.IN_PROGRESS: {
        console.log("status event data:", event.data);
        this.handleIframeStatusEvent(event.data);
        break;
      }
      case MyIDStatus.LIVENESS_FAILED: {
        console.log("error event data:", event.data);
        this.handleIframeFailEvent(event.data);
        break;
      }
      case MyIDStatus.LIVENESS_PASSED: {
        console.log("success event data:", event.data);
        this.handleIframeSuccessEvent(event.data);
        break;
      }
      default: {
        return;
      }
    }
  }

  private handleIframeStatusEvent(data: MyIdEvent): void {
    this.isCameraAccessPrompting = false;
    this.errorMessage = data?.data?.result_note ?? "";
  }

  // private async handleIframeCameraAccessErrorEvent(): Promise<void> {
  //   const redirectLink = await this.buildRedirectLink();
  //   if (!redirectLink) {
  //     this.errorMessage = "Debug message: Server responded with error";
  //     this.step = "error";
  //     return;
  //   }
  //   this.step = "qr-code";
  // }

  private async handleIframeFailEvent(data: MyIdEvent): Promise<void> {
    this.errorMessage = data?.data?.result_note || "Debug message: My ID responded with error";
    this.step = "error";
  }

  private async handleIframeSuccessEvent(data: MyIdEvent): Promise<void> {
    if (!data?.data?.auth_code) {
      this.errorMessage = "Debug message: Missing auth code";
      this.step = "error";
      return;
    }
    const sessionResult = await this.kycService.getVerificationSessionResult({
      authCode: data.data.auth_code,
    });
    if (sessionResult.withError) {
      this.errorMessage = sessionResult.params?.errorMessage || "Debug message: Server responded with error";
      this.step = "error";
      return;
    }
    await this.updateUserVerificationStatus();
    this.step = "success";
  }

  private async updateUserVerificationStatus() {
    const response = await this.userService.getMe();
    if (response.withError) {
      return;
    }
    await this.localStorage.saveUserData(response.params!);
    this.eventBusService.dispatch(
      new EventData(EventTypeConstants.UpdateVerificationStatus, response.params?.kyc)
    );
  }

  async restartVerification(): Promise<void> {
    const iframeUrl = await this.buildIframeUrl();
    if (!iframeUrl) {
      this.errorMessage = "Debug message: Server responded with error";
      this.step = "error";
      return;
    }
    this.myIdUrl = iframeUrl;
    this.step = "photo";
  }

  redirectToMyId() {
    TelegramMiniAppHelper.openLink(this.myIdUrl);
    TelegramMiniAppHelper.close();
  }
}
