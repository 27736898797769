import { animate, style, transition, trigger } from "@angular/animations";
import { Component } from "@angular/core";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { RatesService } from "src/app/services/rates.service";

@Component({
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.css"],
  animations: [
    trigger("fadeInOut", [
      transition("* => *", [style({ opacity: 0 }), animate("0.4s", style({ opacity: 1 }))]),
    ]),
  ],
})
export class LandingComponent {
  public heroTitles = ["Landing.Hero_1a", "Landing.Hero_1b", "Landing.Hero_1c", "Landing.Hero_1d"];
  public visibleTitleIdx = 0;
  public rates: RateDto[] = [];

  private _heroInterval: any;

  constructor(private readonly _ratesService: RatesService) {}

  ngOnInit() {
    this._heroInterval = setInterval(() => {
      this.visibleTitleIdx = (this.visibleTitleIdx + 1) % this.heroTitles.length;
    }, 2000);

    this.getRates();
  }

  private async getRates() {
    const res = await this._ratesService.getRates();
    this.rates = res.params || [];
  }

  ngOnDestroy() {
    clearInterval(this._heroInterval);
  }
}
