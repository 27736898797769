import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CommissionDto } from "src/app/common/DTO/commission/commission.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { CommissionService } from "src/app/services/commission.service";

@Component({
  selector: "app-landing-commission",
  templateUrl: "./landing-commission.component.html",
  styleUrls: ["./landing-commission.component.css"],
})
export class LandingCommissionComponent implements OnInit {
  public groupedCommissions: {
    name: string;
    currency: CryptoSymbol;
    commissions: string[];
  }[] = [];

  constructor(
    private readonly _commissionService: CommissionService,
    private readonly _translateService: TranslateService
  ) {}

  async ngOnInit() {
    await this.getCommissions();
  }

  public async getCommissions(): Promise<void> {
    const allCommissions = (await this._commissionService.getAllCommission()).params ?? [];

    const trxCommissions = this.getFilteredAndSortedCommissions(allCommissions, CryptoSymbol.Trx);
    const usdtCommissions = this.getFilteredAndSortedCommissions(allCommissions, CryptoSymbol.Usdt);
    const maticCommissions = this.getFilteredAndSortedCommissions(allCommissions, CryptoSymbol.Matic);
    const polygonUsdtCommissions = this.getFilteredAndSortedCommissions(
      allCommissions,
      CryptoSymbol.PolygonUsdt
    );

    const trxCommissionsView = this.constructCommissionsView(trxCommissions);
    const usdtCommissionsView = this.constructCommissionsView(usdtCommissions);
    const maticCommissionsView = this.constructCommissionsView(maticCommissions);
    const polygonUsdtCommissionsView = this.constructCommissionsView(polygonUsdtCommissions);

    this.groupedCommissions = [
      {
        name: "USDT - Polygon",
        currency: CryptoSymbol.PolygonUsdt,
        commissions: polygonUsdtCommissionsView,
      },
      {
        name: "USDT - TRON",
        currency: CryptoSymbol.Usdt,
        commissions: usdtCommissionsView,
      },
      {
        name: "MATIC - POLYGON",
        currency: CryptoSymbol.Matic,
        commissions: maticCommissionsView,
      },
      {
        name: "TRX - TRON",
        currency: CryptoSymbol.Trx,
        commissions: trxCommissionsView,
      },
    ];
  }

  private getFilteredAndSortedCommissions(
    allCommissions: CommissionDto[],
    currency: CryptoSymbol
  ): CommissionDto[] {
    return allCommissions.filter(x => x.currency === currency).sort((a, b) => a.maxAmount - b.maxAmount);
  }

  private constructCommissionsView(commissions: CommissionDto[]): string[] {
    let result: string[] = [];
    const arrayLength = commissions.length;

    // Push message if commissions are not set
    if (arrayLength == 0) {
      result.push("Landing.Commission_not_set");
    }

    for (let i = 0; i < commissions.length; i++) {
      // handle first value (from 0 to first item)
      if (arrayLength - i > 1 && i == 0) {
        if (commissions[i].percents != 99) {
          result.push(`0 - ${commissions[i].maxAmount} — ${commissions[i].percents}%`);
        }
      }
      // handle rest of values
      else if (arrayLength - i > 1 && i != 0) {
        result.push(
          `${commissions[i - 1].maxAmount + 1} - ${commissions[i].maxAmount} — ${commissions[i].percents}%`
        );
      }
      // handle last commissions in array longer, than 1
      else if (arrayLength - i == 1 && arrayLength != 0 && arrayLength != 1) {
        result.push(`> ${commissions[i - 1].maxAmount} — ${commissions[i].percents}%`);
      }
      // handle arrays with length = 1
      else {
        result.push(`< ${commissions[i].maxAmount} — ${commissions[i].percents}%`);
      }
    }

    return result.reverse();
  }
}
