<app-header [isLandingPage]="true" />

<section class="relative h-[59rem] overflow-hidden border-b-4 border-box-background max-md:h-auto">
  <div class="container z-10">
    <div class="mt-10 max-w-[50rem]">
      <h1
        class="text-[6rem] font-black uppercase leading-tight -tracking-wider text-text-primary max-md:text-center max-md:text-[2.5rem]"
      >
        <span [@fadeInOut]="visibleTitleIdx" class="text-[9rem] font-black text-accent max-md:text-6xl">
          {{ heroTitles[visibleTitleIdx] | translate }}
        </span>
        {{ "Landing.Hero_2" | translate }}
      </h1>
      <p
        class="mt-20 whitespace-pre-line text-2xl font-semibold max-md:mt-3 max-md:text-center max-md:text-base"
      >
        {{ "Landing.Title" | translate }}
      </p>
    </div>
  </div>

  <div
    class="absolute right-0 top-0 -z-10 h-full w-[62.5rem] max-md:relative max-md:z-10 max-md:mb-4 max-md:mt-9 max-md:h-auto max-md:w-full"
  >
    <img
      src="assets/images/hero-clamber.png"
      alt="hero background"
      class="absolute bottom-0 right-0 -z-10 max-md:hidden"
    />
    <div
      class="absolute bottom-11 left-48 flex flex-col space-y-14 max-md:relative max-md:bottom-auto max-md:left-auto max-md:items-center max-md:space-y-2"
    >
      <app-token-growth class="md:ml-[30rem]" [tokenRate]="rates[4]" />
      <app-token-growth class="md:ml-[26rem]" [tokenRate]="rates[3]" />
      <app-token-growth class="md:ml-[18rem]" [tokenRate]="rates[2]" />
      <app-token-growth class="md:ml-[12rem]" [tokenRate]="rates[1]" />
      <app-token-growth [tokenRate]="rates[0]" />
    </div>
  </div>
</section>

<section class="border-b-4 border-box-background bg-accent" id="landing-calculator">
  <div class="container py-10 max-md:py-5">
    <h2 class="text-5xl font-bold lowercase text-text-secondary max-md:text-4xl">
      .{{ "Landing.Calculator" | translate }}
    </h2>
    <p class="mt-5 text-xl text-text-secondary max-md:mt-3">{{ "Landing.Calculator_note" | translate }}</p>
    <app-currency-calculator [isOnLandingPage]="true" class="mt-5 block max-md:mt-3" />
  </div>
</section>

<section class="border-b-4 border-box-background bg-accent" id="landing-commission">
  <div class="container py-10 max-md:py-5">
    <h2 class="text-5xl font-bold lowercase text-text-secondary max-md:text-4xl">
      .{{ "Landing.Commission" | translate }}
    </h2>
    <app-landing-commission class="mt-5 block max-md:mt-3" />
  </div>
</section>

<section class="border-b-4 border-box-background bg-accent">
  <div class="container py-10 max-md:py-5">
    <h2 class="text-5xl font-bold lowercase text-text-secondary max-md:text-4xl">
      .{{ "Landing.Faq" | translate }}
    </h2>
    <app-faq-accordion class="mt-5 block max-md:mt-3" />
  </div>
</section>
<app-footer />
