import {ValidateConstants} from "src/app/common/constants/validate.constants";
import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function photoFileExtensionValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    if (control.value == null) {
      return null;
    }

    const file = control.value as File;
    const isValidExtension = ValidateConstants.ValidFileExtensions.includes(file.type);

    return isValidExtension ? null : { invalidExtension: true };
  }
}
