import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";
import { BankingTransactionInternalStatus } from "src/app/common/enums/banking-transaction-internal-status.enum";
import { BankingTransactionStatus } from "src/app/common/enums/banking-transaction-status.enum";
import { BankingTransactionType } from "src/app/common/enums/banking-transaction-type.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";

@Component({
  selector: "app-crypto-transaction-list-item",
  templateUrl: "./crypto-transaction-list-item.component.html",
  styleUrls: ["./crypto-transaction-list-item.component.css"],
})
export class CryptoTransactionListItemComponent {
  @Input() txDto: CryptoTxDto = new CryptoTxDto();

  constructor(private readonly _translateService: TranslateService) {}

  public handleClick() {}

  public get txDestination() {
    // ?? TODO: check if it is correct
    if (this.txDto.type === BankingTransactionType.Deposit) {
      return this._translateService.instant("Crypto_transaction.Withdraw");
    } else if (this.txDto.type === BankingTransactionType.Withdraw) {
      return this._translateService.instant("Crypto_transaction.Deposit");
    } else {
      return "";
    }
  }

  public get bankingStatus() {
    switch (this.txDto.bankingStatus) {
      case BankingTransactionStatus.PENDING:
        return this._translateService.instant("Crypto_transaction.Banking_pending");
      case BankingTransactionStatus.CONFIRM:
        return this._translateService.instant("Crypto_transaction.Banking_confirm");
      case BankingTransactionStatus.CONFIRM_FAILED:
        return this._translateService.instant("Crypto_transaction.Banking_confirm_failed");
      case BankingTransactionStatus.REVERSED:
        return this._translateService.instant("Crypto_transaction.Banking_reversed");
      case BankingTransactionStatus.ERROR:
        return this._translateService.instant("Crypto_transaction.Banking_error");
      case BankingTransactionStatus.NOT_SEND:
        return this._translateService.instant("Crypto_transaction.Banking_not_send");
      case BankingTransactionStatus.PREPARE:
        return this._translateService.instant("Crypto_transaction.Banking_prepare");
      case BankingTransactionStatus.SUCCESS:
        return this._translateService.instant("Crypto_transaction.Banking_success");
      case BankingTransactionStatus.REVERSE_ERROR:
        return this._translateService.instant("Crypto_transaction.Banking_reverse_error");
      default:
        return "";
    }
  }

  public get internalStatus() {
    switch (this.txDto.internalStatus) {
      case BankingTransactionInternalStatus.Pending:
        return this._translateService.instant("Crypto_transaction.Internal_pending");
      case BankingTransactionInternalStatus.Complete:
        return this._translateService.instant("Crypto_transaction.Internal_complete");
      case BankingTransactionInternalStatus.Error:
        return this._translateService.instant("Crypto_transaction.Internal_error");
      case BankingTransactionInternalStatus.WaitingForApproval:
        return this._translateService.instant("Crypto_transaction.Internal_waiting");
      case BankingTransactionInternalStatus.RejectedByAdmin:
        return this._translateService.instant("Crypto_transaction.Internal_rejected");
      default:
        return "";
    }
  }

  public getCurrencyName(currency: CryptoSymbol) {
    if (currency === CryptoSymbol.Tiyins) {
      return "UZS";
    }

    switch (currency) {
      case CryptoSymbol.Uzs:
        return "UZS";
      case CryptoSymbol.Usdt:
        return "USDT";
      case CryptoSymbol.Trx:
        return "TRX";
      default:
        return "";
    }
  }

  public getCurrencyValue(amount: number, currency: CryptoSymbol) {
    if (currency === CryptoSymbol.Tiyins) {
      return amount / 100;
    } else {
      return amount;
    }
  }
}
