<h3 class="title">{{ "Admin.Home.Rates" | translate }}</h3>
<div *ngFor="let rate of rates">
  <div
    *ngIf="
      rate.symbol != symbols.Usdt &&
      rate.symbol != symbols.PolygonUsdt &&
      rate.symbol !== symbols.TonUsdt &&
      rate.symbol !== symbols.Not
    "
    class="flex flex-col space-y-3 py-5"
  >
    <div class="box">
      <p class="text-sm font-medium">
        {{ "Admin.Home.Rate" | translate }} {{ getCurrencyName(rate.symbol) }}:
      </p>
      <div class="mt-1 flex items-center gap-2">
        <span class="text-sm font-bold">1 {{ getCurrencyName(rate.symbol) }}:</span>
        <span>{{ rate.usd }}</span>
        <span class="text-sm font-bold">USD</span>
      </div>
    </div>
  </div>
</div>
