import {TransferContractInput} from "src/app/common/models/transfer-contract-input";

export class RawTxHelperUtil {
  public static decodeBase58(hex: string): string {
    return HexAddress.toBase58(hex);
  }

  public static async decodeTransferInput(data: string, isTransferFrom: boolean): Promise<TransferContractInput> {
    return isTransferFrom
      ? await InputDecoder.decodeTransferFrom(data)
      : await InputDecoder.decodeTransfer(data);
  }
}

/*
  WARNING! raw js
  ..or nearly
*/
import {default as JsSHA} from "jssha"
import {ethers} from "ethers";

const BASE = 58;
const ALPHABET = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';

const AbiCoder = ethers.utils.AbiCoder;
const ADDRESS_PREFIX_REGEX = /^(41)/;
const ADDRESS_PREFIX = "41";

class InputDecoder {
  public static async decodeTransfer(data: string) {
    const decoder = new InputDecoder();
    const params = await decoder.decodeParams(['address', 'uint256'], data, true);

    const result = new TransferContractInput();
    result.to = params[0];
    result.amount = params[1];

    return result;
  }
  public static async decodeTransferFrom(data: string) {
    const decoder = new InputDecoder();
    const params = await decoder.decodeParams(['address', 'address', 'uint256'], data, true);

    const result = new TransferContractInput();
    result.from = params[0];
    result.to = params[1];
    result.amount = params[2];

    return result;
  }

  private constructor() {}

  public decodeParams(types: any, output: any, ignoreMethodHash: any) {

    if (!output || typeof output === 'boolean') {
      ignoreMethodHash = output;
      output = types;
    }

    if (ignoreMethodHash && output.replace(/^0x/, '').length % 64 === 8)
      output = '0x' + output.replace(/^0x/, '').substring(8);

    const abiCoder = new AbiCoder();

    if (output.replace(/^0x/, '').length % 64)
      throw new Error('The encoded string is not valid. Its length must be a multiple of 64.');
    return abiCoder.decode(types, output).reduce((obj, arg, index) => {
      if (types[index] == 'address')
        arg = ADDRESS_PREFIX + arg.substr(2).toLowerCase();
      obj.push(arg);
      return obj;
    }, []);
  }
}

class HexAddress {
  public static toBase58(hex: any) {

    let HA = new HexAddress();
    let bytes = HA.hexStr2byteArray(hex);
    let base58 = HA.getBase58CheckAddress(bytes);
    //@ts-ignore
    HA = null;
    //@ts-ignore
    bytes = null;
    return base58;
  }

  private constructor() {}

  public hexStr2byteArray(str: any) {
    const byteArray = Array();
    let d = 0;
    let j = 0;
    let k = 0;

    for (let i = 0; i < str.length; i++) {
      const c = str.charAt(i);
      if (this.isHexChar(c)) {
        d <<= 4;
        d += this.hexChar2byte(c);
        j++;
        if (0 === (j % 2)) {
          byteArray[k++] = d;
          d = 0;
        }
      }
    }
    return byteArray;
  }

  public getBase58CheckAddress(addressBytes: any) {
    const hash0 = this.SHA256(addressBytes);
    const hash1 = this.SHA256(hash0);
    let checkSum = hash1.slice(0, 4);
    checkSum = addressBytes.concat(checkSum);
    return this.encode58(checkSum);
  }


  public SHA256(msgBytes: any) {
    const shaObj = new JsSHA("SHA-256", "HEX");
    const msgHex = this.byteArray2hexStr(msgBytes);
    shaObj.update(msgHex);
    const hashHex = shaObj.getHash("HEX");

    return this.hexStr2byteArray(hashHex);
  }


  public byteArray2hexStr(byteArray: any) {
    let str = "";
    for (let i = 0; i < (byteArray.length - 1); i++) {
      str += this.byte2hexStr(byteArray[i]);
    }
    str += this.byte2hexStr(byteArray[byteArray.length - 1]);
    return str;
  }


  public byte2hexStr(byte: any) {
    let hexByteMap = "0123456789ABCDEF";
    let str = "";
    str += hexByteMap.charAt(byte >> 4);
    str += hexByteMap.charAt(byte & 0x0f);
    return str;
  }


  public encode58(buffer: any) {
    if (buffer.length === 0) {
      return ''
    }

    let i, j, digits = [0]
    for (i = 0; i < buffer.length; i++) {
      for (j = 0; j < digits.length; j++) {
        digits[j] <<= 8
      }

      digits[0] += buffer[i]

      let carry = 0
      for (j = 0; j < digits.length; ++j) {
        digits[j] += carry

        carry = (digits[j] / BASE) | 0
        digits[j] %= BASE
      }

      while (carry) {
        digits.push(carry % BASE)

        carry = (carry / BASE) | 0
      }
    }

    // deal with leading zeros
    for (i = 0; buffer[i] === 0 && i < buffer.length - 1; i++) {
      digits.push(0)
    }

    return digits.reverse().map(function (digit) {
      return ALPHABET[digit]
    }).join('')
  }

  /* Check if a char is hex char */
  public isHexChar(c: any) {
    if ((c >= 'A' && c <= 'F') ||
      (c >= 'a' && c <= 'f') ||
      (c >= '0' && c <= '9')) {
      return 1;
    }
    return 0;
  }

  /* Convert a hex char to value */
  public hexChar2byte(c: any) {
    let d = 0;
    if (c >= 'A' && c <= 'F') {
      d = c.charCodeAt(0) - 'A'.charCodeAt(0) + 10;
    }
    else if (c >= 'a' && c <= 'f') {
      d = c.charCodeAt(0) - 'a'.charCodeAt(0) + 10;
    }
    else if (c >= '0' && c <= '9') {
      d = c.charCodeAt(0) - '0'.charCodeAt(0);
    }
    return d;
  }
}

