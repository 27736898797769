export enum TxPageType {
  Usdt = 1,
  Trx = 2,
  BuySell = 3,
  WaitingConfirm = 4,
  Matic = 5,
  PolygonUsdt = 6,
  Ton = 7,
  Not = 8,
  TonUsdt = 9,
  Bitcoin = 10,
}
