<app-base-auth-form>
  <section class="mx-auto max-w-[28.75rem]">
    <h2 class="title text-center">{{ "Login.Enter" | translate }}</h2>
    <form
      class="box mt-4 flex flex-col space-y-4 px-[1.875rem] py-[2.125rem]"
      [formGroup]="adminForm"
      (ngSubmit)="handleLogin()"
    >
      <app-input
        [title]="'Login.Login' | translate"
        type="text"
        id="phoneNumber"
        formControlName="phoneNumber"
        autocomplete="off"
      ></app-input>
      <app-input
        [title]="'Login.Password' | translate"
        type="password"
        id="psw"
        formControlName="psw"
      ></app-input>
      <button type="submit" class="btn" [disabled]="adminForm.invalid">
        {{ "Login.Enter" | translate }}
      </button>

      <small class="text-sm text-invalid-color" *ngFor="let error of authErrors">{{ error }}</small>
    </form>
  </section>
</app-base-auth-form>
