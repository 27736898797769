export class WalletAssetsDto {
  public usdtAmount = 0;
  public trxAmount = 0;

  constructor(usdtAmount: number, trxAmount: number) {
    this.usdtAmount = usdtAmount;
    this.trxAmount = trxAmount;
  }

}
