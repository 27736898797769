<button
  (click)="onClose()"
  class="absolute right-0 top-[-4.5rem] !m-0 flex h-16 w-16 items-center justify-center rounded-tw bg-select-bg"
>
  <img src="assets/icons/close-circle-outline-white.svg" alt="close" class="h-7 w-7" />
</button>

<div class="mb-6 flex items-center justify-center gap-x-11">
  <button
    (click)="changeVariant('sign-in')"
    class="underlined-btn"
    [ngClass]="{
      'underlined-btn_active': variant === 'sign-in',
      hidden: variant !== 'sign-in' && hideOtherVariants
    }"
  >
    {{ "Login.Sign_in" | translate }}
  </button>
  <button
    (click)="changeVariant('sign-up')"
    class="underlined-btn"
    [ngClass]="{
      'underlined-btn_active': variant === 'sign-up',
      hidden: variant !== 'sign-up' && hideOtherVariants
    }"
  >
    {{ "Login.Sign_up" | translate }}
  </button>
</div>

<ng-container *ngIf="variant === 'sign-in'">
  <app-login-form
    (hideOtherAuthVariants)="onHideOtherVariants($event)"
    (onSuccessfulLogin)="onSuccessfulLogin()"
  />
</ng-container>

<ng-container *ngIf="variant === 'sign-up'">
  <app-register-form
    (hideOtherAuthVariants)="onHideOtherVariants($event)"
    (onSuccessfulSignup)="onSuccessfulSignup()"
  />
</ng-container>

<div
  *ngIf="variant === 'sign-in' && !hideOtherVariants"
  class="mt-6 text-center text-sm font-semibold uppercase tracking-wider"
>
  {{ "Login.Forgot_password" | translate }}
  <button class="contents uppercase text-link-color" (click)="showResetPasswordModal()">
    {{ "Login.Click_to_reset_pass" | translate }}
  </button>
</div>
