import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { Constants } from "src/app/common/constants/constants";
import { Network } from "src/app/common/enums/network.enum";
import { TxPageType } from "src/app/common/enums/tx-page-type.enum";
import { Transaction } from "src/app/common/models/transaction";
import { CryptoService } from "src/app/services/crypto.service";
import { PolygonService } from "src/app/services/polygon.service";
import { TronService } from "src/app/services/tron.service";
import { UserService } from "src/app/services/user.service";

@Component({
  templateUrl: "./user-transactions.component.html",
  styleUrls: ["./user-transactions.component.css"],
})
export class UserTransactionsComponent implements OnInit {
  public userId: number = 0;
  private userData: UserDto;

  public tab: TxPageType = TxPageType.Trx;

  private trxTxs: Transaction[] = [];
  private trxFingerprintHistory: any[] = [null];

  private usdtTxs: Transaction[] = [];
  private usdtFingerprintHistory: any[] = [null];

  private maticTxs: Transaction[] = [];
  private maticTxsPage = 1;

  private polygonUsdtTxs: Transaction[] = [];
  private polygonUsdtTxsPage = 1;

  private pageSize: number = 10;
  public isLoading: boolean = true;

  public buySellTxs: CryptoTxDto[] = [];
  public buySellPage: number = 1;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _tronService: TronService,
    private readonly _cryptoService: CryptoService,
    private readonly _userService: UserService,
    private readonly _polygonService: PolygonService
  ) {
    this._activatedRoute.params.subscribe(params => {
      this.userId = params["id"];
    });
    this.userData = new UserDto();
  }

  async ngOnInit(): Promise<void> {
    await this.requestUserData();
    await this.loadTxs();
  }

  public get txs() {
    switch (this.tab) {
      default:
      case TxPageType.Usdt:
        return this.usdtTxs;
      case TxPageType.Trx:
        return this.trxTxs;
      case TxPageType.Matic:
        return this.maticTxs;
      case TxPageType.PolygonUsdt:
        return this.polygonUsdtTxs;
    }
  }

  public get hasPrevPage() {
    switch (this.tab) {
      case TxPageType.Trx:
        return this.trxFingerprintHistory.length > 2;
      case TxPageType.Usdt:
        return this.usdtFingerprintHistory.length > 2;
      case TxPageType.Matic:
        return this.maticTxsPage > 1;
      case TxPageType.PolygonUsdt:
        return this.polygonUsdtTxsPage > 1;
      case TxPageType.BuySell:
        return this.buySellPage > 1;
      default:
        return false;
    }
  }

  public get hasNextPage() {
    switch (this.tab) {
      case TxPageType.Trx:
        return this.trxFingerprintHistory[this.trxFingerprintHistory.length - 1] != null;
      case TxPageType.Usdt:
        return this.usdtFingerprintHistory[this.usdtFingerprintHistory.length - 1] != null;
      case TxPageType.Matic:
        return this.maticTxs.length === this.pageSize;
      case TxPageType.PolygonUsdt:
        return this.polygonUsdtTxs.length === this.pageSize;
      case TxPageType.BuySell:
        return this.buySellTxs.length === this.pageSize;
      default:
        return false;
    }
  }

  public onPrevPage() {
    switch (this.tab) {
      case TxPageType.Trx:
        this.trxFingerprintHistory.splice(-2);
        this.loadTrxTxs();
        break;
      case TxPageType.Usdt:
        this.usdtFingerprintHistory.splice(-2);
        this.loadUsdtTxs();
        break;
      case TxPageType.Matic:
        this.maticTxsPage--;
        this.loadMaticTxs();
        break;
      case TxPageType.PolygonUsdt:
        this.polygonUsdtTxsPage--;
        this.loadPolygonUsdtTxs();
        break;
      case TxPageType.BuySell:
        this.buySellPage--;
        this.loadBuySellTxs();
        break;
    }
  }

  public onNextPage() {
    switch (this.tab) {
      case TxPageType.Trx:
        this.loadTrxTxs();
        break;
      case TxPageType.Usdt:
        this.loadUsdtTxs();
        break;
      case TxPageType.Matic:
        this.maticTxsPage++;
        this.loadMaticTxs();
        break;
      case TxPageType.PolygonUsdt:
        this.polygonUsdtTxsPage++;
        this.loadPolygonUsdtTxs();
        break;
      case TxPageType.BuySell:
        this.buySellPage++;
        this.loadBuySellTxs();
        break;
    }
  }

  public switchTxType(tabNumber: number) {
    this.tab = Array.from(Constants.TokenSwitchValues.keys())[tabNumber];
    this.buySellPage = 1;
    this.trxFingerprintHistory = [null];
    this.usdtFingerprintHistory = [null];
    this.maticTxsPage = 1;
    this.polygonUsdtTxsPage = 1;
    this.loadTxs();
  }

  public get tokenSwitchValues() {
    return Array.from(Constants.TokenSwitchValues.values()).slice(0, -1);
  }

  public get isBuySellTab() {
    return this.tab === TxPageType.BuySell;
  }

  public get hasTxs() {
    return this.isBuySellTab ? this.buySellTxs.length > 0 : this.txs.length > 0;
  }

  private async requestUserData() {
    const res = await this._userService.getUserById(this.userId);
    this.userData = res.params!;
  }

  private async loadTrxTxs() {
    const trxWallet = this.userData.wallets.find(x => x.network === Network.Tron)?.address ?? "";

    let nextPageFingerprint;
    if (this.trxFingerprintHistory.length <= 1) {
      nextPageFingerprint = null;
    } else {
      nextPageFingerprint = this.trxFingerprintHistory[this.trxFingerprintHistory.length - 1];
    }

    const trxTxsRes = await this._tronService.getTrxTransactions(trxWallet, true, nextPageFingerprint);
    this.trxFingerprintHistory.push(trxTxsRes.fingerPrint);
    this.trxTxs = trxTxsRes.items;
  }

  private async loadUsdtTxs() {
    const trxWallet = this.userData.wallets.find(x => x.network === Network.Tron)?.address ?? "";

    let nextPageFingerprint;
    if (this.usdtFingerprintHistory.length <= 1) {
      nextPageFingerprint = null;
    } else {
      nextPageFingerprint = this.usdtFingerprintHistory[this.usdtFingerprintHistory.length - 1];
    }
    const usdtTxsRes = await this._tronService.getUsdtTransactions(trxWallet, true, nextPageFingerprint);
    this.usdtFingerprintHistory.push(usdtTxsRes.fingerPrint);
    this.usdtTxs = this.usdtTxs.concat(usdtTxsRes.items);
  }

  private async loadBuySellTxs() {
    const buySellTxsRes = await this._cryptoService.getTransactions(
      this.buySellPage,
      this.pageSize,
      this.userId
    );
    this.buySellTxs = buySellTxsRes.params?.items ?? [];
  }

  private async loadMaticTxs() {
    const polygonWallet = this.userData.wallets.find(x => x.network === Network.Polygon)?.address ?? "";
    const maticTxsRes = await this._polygonService.getMaticTransactions(polygonWallet, this.maticTxsPage);
    this.maticTxs = maticTxsRes;
  }

  private async loadPolygonUsdtTxs() {
    const polygonWallet = this.userData.wallets.find(x => x.network === Network.Polygon)?.address ?? "";
    const usdtTxsRes = await this._polygonService.getUsdtTransactions(polygonWallet, this.polygonUsdtTxsPage);
    this.polygonUsdtTxs = usdtTxsRes;
  }

  private async loadTxs() {
    this.isLoading = true;
    switch (this.tab) {
      case TxPageType.Matic:
        await this.loadMaticTxs();
        break;
      case TxPageType.PolygonUsdt:
        await this.loadPolygonUsdtTxs();
        break;
      case TxPageType.Usdt:
        await this.loadUsdtTxs();
        break;
      case TxPageType.Trx:
        await this.loadTrxTxs();
        break;
      case TxPageType.BuySell:
        await this.loadBuySellTxs();
        break;
      case TxPageType.WaitingConfirm:
        break;
      default:
        break;
    }
    this.isLoading = false;
  }
}
