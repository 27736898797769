import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Address } from "@ton/core";
import { Subject, debounceTime, distinctUntilChanged } from "rxjs";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { SendCryptoDto } from "src/app/common/DTO/wallets/send-crypto.dto";
import { WalletBalanceDto } from "src/app/common/DTO/wallets/wallet-balance.dto";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { Network } from "src/app/common/enums/network.enum";
import { WalletErrorCode } from "src/app/common/enums/wallet-error-code.enum";
import { EventData } from "src/app/common/models/event-data";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { TelegramMiniAppHelper } from "src/app/common/utils/telegram-mini-app-helper.util";
import { lengthEqualValidator } from "src/app/common/validators/length-equal.validator";
import { CommissionService } from "src/app/services/commission.service";
import { EventBusService } from "src/app/services/event-bus.service";
import { PolygonService } from "src/app/services/polygon.service";
import { RatesService } from "src/app/services/rates.service";
import { TonConnectService } from "src/app/services/ton-connect.service";
import { WalletService } from "src/app/services/wallet.service";
import { SwapModalComponent } from "../swap-modal/swap-modal.component";

enum Step {
  Input,
  Confirm,
  Success,
  NoCrypto,
}

@Component({
  selector: "app-send-modal",
  templateUrl: "./send-modal.component.html",
  styleUrls: ["./send-modal.component.css"],
})
export class SendModalComponent implements OnInit {
  @Input() wallet: WalletDto = new WalletDto();
  @Input() walletBalance: WalletBalanceDto | null = null;

  step: Step = Step.Input;
  Step = Step;
  businessError: string | null = null;
  wallets: WalletDto[] = [];
  selectedWallet = this.wallet;
  selectedWalletBalance = this.walletBalance;
  confirmationTime = 0;
  isPending = false;
  networkFee = 0;
  isCalculating = false;
  Network = Network;
  isTelegramMiniApp = TelegramMiniAppHelper.isMiniApp();
  tonWalletAddress = "";

  sendForm: FormGroup;

  private _timer: any;
  private polygonGasPrice = 0;
  private rates: RateDto[] = [];
  private feeCallSubject = new Subject<void>();

  private cleanUpFunctionsList: (VoidFunction | undefined)[] = [];

  constructor(
    private _translateService: TranslateService,
    private _walletService: WalletService,
    private _activeModal: NgbActiveModal,
    private _polygonService: PolygonService,
    private _ratesService: RatesService,
    private _commissionService: CommissionService,
    private _tonConnectService: TonConnectService,
    private _modalService: NgbModal,
    private _eventBusService: EventBusService
  ) {
    this.sendForm = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(Number.MIN_VALUE)]),
      to: new FormControl(null, [
        Validators.required,
        lengthEqualValidator(ValidateConstants.TrxAddressLength),
        Validators.pattern(ValidateConstants.TrxAddressPattern),
      ]),
    });

    // Call the fee calculation function when the form changes
    this.sendForm.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(async () => {
      if (this.sendForm.valid) {
        await this.calculateNetworkFee();
      }
    });

    // Call the fee calculation function every 10 seconds after the last call
    this.feeCallSubject.pipe(debounceTime(10000)).subscribe(async () => {
      await this.getGasPrice();
      await this.calculateNetworkFee();
    });

    this.tonWalletAddress = this._tonConnectService?.tonConnectUi?.account?.address || "";
    const tonStatusUnsubscribe = this._tonConnectService?.tonConnectUi?.onStatusChange?.(wallet => {
      this.tonWalletAddress = wallet?.account?.address || "";
    });
    this.cleanUpFunctionsList.push(tonStatusUnsubscribe);
  }

  async ngOnInit() {
    const wallets = await this._walletService.getMy();
    this.wallets = wallets?.params || [];
    if (this.wallet && this.walletBalance) {
      this.selectedWallet = this.wallet;
      this.selectedWalletBalance = this.walletBalance;
    } else {
      this.selectedWallet = { ...this.wallets[0], balances: this.wallets[0].balances };
      this.selectedWalletBalance = this.selectedWallet.balances[0];
    }
    await this.getGasPrice();
    await this.getRates();
    await this.calculateNetworkFee();
    this.updateAmountValidator();
    this.updateWalletValidator();
  }

  getNetworkName = getNetworkName;
  getCurrencyName = getCurrencyName;

  async onSelectBalance(balance: WalletBalanceDto, walletAddress: string) {
    const foundWallet = this.wallets.find(w => w.address === walletAddress)!;
    this.selectedWallet = { ...foundWallet, balances: [balance] };
    await this.calculateNetworkFee();
    this.updateAmountValidator();
    this.updateWalletValidator();
  }

  onBack() {
    if (this.step === Step.Confirm) {
      this.step = Step.Input;
    }
    clearInterval(this._timer);
    this.businessError = null;
  }

  onClose() {
    this._activeModal.close();
  }

  async submitInput() {
    if (this.sendForm.controls["amount"].hasError("max")) {
      this.step = Step.NoCrypto;
      return;
    }

    if (this.sendForm.valid) {
      this.step = Step.Confirm;
    }
  }

  async onConfirm() {
    this.isPending = true;
    this.businessError = null;
    const balance = this.selectedWalletBalance!;
    const amount = this.sendForm.controls["amount"].value;
    const to = this.sendForm.controls["to"].value;

    const dto: SendCryptoDto = {
      to,
      amount,
      currency: balance.currency,
    };

    if (balance.currency === CryptoSymbol.Bitcoin) {
      const decimals = 10 ** 8;
      const amountWithDecimals = Number(dto.amount) * decimals;
      dto.amount = Math.floor(amountWithDecimals);
    }

    const response = await this._walletService.send(dto);
    this.isPending = false;

    if (response.withError) {
      switch (response.errorCode) {
        case WalletErrorCode.SendLimitReached:
          this.businessError = this._translateService.instant("Send.Send_limit_reached_error");
          break;
        case WalletErrorCode.BlockchainError:
          this.businessError = this._translateService.instant("Send.Blockchain_error");
          break;
        case WalletErrorCode.SendDisabled:
          this.businessError = this._translateService.instant("Send.Send_disabled_error");
          break;
        case WalletErrorCode.InvalidAddress:
          this.businessError = "Указан некорректный адрес получателя";
          break;
        case WalletErrorCode.NotEnoughCurrency:
          this.businessError = "Недостаточно средств для отправки";
          break;
        default:
          this.businessError = this._translateService.instant("Common.Unknown_error");
          break;
      }
      return;
    } else {
      this.step = Step.Success;
      this.businessError = null;
      this._eventBusService.dispatch(new EventData(EventTypeConstants.UpdateWallets));
    }
  }

  onOpenSwapModal() {
    const modalRef = this._modalService.open(SwapModalComponent, {
      modalDialogClass: "modal-dialog_full-content",
    });
    modalRef.componentInstance.walletBalance = this.selectedWalletBalance;
    this.onClose();
  }

  selectNetwork(network: Network): void {
    this.selectedWallet = this.wallets.find(x => x.network === network) || new WalletDto();
    const balance = this.selectedWallet.balances[0];
    this.selectedWalletBalance = balance;
    this.updateWalletValidator();
    this.updateAmountValidator();
  }

  selectWalletBalanceByCurrency(currency: CryptoSymbol) {
    const selectedWalletBalance = this.selectedWallet.balances.find(b => b.currency === currency);
    this.selectedWalletBalance = selectedWalletBalance ?? null;
    this.updateAmountValidator();
  }

  get networks() {
    return this.wallets.map(w => w.network);
  }

  get currencies() {
    return this.selectedWallet.balances.map(b => b.currency);
  }

  public get selectedCurrencyName() {
    if (this.selectedWalletBalance) {
      return getCurrencyName(this.selectedWalletBalance.currency);
    } else {
      return "-";
    }
  }

  public get nativeCurrencyName() {
    if (this.selectedWallet.balances.length === 0) {
      return "";
    }

    switch (this.selectedWallet.balances[0].currency) {
      case CryptoSymbol.Trx:
      case CryptoSymbol.Usdt:
        return "TRX";
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "MATIC";
      case CryptoSymbol.Ton:
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Not:
        return "TON";
      case CryptoSymbol.Bitcoin:
        return "BTC";
      default:
        return "";
    }
  }

  public get minimumAmount() {
    switch (this.selectedWallet?.balances[0]?.currency) {
      case CryptoSymbol.Trx:
        return 5;
      case CryptoSymbol.Usdt:
        return 50;
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
      case CryptoSymbol.Ton:
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Not:
        return 1;
      case CryptoSymbol.Bitcoin:
        return 0.00005;
      default:
        return 50;
    }
  }

  public get networkName() {
    if (this.selectedWallet.balances.length === 0) {
      return "";
    }
    return getNetworkName(this.selectedWallet.network);
  }

  public get receiveAmount() {
    return this.sendForm.controls["amount"].value || 0;
  }

  public get sendAmount() {
    const inputAmount = +this.sendForm.controls["amount"].value;
    let convertedFee = 0;

    switch (this.selectedWallet?.balances[0]?.currency) {
      case CryptoSymbol.Matic:
      case CryptoSymbol.Trx:
      case CryptoSymbol.Ton:
      case CryptoSymbol.Bitcoin:
        convertedFee = this.networkFee;
        break;
      // case CryptoSymbol.Usdt:
      //   convertedFee = ConvertCurrencyHelper.convertToUsdt(this.networkFee, CryptoSymbol.Trx, this.rates);
      //   break;
      // case CryptoSymbol.PolygonUsdt:
      //   convertedFee = ConvertCurrencyHelper.convertToPolygonUsdt(
      //     this.networkFee,
      //     CryptoSymbol.Matic,
      //     this.rates
      //   );
      //   break;
      // case CryptoSymbol.TonUsdt:
      //   convertedFee = ConvertCurrencyHelper.convertToTonUsdt(this.networkFee, CryptoSymbol.Ton, this.rates);
      //   break;
      // case CryptoSymbol.Not:
      //   convertedFee = ConvertCurrencyHelper.convertToNot(this.networkFee, CryptoSymbol.Ton, this.rates);
      //   break;
      default:
        convertedFee = 0;
        break;
    }

    return inputAmount + convertedFee;
  }

  public get amountError(): string | null {
    const amount = this.sendForm.controls["amount"];
    const balanceCurrency = this.selectedWallet?.balances[0]?.currency;

    if (amount.value == null) {
      return null;
    }

    if (amount?.hasError("required")) {
      return this._translateService.instant("Send.Amount_required_error");
    }

    if (amount?.hasError("min")) {
      return this._translateService.instant("Send.Amount_min_error");
    }

    if (amount?.hasError("max")) {
      return this._translateService.instant("Send.Amount_insuf_error");
    }

    let nativeBalance = 0;
    switch (balanceCurrency) {
      case CryptoSymbol.Usdt:
        nativeBalance =
          this.wallets.find(w => w.balances[0].currency === CryptoSymbol.Trx)?.balances[0].amount ?? 0;
        if (this.networkFee > nativeBalance) {
          return this._translateService.instant("Send.Amount_commission_error");
        }
        break;
      case CryptoSymbol.PolygonUsdt:
        nativeBalance =
          this.wallets.find(w => w.balances[0].currency === CryptoSymbol.Matic)?.balances[0].amount ?? 0;
        if (this.networkFee > nativeBalance) {
          return this._translateService.instant("Send.Amount_commission_error");
        }
        break;
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Not:
        nativeBalance =
          this.wallets.find(w => w.balances[0].currency === CryptoSymbol.Ton)?.balances[0].amount ?? 0;
        if (this.networkFee > nativeBalance) {
          return this._translateService.instant("Send.Amount_commission_error");
        }
        break;
      default:
        break;
    }

    return null;
  }

  public get toError(): string | null {
    const to = this.sendForm.controls["to"];
    if (to?.value == null) {
      return null;
    }
    if (to?.hasError("required")) {
      return this._translateService.instant("Send.Receiver_required_error");
    }
    if (to?.hasError("lengthEqual")) {
      return this._translateService.instant("Send.Receiver_incorrect_error");
    }
    if (to?.hasError("pattern")) {
      return this._translateService.instant("Send.Receiver_incorrect_error");
    }
    return null;
  }

  public useTonWalletAddressAsReceiver() {
    const userFriendlyTonAddress = Address.parseRaw(this.tonWalletAddress).toString();
    this.sendForm.controls["to"].setValue(userFriendlyTonAddress);
  }

  private async calculateNetworkFee() {
    this.isCalculating = true;
    const currency = this.selectedWallet.balances[0].currency;
    this.networkFee = await this._commissionService.calculateNetworkCommission({
      currency,
      gasPrices: { polygonGasPrice: this.polygonGasPrice },
      amount: this.sendForm.controls["amount"].value,
      toAddress: this.sendForm.controls["to"].value,
      fromAddress: this.selectedWallet.address,
    });
    this.isCalculating = false;
    this.feeCallSubject.next();
  }

  private updateAmountValidator() {
    const amount = this.sendForm.controls["amount"];
    const balanceAmount = this.selectedWalletBalance?.amount || 0;
    let maxValue = 0;

    switch (this.selectedWalletBalance?.currency) {
      case CryptoSymbol.Trx:
      case CryptoSymbol.Matic:
      case CryptoSymbol.Ton:
      case CryptoSymbol.Bitcoin:
        maxValue = balanceAmount - this.networkFee;
        break;
      case CryptoSymbol.Usdt:
      case CryptoSymbol.PolygonUsdt:
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Not:
        maxValue = balanceAmount;
        break;
      default:
        break;
    }

    amount.setValidators([Validators.required, Validators.min(Number.MIN_VALUE), Validators.max(maxValue)]);
    amount.updateValueAndValidity();
  }

  private updateWalletValidator() {
    const to = this.sendForm.controls["to"];
    if (this.selectedWallet.network === Network.Tron) {
      to.setValidators([
        Validators.required,
        lengthEqualValidator(ValidateConstants.TrxAddressLength),
        Validators.pattern(ValidateConstants.TrxAddressPattern),
      ]);
    } else if (this.selectedWallet.network === Network.Polygon) {
      to.setValidators([
        Validators.required,
        lengthEqualValidator(ValidateConstants.PolygonAddressLength),
        Validators.pattern(ValidateConstants.PolygonAddressPattern),
      ]);
    } else if (this.selectedWallet.network === Network.Ton) {
      to.setValidators([
        Validators.required,
        lengthEqualValidator(ValidateConstants.TonAddressLength),
        Validators.pattern(ValidateConstants.TonAddressPattern),
      ]);
    } else if (this.selectedWallet.network === Network.Bitcoin) {
      to.setValidators([
        Validators.required,
        lengthEqualValidator(ValidateConstants.BitcoinAddressLength),
        Validators.pattern(ValidateConstants.BitcoinAddressPattern),
      ]);
    }
    to.updateValueAndValidity();
  }

  private async getGasPrice() {
    const polygonRes = await this._polygonService.getGasPrice();
    this.polygonGasPrice = polygonRes?.SafeGasPrice ? +polygonRes.SafeGasPrice : 0;
  }

  private async getRates() {
    const res = await this._ratesService.getRates();
    if (res.withError) {
      return;
    }
    this.rates = res.params!;
  }

  ngOnDestroy() {
    clearInterval(this._timer);
    this.feeCallSubject.unsubscribe();
    this.cleanUpFunctionsList.map(cleanUp => cleanUp?.());
  }
}
