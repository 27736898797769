import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EnvService } from "src/app/services/env.service";
import { Constants } from "src/app/common/constants/constants";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { AuthErrorCode } from "src/app/common/enums/auth-error-code.enum";
import { SignupDto } from "src/app/common/DTO/auth/signup.dto";
import { Observable, firstValueFrom } from "rxjs";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { ApprovePhoneDto } from "src/app/common/DTO/auth/approve-phone.dto";
import { SendConfirmCodeDto } from "src/app/common/DTO/auth/send-confirm-code.dto";
import { LoginDto } from "src/app/common/DTO/auth/login.dto";
import { SuccessLoginDto } from "src/app/common/DTO/auth/success-login.dto";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { RefreshDto } from "src/app/common/DTO/auth/refresh.dto";
import { AdminLoginDto } from "src/app/common/DTO/auth/admin-login.dto";
import { AddAdminDto } from "src/app/common/DTO/auth/add-admin-dto";
import { ChangePasswordDto } from "src/app/common/DTO/auth/change-password.dto";
import { ResetPasswordDto } from "src/app/common/DTO/auth/reset-password.dto";
import { IsPhoneExistsDto } from "src/app/common/DTO/auth/is-phone-exists.dto";
import { ValidateLoginCredentialsDto } from "src/app/common/DTO/auth/validate-login-credentials.dto";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private readonly _baseReqOpts: { headers: HttpHeaders };
  private readonly CodeRequestLimitExceedsMsg = "You will be able to request the code again no later than ";
  private readonly DisallowSmsSentTo = "The user is not allowed to send sms up to";

  public isAuthorized: boolean = false;

  @Output() onLogin = new EventEmitter();

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _envService: EnvService,
    private readonly _localStorage: LocalStorageService
  ) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  // public async refresh(dto: RefreshDto): Promise<HttpResultDto<AuthErrorCode, SuccessLoginDto | null>> {
  //   const uri = `${this._envService.serverUrl}${UrnConstants.RefreshTokenUrn}`;
  //   const result = new HttpResultDto<AuthErrorCode, SuccessLoginDto>(false);

  //   try {
  //     const response = (await firstValueFrom(
  //       this._httpClient.post(uri, dto, this._baseReqOpts)
  //     )) as ApiResponseDto<SuccessLoginDto>;

  //     result.params = response.data;
  //   } catch (e) {
  //     const apiError = ErrorParserUtil.parse(e);
  //     result.withError = true;
  //     result.errorCode = this.getAuthErrorCode(apiError.msg);
  //   }

  //   return result;
  // }

  public async refreshTokens(): Promise<Observable<any>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.RefreshTokenUrn}`;
    const accessToken = await this._localStorage.accessToken();
    const refreshToken = await this._localStorage.refreshToken();
    const dto: RefreshDto = { expiredToken: accessToken, refreshToken: refreshToken };

    return this._httpClient.post(uri, dto, this._baseReqOpts);
  }

  public async login(dto: LoginDto): Promise<HttpResultDto<AuthErrorCode, SuccessLoginDto | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.LoginUrn}`;
    const result = new HttpResultDto<AuthErrorCode, SuccessLoginDto>(false);

    try {
      const response = (await firstValueFrom(
        this._httpClient.post(uri, dto, this._baseReqOpts)
      )) as ApiResponseDto<SuccessLoginDto>;

      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);

      result.withError = true;
      result.errorCode = this.getAuthErrorCode(apiError.msg);
    }

    return result;
  }

  public async sendConfirmCode(dto: SendConfirmCodeDto): Promise<HttpResultDto<AuthErrorCode, Date | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.SendConfirmCodeUrn}`;

    const result = new HttpResultDto<AuthErrorCode, Date | null>(false);
    try {
      const response = (await firstValueFrom(
        this._httpClient.post(uri, dto, this._baseReqOpts)
      )) as ApiResponseDto<null>;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);

      result.withError = true;
      result.errorCode = this.getAuthErrorCode(apiError.msg);

      if (result.errorCode == AuthErrorCode.CodeRequestLimitExceeds) {
        result.params = this.parseCodeRequestLimitMsg(apiError.msg);
      }
    }

    return result;
  }

  public async approvePhone(dto: ApprovePhoneDto): Promise<HttpResultDto<AuthErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.ApprovePhoneUrn}`;
    const result = new HttpResultDto<AuthErrorCode, null>(false);

    try {
      const response = (await firstValueFrom(
        this._httpClient.post(uri, dto, this._baseReqOpts)
      )) as ApiResponseDto<null>;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);

      result.withError = true;
      result.errorCode = this.getAuthErrorCode(apiError.msg);
    }

    return result;
  }

  public async signup(dto: SignupDto): Promise<HttpResultDto<AuthErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.SignupUrn}`;

    const result = new HttpResultDto<AuthErrorCode, null>(false);

    try {
      const response = (await firstValueFrom(
        this._httpClient.post(uri, dto, this._baseReqOpts)
      )) as ApiResponseDto<string>;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);

      result.withError = true;
      result.errorCode = this.getAuthErrorCode(apiError.msg);
    }

    return result;
  }

  public async loginAdmin(dto: AdminLoginDto): Promise<HttpResultDto<AuthErrorCode, SuccessLoginDto | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.LoginAdminUrn}`;

    const result = new HttpResultDto<AuthErrorCode, SuccessLoginDto>(false);

    try {
      const response = (await firstValueFrom(
        this._httpClient.post(uri, dto, this._baseReqOpts)
      )) as ApiResponseDto<SuccessLoginDto>;

      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);

      result.withError = true;
      result.errorCode = this.getAuthErrorCode(apiError.msg);
    }

    return result;
  }

  public async logout() {
    const uri = `${this._envService.serverUrl}${UrnConstants.LogoutUrn}`;

    const result = new HttpResultDto<AuthErrorCode, null>(false);

    try {
      await firstValueFrom(this._httpClient.post(uri, null, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);

      result.errorCode = this.getAuthErrorCode(apiError.msg);
      result.withError = true;
    }

    return result;
  }

  public async addAdmin(dto: AddAdminDto): Promise<HttpResultDto<AuthErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.AdminsUrn}`;

    const result = new HttpResultDto<AuthErrorCode, null>(false);

    try {
      await firstValueFrom(this._httpClient.post(uri, dto, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);

      result.errorCode = this.getAuthErrorCode(apiError.msg);
      result.withError = true;
    }

    return result;
  }

  public async changePassword(dto: ChangePasswordDto): Promise<HttpResultDto<AuthErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.ChangePasswordUrn}`;

    const result = new HttpResultDto<AuthErrorCode, null>(false);

    try {
      await firstValueFrom(this._httpClient.post(uri, dto, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);

      result.errorCode = this.getAuthErrorCode(apiError.msg);
      result.withError = true;
    }

    return result;
  }

  public async sendPasswordResetCode(
    dto: SendConfirmCodeDto
  ): Promise<HttpResultDto<AuthErrorCode, Date | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.SendPasswordResetCodeUrn}`;
    const result = new HttpResultDto<AuthErrorCode, Date | null>(false);

    try {
      await firstValueFrom(this._httpClient.post(uri, dto, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);

      result.withError = true;
      result.errorCode = this.getAuthErrorCode(apiError.msg);

      if (result.errorCode == AuthErrorCode.CodeRequestLimitExceeds) {
        result.params = this.parseCodeRequestLimitMsg(apiError.msg);
      }
    }

    return result;
  }

  public async resetPassword(dto: ResetPasswordDto): Promise<HttpResultDto<AuthErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.ResetPasswordUrn}`;
    const result = new HttpResultDto<AuthErrorCode, null>(false);

    try {
      await firstValueFrom(this._httpClient.post(uri, dto, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getAuthErrorCode(apiError.msg);
    }

    return result;
  }

  public async checkPhoneIsExists(
    phoneNumber: string
  ): Promise<HttpResultDto<AuthErrorCode, IsPhoneExistsDto>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.CheckPhoneIsExistsUrn}`;
    const result = new HttpResultDto<AuthErrorCode, IsPhoneExistsDto>(false);

    try {
      const response = (await firstValueFrom(
        this._httpClient.post(uri, { phoneNumber }, this._baseReqOpts)
      )) as ApiResponseDto<IsPhoneExistsDto>;
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getAuthErrorCode(apiError.msg);
    }

    return result;
  }

  public async validateLoginCredentials(
    dto: ValidateLoginCredentialsDto
  ): Promise<HttpResultDto<AuthErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.ValidateLoginUrn}`;
    const result = new HttpResultDto<AuthErrorCode, null>(false);

    try {
      await firstValueFrom(this._httpClient.post(uri, dto, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getAuthErrorCode(apiError.msg);
    }

    return result;
  }

  private parseCodeRequestLimitMsg(str: string): Date {
    const dateStr = str.split(this.CodeRequestLimitExceedsMsg)[1];
    return new Date(dateStr);
  }

  private getAuthErrorCode(error: string): AuthErrorCode {
    if (error && error.includes(this.CodeRequestLimitExceedsMsg)) {
      return AuthErrorCode.CodeRequestLimitExceeds;
    }
    if (error && error.includes(this.DisallowSmsSentTo)) {
      return AuthErrorCode.DisallowSmsSentTo;
    }

    switch (error) {
      case Constants.InternalServerError:
        return AuthErrorCode.InternalError;
      case "Phone number already use":
        return AuthErrorCode.PhoneNumberAlreadyUse;
      case "Already approved":
        return AuthErrorCode.PhoneAlreadyApprove;
      case "Incorrect or expired code":
        return AuthErrorCode.IncorrectCode;
      case "User not found":
        return AuthErrorCode.UserNotFound;
      case "Incorrect phone or password":
        return AuthErrorCode.IncorrectPhoneOrPsw;
      case "User not approved":
        return AuthErrorCode.UserNotApproved;
      case "Invalid access token or refresh token":
        return AuthErrorCode.InvalidAccessOrRefresh;
      case "Incorrect refresh token":
        return AuthErrorCode.IncorrectRefreshToken;
      case "That login already use":
        return AuthErrorCode.LoginAlreadyUse;
      case "Incorrect password":
        return AuthErrorCode.IncorrectPassword;
      default:
        return AuthErrorCode.InternalError;
    }
  }
}
