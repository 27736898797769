import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "mfeCustom",
})
export class MfeCustomPipe implements PipeTransform {
  transform(
    value: string | number | undefined,
    moreDecimals: boolean = false,
    isLowValue: boolean = false
  ): string {
    if (value === undefined) {
      return "0";
    }

    if (isLowValue) {
      const valueNumber = Number(value);
      if (Number.isNaN(valueNumber) || valueNumber <= 0) {
        return "0";
      }
      const roundedValue = valueNumber.toFixed(8);
      return Number(roundedValue).toString();
    }

    const roundTo = moreDecimals ? 6 : 2;

    const rounded = Number(value).toFixed(roundTo);
    return +value > 0 ? Number(rounded).toLocaleString("mfe") : "0";
  }
}
