import {Injectable} from "@angular/core";
import {UserService} from "src/app/services/user.service";
import {Router} from "@angular/router";
import {RouteConstants} from "src/app/common/constants/route.constants";

@Injectable({
  providedIn: "root"
})
export class KycGuard {
  constructor(
    private readonly _userService: UserService,
    private readonly _router: Router
  ) {}

  public async canActivate() {
    const response = await this._userService.getMe();
    if (response.withError) {
      this._router.navigate([RouteConstants.login]);
      return false;
    }

    if (response.params!.kyc == null) {
      return true;
    }

    this._router.navigate([RouteConstants.profile]);
    return false;
  }
}
