export enum CardErrorCode {
  Unauthorized = 1,
  InternalError = 2,
  ExceededCardCountLimit = 3,
  CardAlreadyExists = 4,
  DisallowSmsSent = 5,
  CodeRequestLimitExceeds = 6,
  IncorrectCode = 7,
  ServerAbordConnection = 8,
  BadRequest = 9,
  IncorrectCardData = 10,
  ExpiredOptCode = 11,
  CardIsCorporate = 12,
  HumoIsDown = 13,
  HumoCardInfoError = 14,
  IncorrectPhoneNumber = 15,
  OTPPhoneNumberDifferent = 16,
  OTPDailyLimitUsed = 17,
  PanInvalid = 18,
}
